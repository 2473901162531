import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { convertIpfsUrl, formatTimestampToTime, get, isApp, set } from '../utils/CommonUtil';
import { useWalletStore } from '../store/WalletStore';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getNft721Uri } from '../utils/WalletUtil';
import { TRANSACTION_DETAIL_PAGE } from '../global/Constant';
import { ClipLoader } from 'react-spinners';

function ActivityDetail() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const {item} = location.state;
    const {currentWallet}  = useWalletStore(['currentWallet']);
    const [metadata, setMetadata] = useState();
    const [loading,setLoading] = useState(false);
    const goTransactionDetail= () => {
        navigate(TRANSACTION_DETAIL_PAGE, {
            state : {item:item}
        });
    }

    const onLoad = async () => {
        setLoading(true);
        const tokenURI = await getNft721Uri(item.networkInfo.endPoint, item.networkInfo.contractAddress, item.tokenID);
        // console.dir({ "tokenURI": tokenURI });
        if (tokenURI) {
            let url = tokenURI;
            if(!tokenURI.startsWith('http')){
                // console.dir({ "tokenURI_bool": tokenURI.startsWith('http') });
                url = convertIpfsUrl(tokenURI);
                
            }
            // console.dir({ "url": url });
            const result = await set('getNftMetadata',url);
            // console.dir({ "result": result });
            // console.log(typeof result === 'object')
            typeof result === 'object' ? setMetadata(result) : setMetadata(JSON.parse(result));
            
            // 이미지가 없을떄만 loading 없애기
            if(!result.image){
            // if(true){
                setLoading(false);
            }
        }
    }
    useEffect(() => {
        if(isApp()) {
            onLoad();
        }
    },[])
    return (
        <div className='flex-1 flex flex-col px-[24px] overflow-auto text-white'>
            <div className='flex flex-col py-[24px] gap-[32px]'>
                <div className='text-[16px] text-lavender-gray text-center'>
                {   
                    item.methodId === '0x6a761202' ? 'Exec Transaction'
                    : item.networkInfo.isCoin ? (
                        item.methodId === '0x'
                        ? `${item.to.toLowerCase() === currentWallet.publicKey.toLowerCase() ? t('105') : t('106')} ${item.networkInfo.symbol}` 
                        : item.methodId
                    )
                    : `${item.to.toLowerCase() === currentWallet.publicKey.toLowerCase() ? t('105') : t('106')} ${item.networkInfo.alias.toUpperCase()}` 
                }
                </div>
                <div className='flex flex-col gap-[16px] items-center'>
                    <div className='w-[60px] h-[60px] bg-lavender-gray rounded-[50%] flex flex-row items-center justify-center text-medium-gray text-[12px]'>
                        {item.networkInfo.alias.toUpperCase().substr(0,2)}
                    </div>
                    <div className='flex flex-col gap-[12px]'>
                        <div className='flex flex-col'>
                            <div className='text-[24px] font-bold text-white text-center'>
                                Contract call to :<br/>
                                {item.tokenName}
                            </div>
                            <div className='text-light-gray-purple text-[14px] text-center'>{formatTimestampToTime(item.timeStamp)}</div>
                        </div>
                        <div className='text-lavender-gray text-[14px] text-center'>
                            {item.networkInfo.groupName}
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-[20px] items-center'>
                    {
                        loading && <ClipLoader
                            size={40}
                            color={"#FFCC48"}
                            loading={loading}
                            speedMultiplier={0.8}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        /> 
                    }
                    {
                        metadata && <img src={metadata.image.startsWith("ipfs://") ? convertIpfsUrl(metadata.image) : metadata.image} className={loading ? 'hidden' : 'block'} onLoad={() => setLoading(false)} alt='' />
                    }
                    {
                        metadata?.description && <div className='text-lavender-gray text-[14px]'>
                            {
                                metadata.description
                            }
                        </div>
                    }
                    
                </div>
            </div>
            <div className='flex flex-row justify-center'>
                <div className='w-[123px] ronded-[4px] px-[24px] py-[14.5px] flex flex-row text-white gap-[10px] justify-center items-center bg-dark-gray-purple' onClick={goTransactionDetail}>
                    <div>Details</div>
                    <img src='/assets/icon/arrow-next.svg' alt=''/>
                </div>
            </div>
        </div>
    );
}

export default ActivityDetail;

import React from 'react';

import { QRCodeCanvas } from 'qrcode.react';
// import { isApp, set } from '../utils/CommonUtils';

import { toast, Slide } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useWalletStore } from '../store/WalletStore';
import { isApp, set } from '../utils/CommonUtil';

function MyWalletAddress() {
  const { currentWallet } = useWalletStore(['currentWallet']);
  const { t } = useTranslation();

  const onAddressCopy = async () => {
    if (isApp()) {
      const result = await set('copyAddress', currentWallet?.publicKey);
      if (result) {
        toast(t('57'), {
          position: 'bottom-center',
          autoClose: 1000,
          closeButton: false,
          hideProgressBar: true,
          pauseOnHover: false,
          draggable: false,
          transition: Slide,
          className: '!bg-dark-gray-purple !text-[14px] !text-white',
          icon: <img src='/assets/icon/success.svg' alt='' />
        });
      }
    }
  }
  return (
    <div className='flex-1 pt-[24px] px-[36px] flex flex-col gap-[40px]'>
      <div className='text-center text-white text-[20px] font-bold' dangerouslySetInnerHTML={{ __html: t('73') }}>
      </div>
      <div className='flex flex-col items-center'>
        {/* qr sample */}
        <QRCodeCanvas size={240} value={currentWallet?.publicKey} />
      </div>
      <div className='flex flex-row gap-[19px] p-[16px] bg-dark-navy'>
        <div className='break-all flex-1 text-lavender-gray text-[16px]'>
          {currentWallet?.publicKey}
        </div>
        <div className='flex-none'>
          <img src='/assets/icon/copy.png' alt='' className='cursor-pointer' onClick={onAddressCopy} />
        </div>
      </div>
    </div>
  );
}

export default MyWalletAddress;
import React from 'react';

function TabTitleItem({title}) {
    return (
        <div className='py-[0.625rem] text-[1.25rem] font-bold line text-lavender-gray'>
            <div className='px-[24px]'>
                {title}
            </div>  
        </div>
    );
}

export default TabTitleItem;
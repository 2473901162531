import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ASSET_DETAIL_TAB_LIST, MY_WALLET_PAGE, SEND_ASSETS_PAGE } from '../global/Constant';
import { useTranslation } from 'react-i18next';
import Activity from './Activity';
import AssetDetailInfo from '../components/AssetDetailInfo'
import { useWalletStore } from '../store/WalletStore';
import { useSendingInfoStore } from '../store/SendingInfoStore';

function AssetDetail() {
    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {item, tabIdx} = location.state;
    const totalPrice = location.state.totalPrice;
    const prevPercent = location.state.prevPercent;

    const [tabList, setTabList] = useState(ASSET_DETAIL_TAB_LIST);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const {currentWallet}  = useWalletStore(['currentWallet']);
    const {
        setSendingToAddress, setSendingContractAddress, setSendingAmount, setSendingAvailable, setSendingMaxPriorityFeePerGas, setSendingMaxFeePerGas, setSendingGaspriceBsc,
    } = useSendingInfoStore([
        'setSendingToAddress', 'setSendingContractAddress', 'setSendingAmount', 'setSendingAvailable', 'setSendingMaxPriorityFeePerGas', 'setSendingMaxFeePerGas', 'setSendingGaspriceBsc',
    ]);

    const setActiveTab = (selectedIndex) => {
        setActiveTabIndex(selectedIndex);
        setTabList(tabList.map((item, index) => ({
            ...item,
            active: index === selectedIndex
        })));
    }

    const onChnageTab = (index) => {
        setActiveTab(index);
    }

    const goSendingAssets = () => {
        setSendingToAddress('');
        setSendingContractAddress('');
        setSendingAmount(0);
        setSendingAvailable(0);
        setSendingMaxPriorityFeePerGas(0);
        setSendingMaxFeePerGas(0);
        setSendingGaspriceBsc(0);
        navigate(SEND_ASSETS_PAGE, {
            state: {
                item,
                openSetting: false,
            }
        })
    }

    useEffect( () => {
        if ( tabIdx !== undefined ) setActiveTab(tabIdx);
    }, [])

    return (
        <div className={`flex-1 flex flex-col overflow-hidden`}>
            {/* <pre className='text-white'>{JSON.stringify(item,null,2)}</pre> */}
            <div className={`flex-1 flex flex-col ${activeTabIndex === 0 ? 'gap-[32px]' : 'gap-[24px]'} overflow-hidden`}>
                <div className='flex flex-row justify-center gap-[48px]'>
                    {
                        tabList.map((item, index) =>
                            <div key={index} className='text-medium-gray text-center relative text-[1rem] cursor-pointer' onClick={() => onChnageTab(index)}>
                                <div className={`w-[0.1875rem] h-[0.1875rem] rounded-[50%] ${item.active ? 'bg-white' : ''} mx-auto`} />
                                <div className={item.active ? 'text-white' : ''}>{t(item.key)}</div>
                            </div>)
                    }
                </div>
                <div className='flex-1 overflow-y-auto flex felx-col'>
                    {
                        activeTabIndex === 0
                            ? <AssetDetailInfo item={item} totalPrice={totalPrice} prevPercent={prevPercent}/>
                            : <Activity item={item}/>
                    }
                </div>
            </div>

            {
                !currentWallet.isWatch && <div className='flex-none flex flex-row gap-[8px] pt-[24px] pb-[40px] text-[16px] items-center px-[24px]'>
                    <div className='text-dark-purple bg-primary-yellow flex-1 py-[18.5px] rounded-[4px] text-center' onClick={goSendingAssets}>
                        {t('123')}
                    </div>
                    <div className='text-white border bg-dark-purple border-primary-yellow flex-1 py-[18.5px] rounded-[4px] text-center cursor-pointer' onClick={() => navigate(MY_WALLET_PAGE)}>
                        {t('124')}
                    </div>
                </div>
            }
        </div>
    )
}

export default AssetDetail

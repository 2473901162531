import React from 'react';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ASSET_DETAIL_PAGE, COLLECTIBLE_DETAIL_PAGE } from '../../global/Constant';
function TransactionInProgressModal({ isOpen, setIsOpen, title, detailInfo }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const goAssetDetailActivity = () => {
        if (!detailInfo.isNft) {
            navigate(ASSET_DETAIL_PAGE, {
                state: {
                    item: detailInfo,
                    tabIdx: 1
                }
            })
        } else {
            navigate(COLLECTIBLE_DETAIL_PAGE, {
                state: {
                    item: detailInfo,
                    tabIdx: 1
                }
            })
        }

    }

    return (
        <Modal open={isOpen} center classNames={{
            overlay: 'custom_overlay',
            modal: 'custom_modal'
        }} showCloseIcon={false} onClose={() => setIsOpen(false)}>
            <div className='flex flex-col gap-[32px] px-[24px] py-[32px] items-center'>
                <div className='flex flex-col gap-[16px] w-[264px] items-center'>
                    <div className='flex justify-center items-center'>
                        <img
                            className='h-[36px] w-[36px]'
                            src='/assets/icon/success.svg' alt=''
                        />
                    </div>
                    <div className='text-white text-[20px] font-bold text-center'>{title}</div>
                    <div className='text-lavender-gray text-[16px] text-center' dangerouslySetInnerHTML={{__html : t('120')}} />
                </div>
                <div className='flex flex-row justify-between'>
                    {
                        detailInfo.protocol !== 721 ?
                            <div>
                                <div className='bg-[#343140] text-[#ffffff] w-[120px] h-[60px] py-[5px] px-[10px] text-[16px] rounded-[4px] cursor-pointer flex flex-row justify-center' onClick={() => {
                                    setIsOpen(false)
                                }}
                                >
                                    <div className='flex flex-col justify-center'>
                                        {t('121')}
                                    </div>
                                </div>
                            </div> :
                            null
                    }
                    {
                        detailInfo.protocol !== 721 ?
                        <div className='w-[8px]' /> :
                        null
                    }
                    
                    <div className='bg-primary-yellow w-[120px] h-[60px] py-[5px] px-[10px] text-dark-purple text-[16px] rounded-[4px] cursor-pointer flex flex-row justify-center' onClick={goAssetDetailActivity}>
                        <div className='flex flex-col justify-center'>
                            {t('122')}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default TransactionInProgressModal;
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGeckoStore } from "./store/GeckoStore";
import { axiosGecko } from "./lib/axios";
import { useNetworkStore } from "./store/NetworkStore";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ACTIVITY_PAGE, BROWSER_PAGE, LOGIN_PAGE, MAIN_PAGE, MARKET_API, SETTING_PAGE, WALLETS } from "./global/Constant";
import { commonGroupBy, get, getItem, isApp, saveNetwork, setChangeLanguage } from "./utils/CommonUtil";
import { useWalletStore } from "./store/WalletStore";
import Header from "./components/Header";
import BottomMenu from "./components/BottomMenu";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useDefaultStore } from "./store/DefaultStore";

function App() {
  const location = useLocation();
  const {i18n } = useTranslation();
  const {marketData,setMarketData,updateTime,setUpdateTime} 
  = useGeckoStore(['marketData','setMarketData','updateTime','setUpdateTime']);
  const {networks,setNetworks} = useNetworkStore(['networks','setNetworks']);
  const {setCurrentWallet} = useWalletStore(['setCurrentWallet']);
  const {setCurrency} = useDefaultStore(['setCurrency']);
  
  const navigate = useNavigate();

  // gecko api call
  const getMarketData = async(moneyUnit) => {
      // 게코api 마켓데이터 조회
      const params = {
        'vs_currency' : moneyUnit,
        // 'ids' : 'ethereum,tether,usd-coin,binancecoin,ssv-network',
        'ids' : 'staked-ether,wrapped-eeth,pepe,ethena-usde,polygon,leo-token,dai,uniswap,chainlink,wrapped-bitcoin,shiba-inu,ethereum,tether,usd-coin,binancecoin,ssv-network',
        'per_page' : 16,
        'page' : 1,
        'sparkline' : false,
        'price_change_percentage' : '24h'
      };
      const timestamp = new Date().getTime();
      if(marketData === null){
        try{
          const marketResponse = await axiosGecko(MARKET_API,{params});    
          setMarketData(marketResponse.data);
          setUpdateTime(timestamp);
        }
        catch(ex) {
          console.log("Err::",ex)
        }
      }
      // 1분이 지난상태면 갱신 가능
      else {
          const timeDifference = Math.abs(timestamp - updateTime);
          // 1분(60,000 밀리초) 이상 차이나는지 확인
          if (timeDifference >= 60000) {
              // 여기에 실행할 코드 작성
              try{
              const marketResponse = await axiosGecko(MARKET_API,{params});    
              setMarketData(marketResponse.data);
              setUpdateTime(timestamp);
              }
              catch(ex){
                console.log("Err::",ex);
              }
          }
      }
  }
  const onLoad = useCallback(async () => {
    // 해당 페이지는 로그인 이후에만 접속 가능한 페이지다.
    // 지갑목록이 존재하지 않으면 login 페이지로 보낸다.
    if(isApp()){
      const wallets = await getItem(WALLETS);
      if(wallets === null) {
        navigate(LOGIN_PAGE);
        return;
      }
      else {
        // 현재 선택되어있는 지갑 정보 가져오기
        const updateWallet = {};
        JSON.parse(wallets).map(walletItem => {
          const selectedAccount = walletItem.accounts.find(account => account.isSelected);
          if(selectedAccount) {
            updateWallet.isBackup = walletItem.isBackup;
            updateWallet.index = selectedAccount.index;
            updateWallet.isSelected = selectedAccount.isSelected;
            updateWallet.publicKey = selectedAccount.publicKey;
            updateWallet.nickName = selectedAccount.nickName;
            updateWallet.isSeed = selectedAccount.isSeed;
            updateWallet.isPrivateKey = selectedAccount.isPrivateKey;
            updateWallet.isWatch = selectedAccount.isWatch;
          }
        })
        setCurrentWallet(updateWallet);
      }

      // 통화 화폐정보
      let moneyUnit = await getItem('currency');
      if (moneyUnit === null) moneyUnit = 'USD';
      setCurrency(moneyUnit);
      // 마켓데이터 조회 (일단은 하드코딩)
      await getMarketData(moneyUnit);
      // 네트워크 목록 조회
      if(networks === null && location.pathname === MAIN_PAGE) {
        // 1. network 목록 조회
        const networkResponse = JSON.parse(await saveNetwork());
        const networkList = commonGroupBy(networkResponse,'isNft');
        setNetworks({
            asset : commonGroupBy(networkList.asset || [],"groupName"),
            collectible : commonGroupBy(networkList.collectible || [],"groupName")
        });
      }

      await setChangeLanguage(i18n);
      await get('removeSplash')
    }
  },[])
  useEffect(() => {
    const controller = new AbortController();
    onLoad();
    return () => {
        controller.abort();
    };
},[])

  return (
    <div className='bg-dark-purple h-screen flex flex-col w-full max-w-[40rem] mx-auto'>
      <Header />
      <Outlet />
      {
        (location.pathname === MAIN_PAGE
        || location.pathname === ACTIVITY_PAGE
        || location.pathname === BROWSER_PAGE
        || location.pathname === SETTING_PAGE)
        && <BottomMenu />
      }
      <ToastContainer />
    </div>
  );
}

export default React.memo(App);

import { useShallow } from '../lib/hooks/useShallow';
import { createWithEqualityFn } from 'zustand/traditional';

const initialState = {
    settingOpen : false,
}

export const SettingStore = createWithEqualityFn((set) => ({
    ...initialState,
    setSettingOpen: (items) => set({settingOpen : items}),
    reset : () => {
        set(initialState)
    }
}),Object.is)

export const useSettingStore = (keys) => {
    return useShallow(SettingStore, keys);
};
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get, isApp, isBoolean } from '../utils/CommonUtil';
import { BACKUP_WALLET_STEP02_PAGE } from '../global/Constant';
import { useDefaultStore } from '../store/DefaultStore';

function BackupWallet01() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { setCurrentStep } = useDefaultStore(['setCurrentStep']);

    const nextStep = async () => {
        if(isApp()){
            // 얼굴인식
            const result = await get('getLocalAuth');
            if(isBoolean(result)) {
                if(result){
                    setCurrentStep(2);
                    navigate(BACKUP_WALLET_STEP02_PAGE);
                }
            }
            else {
                // 모달창을 띄운다
                // setSettingOpen(true);
            }
        }
    }
    const onLoad = () => {
        setCurrentStep(1);
    }
    useEffect(() =>{
        onLoad();
    },[])

    return (
        <>
            <div className='flex-1 px-[24px] pt-[16px] pb-[40px] flex flex-col overflow-y-auto'>
                <div className='flex flex-col gap-[24px]'>
                    <div className='text-white text-[20px] font-bold' dangerouslySetInnerHTML={{__html : t('32')}} />
                    <div className='text-lavender-gray text-[16px]' dangerouslySetInnerHTML={{__html : t('33')}} />
                </div>
                <div className='flex-1' />
                <div className='bg-primary-yellow px-[54.5px] py-[18.5px] rounded-[4px]
                                text-[16px] text-dark-purple text-center' onClick={nextStep}>
                    {t('34')}
                </div>
            </div>
        </>
    );
}

export default BackupWallet01;
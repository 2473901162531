import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { useWalletStore } from '../store/WalletStore';
import { useGeckoStore } from '../store/GeckoStore';
import { get, getItem, isApp, isBoolean, onPaste, onQrScanner } from '../utils/CommonUtil';
import { useSendingInfoStore } from '../store/SendingInfoStore';
import { getCoinBalance, getEstimatedGas, getFeeData, getTokenBalance, sendCoin, sendToken } from '../utils/WalletUtil';
import { formatEther, parseEther, parseUnits } from 'ethers';
import { speedSetter } from '../global/Constant';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { ClipLoader } from 'react-spinners';
import FailToBroadcastingTransactionModal from '../components/modal/FailToBroadcastingTransactionModal';
import TransactionInProgressModal from '../components/modal/TransactionInProgressModal';
import 'react-spring-bottom-sheet/dist/style.css'

function SendAssets() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const addressRef = useRef(null);
    const { item, openSetting } = location.state;
    const { marketData } = useGeckoStore(['marketData']);
    const { currentWallet } = useWalletStore(['currentWallet']);
    const [open, setOpen] = useState(openSetting);
    const [loading, setLoading] = useState(false);
    const [isTransactionFailModal, setIsTransactionFailModal] = useState(false);
    const onCloseGuide_TransactionFailModal = () => setIsTransactionFailModal(false);
    const [transactionInProgressModal, setTransactionInProgressModal] = useState(false);
    const [sendFailCode, setSendFailCode] = useState();

    const getImage = useCallback((flag) => {
        if (marketData) {
            const marketItem = marketData.find(marketItem => marketItem.symbol === flag.toLowerCase());
            if (marketItem) return marketItem.image;
        }
    }, [])
    const getCurrentPrice = useCallback((flag) => {
        if (marketData) {
            const marketItem = marketData.find(marketItem => marketItem.symbol === flag.toLowerCase());
            if (marketItem) return marketItem?.current_price;
            else return marketItem;
        }
    }, [])
    const closeSheet = () => { setOpen(false); };
    const {
        sendingToAddress, sendingContractAddress, sendingAmount, sendingAvailable, sendingMaxPriorityFeePerGas, sendingMaxFeePerGas, sendingSpeed, sendingGaspriceBsc, setSendingToAddress, setSendingContractAddress, setSendingAmount, setSendingAvailable, setSendingMaxPriorityFeePerGas, setSendingMaxFeePerGas, setSendingGaspriceBsc,
    } = useSendingInfoStore([
        'sendingToAddress', 'sendingContractAddress', 'sendingAmount', 'sendingAvailable', 'sendingMaxPriorityFeePerGas', 'sendingMaxFeePerGas', 'sendingSpeed', 'sendingGaspriceBsc', 'setSendingToAddress', 'setSendingContractAddress', 'setSendingAmount', 'setSendingAvailable', 'setSendingMaxPriorityFeePerGas', 'setSendingMaxFeePerGas', 'setSendingGaspriceBsc',
    ]);
    const settingAllFunds = async () => {
        const transaction = {}
        const feeData = await getFeeData(item.endPoint);
        if (item.isCoin) {
            transaction.to = currentWallet.publicKey; // 수수료를 구하기 위해 임시로 본인의 지깁주소를 넣음
            transaction.value = parseEther(sendingAvailable); // 수수료를 구하기 위해 보낼 수량 넣음
            const estimatedGas = await getEstimatedGas(item.endPoint, transaction);
            setSendingAmount(await getCoinBalance(currentWallet.publicKey, item.endPoint) - formatEther(feeData.gasPrice * estimatedGas + feeData.maxPriorityFeePerGas * estimatedGas));
        } else setSendingAmount(await getTokenBalance(currentWallet.publicKey, item.endPoint, item.contractAddress));
    }
    const fetchAvailableAmount = async () => {
        if (item.isCoin) setSendingAvailable(await getCoinBalance(currentWallet.publicKey, item.endPoint));
        else {
            setSendingContractAddress(item.contractAddress);
            setSendingAvailable(await getTokenBalance(currentWallet.publicKey, item.endPoint, item.contractAddress));
        }
    }
    const getFeeInfo = async () => {
        try {
            await getFeeData(item.endPoint).then((res, err) => {
                if (sendingGaspriceBsc == undefined || sendingGaspriceBsc == 0) setSendingGaspriceBsc(parseInt(res?.gasPrice) * speedSetter[1].gaspriceRatio);
                if (sendingMaxPriorityFeePerGas == undefined || sendingMaxPriorityFeePerGas == 0) setSendingMaxPriorityFeePerGas(parseUnits('1.5', 'gwei'));
                if (sendingMaxFeePerGas == undefined || sendingMaxFeePerGas == 0) setSendingMaxFeePerGas(res?.maxFeePerGas + parseUnits('1.5', 'gwei'));
            })
        } catch (error) {
            console.error('Error fetching coin data:', error);
        }
    }
    const onLoad = async () => {
        await fetchAvailableAmount();
        await getFeeInfo();
        getCurrentPrice(item.alias);
    }
    useEffect(() => {
        if (isApp()) {
            onLoad();
            if (item.groupName === 'ETHEREUM' || item.groupName === 'Seporila(Testnet)') item.groupName === 'ETHEREUM' ? item.symbol = "ETH" : item.symbol = "SeporliaETH"
            else item.groupName === 'BINANCE(BSC)' ? item.symbol = "BNB" : item.symbol = "tBNB"
        }
    }, []);
    const validateHexOnly = (input) => { // 정규표현식을 사용하여 입력된 값이 16진수 문자(a-f, A-F, 0-9)만 포함하는지 확인합니다.
        const hexRegex = /^0x[a-zA-Z0-9]+$/;
        return input.length === 42 && hexRegex.test(input);
    }
    const validateAmount = () => {
        if (sendingAmount === null || (parseFloat(sendingAmount) <= parseFloat(0))) return false;
        else if (parseFloat(sendingAvailable) < parseFloat(sendingAmount)) return false;
        else return true;
    }
    const isRetrieved = useMemo(() => {
        if ( sendingToAddress.length === 0) return null;
        else if (validateHexOnly(sendingToAddress) && validateAmount()) return true;
        else return false;
    }, [sendingToAddress, sendingAmount]);
    const goSetSpeed = () => { navigate('/set_speed', { state: { item, } }) }
    const confirmBtn = async () => {
        const result = await get('getLocalAuth');
        if (isBoolean(result)) {
            if (result) {
                setLoading(true);
                let pk = '';
                const walletList = await getItem('wallets');
                if (walletList !== null) {
                    const wallets = JSON.parse(walletList);
                    wallets.map((wallet) => { if (wallet.accounts[0].isSelected) pk = wallet.accounts[0].privateKey; })
                }
                try {
                    if (item.isCoin) {
                        const sendCoinResult = await sendCoin(pk, item, sendingToAddress, sendingAmount, sendingMaxPriorityFeePerGas, sendingMaxFeePerGas, sendingGaspriceBsc);
                        // console.dir({ "sendCoinResult": sendCoinResult })
                    } else {
                        const sendTokenResult = await sendToken(pk, item, sendingToAddress, sendingContractAddress, sendingAmount, sendingMaxPriorityFeePerGas, sendingMaxFeePerGas, sendingGaspriceBsc);
                        // console.dir({ "sendTokenResult": sendTokenResult })
                    }
                    setTransactionInProgressModal(true)
                } catch (error) {
                    console.dir({ "error": error })
                    setSendFailCode(error.code);
                    setIsTransactionFailModal(true);
                } finally {
                    setSendingToAddress("");
                    setSendingContractAddress("");
                    setSendingAmount(0);
                    setOpen(false);
                    await fetchAvailableAmount();
                    setLoading(false);
                }
            }
        }
    }
    useEffect(() => {
        const textarea = addressRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = Math.min(textarea.scrollHeight, 38) + 'px';
    }, [sendingToAddress]);

    return (
        <>
            <div className={`flex-1 flex flex-col px-[24px] overflow-hidden pt-[24px] gap-[48px]`}>
                {/* <pre className='text-white'>{JSON.stringify(item,null,2)}</pre> */}
                    <div className='flex flex-col gap-[8px]'>
                        <div className='flex flex-row items-center gap-[8px]'>
                            <div className='text-lavender-gray text-[14px]'>{t('129')}</div>
                            <div className='text-[14px] text-medium-gray'>{item.groupName}</div>
                        </div>
                        <div className='flex flex-row items-center h-[56px] py-[16px] px-[16px] border-[1px] border-[#343140] rounded-[4px]'>
                                <div className='flex flex-row gap-[4px]'>
                                    <div className='w-[24px] h-[24px] bg-white rounded-[50%] flex flex-row'>
                                        {
                                            getImage(item.alias)
                                                ? <img src={getImage(item.alias)} alt='' />
                                                : <div className='truncate bg-lavender-gray w-[24px] h-[24px] rounded-[50%] text-medium-gray flex justify-center items-center text-[6px]'>
                                                    {item.alias}
                                                </div>
                                        }
                                        {(item.isMain && !item.isCoin) && <div className='w-[7px] h-[7px] rounded-[50%] bg-medium-gray absolute left-0 bottom-0'><img src={getImage(item.symbol)} alt='' className='' /></div>}
                                    </div>
                                    <div className='text-[16px] text-white font-bold'>{item.alias}</div>
                                </div>
                                <input type='text' className='auto-height2 flex flex-1 h-[24px] bg-transparent focus:outline-none text-white font-bold text-right'
                                    value={sendingAmount}
                                    placeholder='0'
                                    onChange={(e) => {
                                        if (e.target.value === '' || /^[0-9]*\.?[0-9]*$/.test(e.target.value)) {
                                            setSendingAmount(e.target.value);
                                        }
                                    }}
                                />
                        </div>
                        <div className='flex flex-row items-center'>
                                <div className='flex-1 flex flex-row gap-[4px]'>
                                    <div className='text-[14px] text-medium-gray' >{t('142')} : </div>
                                    <div className='text-[14px] text-white flex-1'>{parseFloat(sendingAvailable).toFixed(5)} {item.alias}</div>
                                </div>
                                <div className={`text-[14px] cursor-pointer ${parseFloat(sendingAvailable) < parseFloat(sendingAmount) ? 'text-light-red' : 'text-light-yellow' }`}
                                    onClick={parseFloat(sendingAvailable) < parseFloat(sendingAmount) ? null : settingAllFunds}>
                                    {parseFloat(sendingAvailable) < parseFloat(sendingAmount) ? t('145') : t('143')}
                                </div> :
                        </div>
                    </div>
                    <div className='flex flex-col gap-[8px]'>
                        <div className='flex flex-row items-center text-lavender-gray'>
                            {t('140')}
                        </div>
                        <div className='flex flex-row items-center p-[16px] border border-dark-gray-purple rounded-[4px] gap-[8px]'>
                            <textarea className='flex-1 resize-none box-border bg-transparent text-white outline-none auto-height'
                                placeholder={t('64')}
                                value={sendingToAddress} 
                                ref={addressRef} rows={1}
                                onChange={(e) => {
                                    setSendingToAddress(e.target.value)
                                }}></textarea>
                            <div className='w-[24px] h-[24px] cursor-pointer' onClick={async () => {
                                let lang = await getItem('language');
                                if (lang === null) lang = 'en';
                                onQrScanner({ setState: setSendingToAddress, lang: lang })
                            }}>
                                <img src='/assets/icon/scan.png' alt='' />
                            </div>
                        </div>
                        <div className='flex flex-row justify-between '>
                            {
                                isRetrieved !== null 
                                    ? (validateHexOnly(sendingToAddress) ? <div className='text-[#3687FF]'>{t('79')}</div> : <div className='text-[#FF5858]'>{t('78')}</div>)
                                    : <div />
                            }
                            <div className='text-[#FFD771] cursor-pointer' onClick={() => onPaste({ setState: setSendingToAddress })}>{t('72')}</div>
                        </div>
                    </div>
                    <div className='flex-1' />
                    <div className='flex flex-row justify-center'>
                        <div className={`px-[103px] py-[18.5px] text-dark-purple bg-primary-yellow rounded-[4px] text-center ${isRetrieved ? '' : 'opacity-50'}`} 
                             onClick={() => isRetrieved ? setOpen(true) : null}>{t('144')}</div> :
                    </div>
            </div>
            <FailToBroadcastingTransactionModal isOpen={isTransactionFailModal} setIsOpen={setIsTransactionFailModal} title={t('177')} fn={onCloseGuide_TransactionFailModal} code={sendFailCode} />
            <TransactionInProgressModal isOpen={transactionInProgressModal} setIsOpen={setTransactionInProgressModal} title={t('119')} detailInfo={item} />
            <BottomSheet open={open}
                onDismiss={closeSheet}
                header={false}>
                    <div className='px-[24px] pt-[20px] pb-[32px] bg-dark-navy rounded-t-[16px] flex flex-col gap-[32px]'>
                        <div className='flex flex-col gap-[24px]'>
                            <div className='flex flex-row'>
                                <div className='flex-1 text-light-gray-purple'>{t('125')}</div>    
                                <img src='/assets/icon/close_x.svg' alt='' onClick={closeSheet} />
                            </div>  
                            <div className='flex flex-row'>
                                <div className='flex-1 flex flex-row gap-[16px]'>
                                    <div className='text-light-gray-purple w-[64px]'>{t('126')}</div>
                                    <div className='flex-1 text-white'>
                                        {sendingAvailable !== undefined ? sendingAvailable : item?.balance} {item.alias}
                                    </div>
                                </div>
                                <div className='w-[24px] h-[24px] flex flex-row'>
                                    {
                                        getImage(item.alias)
                                            ? <img src={getImage(item.alias)} alt='' />
                                            : <div className='truncate bg-lavender-gray w-[24px] h-[24px] rounded-[50%] text-medium-gray flex justify-center items-center text-[6px]'>
                                                {item.alias}
                                            </div>
                                    }
                                </div>
                            </div>
                            <div className='flex flex-row'>
                                <div className='flex-1 flex flex-row gap-[16px]'>
                                    <div className='text-light-gray-purple w-[64px]'>{t('127')}</div>
                                    <div className='flex flex-col gap-[2px]'>
                                        <div className='flex-1 text-white'>
                                            {sendingSpeed !== undefined ? sendingSpeed : 'Average'}
                                        </div>
                                        <div className='text-medium-gray'>
                                            {'<'} {(parseFloat(formatEther(sendingMaxFeePerGas))).toFixed(4)} {item?.symbol} ($ {getCurrentPrice(item?.alias) === undefined ? '0.00' : (parseFloat(formatEther(sendingMaxFeePerGas)) * getCurrentPrice(item?.alias)).toFixed(9)})
                                        </div>
                                    </div>
                                </div>
                                <div className='text-primary-yellow' onClick={goSetSpeed}>{t('138')}</div>
                            </div>
                            <div className='flex flex-row'>
                                <div className='flex-1 flex flex-row gap-[16px]'>
                                    <div className='text-light-gray-purple w-[64px]'>{t('128')}</div>
                                    <div className='flex-1 text-white break-all '>
                                        {sendingToAddress}
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-row'>
                                <div className='flex-1 flex flex-row gap-[16px]'>
                                    <div className='text-light-gray-purple w-[64px]'>{t('129')}</div>
                                    <div className='flex-1 text-white'>
                                        {parseFloat(sendingAmount).toFixed(5)} {item.alias}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row justify-center'>
                            {loading 
                                ? <ClipLoader
                                    size={40}
                                    color={"#FFCC48"}
                                    loading={loading}
                                    speedMultiplier={0.8}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                /> 
                                : <div className='w-[105px] text-dark-purple bg-primary-yellow px-[24px] py-[18.5px] rounded-[4px] text-center' onClick={confirmBtn}>{t('139')}</div>}
                        </div>
                    </div>
                {/* <div className='px-[24px] pt-[32px] bg-[#22212D] text-[#ffffff] rounded-t-[16px]'>
                    <div className='pb-[24px] text-[#626070]'>{t('125')}</div>
                    <div className='pb-[24px] flex flex-row' onClick={closeSheet}>
                        <div className='text-[#626070] w-[64px]'>{t('126')}</div>
                        <div className='w-[16px]' />
                        {sendingAvailable !== undefined ? sendingAvailable : item?.balance} {item.alias}
                    </div>
                    <div className='pb-[24px] w-[a] flex flex-row'>
                        <div className='text-[#626070] w-[64px]'>{t('127')}</div>
                        <div className='w-[16px]'></div>
                        <div className='flex flex-row justify-between'>
                            <div onClick={closeSheet}>
                                <div>{sendingSpeed !== undefined ? sendingSpeed : 'Average'}</div>
                                <div>
                                    <div className='flex flex-row justify-between'>
                                        {'< '} {(parseFloat(formatEther(sendingMaxFeePerGas))).toFixed(4)} {item?.symbol} ($ {getCurrentPrice(item?.alias) === undefined ? '0.00' : (parseFloat(formatEther(sendingMaxFeePerGas)) * getCurrentPrice(item?.alias)).toFixed(9)})
                                    </div>
                                </div>
                            </div>
                            <div className='w-[24px]' />
                            <div className='text-[#FFCC48]' onClick={goSetSpeed}>{t('138')}</div>
                        </div>
                    </div>
                    <div className='pb-[24px] flex flex-row' onClick={closeSheet}>
                        <div className='text-[#626070] w-[64px]'>{t('128')}</div>
                        <div className='w-[16px]' />
                        <div className='break-all text-[#ffffff] pl-[16px] pr-[24px]'>{sendingToAddress}</div>
                    </div>
                    <div className='pb-[24px] flex flex-row' onClick={closeSheet}>
                        <div className='text-[#626070] w-[64px]'>{t('129')}</div>
                        <div className='w-[16px]' />
                        {parseFloat(sendingAmount).toFixed(5)} {item.symbol}
                    </div>
                    <div className='p-[32px] flex flex-row justify-center'>
                        {
                            loading 
                            ? <ClipLoader
                                size={40}
                                color={"#FFCC48"}
                                loading={loading}
                                speedMultiplier={0.8}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            /> 
                            : <div className='text-dark-purple bg-primary-yellow flex-1 px-[36px] py-[18.5px] rounded-[4px] text-center' onClick={confirmBtn}>{t('139')}</div>
                        }
                    </div>
                </div> */}
            </BottomSheet>
        </>
    )
}
export default SendAssets;
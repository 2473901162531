import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { commonGroupBy, getItem, isApp, onPaste, onQrScanner, onlyNumberAndAlpha, setItem } from "../utils/CommonUtil";
import { ethers } from "ethers";
import { getMyNftInfo1155, getMyNftInfo721, getMyTokenInfo, getNft1155Balance, getNft721TokenId, getTokenBalance, getTokenDecimals, getTokenName, getTokenSymbol, getTotalSupply } from "../utils/WalletUtil";
import SubTitle from "../components/SubTitle";
import { ClipLoader } from "react-spinners";
import TabTitleItem from "../components/TabTitleItem";
import ErrorImportWalletModal from "../components/modal/ErrorImportWalletModal";
import { useWalletStore } from "../store/WalletStore";
import CustomTokenItem from "../components/CustomTokenItem";
import { useNetworkStore } from "../store/NetworkStore";

function AddCustomToken() {
    const {currentWallet} = useWalletStore(['currentWallet']);
    const { t } = useTranslation();
    const {setNetworks} = useNetworkStore(['setNetworks']);

    const contractAddressRef = useRef(null);
    const [loading,setLoading] = useState(false);
    const [contractAddress, setContractAddress] = useState('')
    const [invalidTitle, setInvalidTitle] = useState('');
    const [validationMessage, setValidationMessage] = useState('')
    const [isFocus, setIsFocus] = useState(false);
    const [validationType, setValidationType] = useState('');
    const [isErrorModal, setIsErrorModal] = useState(false);
    const [tokens, setTokens] = useState([]);
    const [groupDisplayTokens, setGroupDisplayTokens] = useState(null);

    async function getNetworkInfo(provider) {
        const network = await provider.getNetwork();
        return network.chainId;
      }

      
    const onLoadAutoScan = async () => {
        const networkList = await getItem('NETWORK_LIST');
        const networks = JSON.parse(networkList);
        const isExistContractAddress = networks.find(network => network.contractAddress === contractAddress)
        // const mainNetworks = networks.filter(network => network.isCoin && network.isMain)
        const mainNetworks = networks.filter(network => network.isCoin)
        
        if(isExistContractAddress) {
            setInvalidTitle(t('65'));
            setIsErrorModal(true);
            return;
        }
        // 메인네트워크 정보만
        const promises = [];

        if(mainNetworks.length > 0) {
            const walletAddress = currentWallet.publicKey;
            for(let networkItem of mainNetworks){
                const tokenInfo = {
                    groupName : networkItem.groupName,
                    symbol: networkItem.symbol,
                    endPoint: networkItem.endPoint,
                    isMain: networkItem.isMain,
                    isCoin: false,
                    isNft : false,
                    isSearch: true,
                    display: true,
                    contractAddress: contractAddress
                }

                let tokenResult = await getMyNftInfo721(networkItem.endPoint,contractAddress,walletAddress);
                if(tokenResult){
                    tokenInfo.isNft = true;
                    tokenInfo.name = tokenResult.name;
                    tokenInfo.alias = tokenResult.symbol;
                    tokenInfo.balance = tokenResult.balance;
                    tokenInfo.protocol = 721;
                    promises.push(tokenInfo);
                }
                else {
                    tokenResult = await getMyNftInfo1155(networkItem.endPoint,contractAddress,walletAddress);
                    if(tokenResult){
                        tokenInfo.isNft = true;
                        tokenInfo.name = tokenResult.name;
                        tokenInfo.alias = tokenResult.symbol;
                        tokenInfo.balance = tokenResult.balance;
                        tokenInfo.protocol = 1155;
                        promises.push(tokenInfo);
                    }
                    else {
                        tokenResult = await getMyTokenInfo(networkItem.endPoint,contractAddress,walletAddress);
                        if(tokenResult){
                            tokenInfo.isNft = false;
                            tokenInfo.name = tokenResult.name;
                            tokenInfo.alias = tokenResult.symbol;
                            tokenInfo.balance = tokenResult.balance;
                            tokenInfo.protocol = 20;
                            promises.push(tokenInfo);
                        }
                    }
                }
            }
        }
        return await Promise.all(promises);
    }
    const onChangeContractAddress = async () => {
        if(isApp()){
            const value = contractAddress.trim();
            setValidationType('');
            setTokens([]);
            setGroupDisplayTokens(null);
            // validation
            // 입력값 시작이 0x 로 시작하는가
            if(value.length > 0) {
                // 영문/숫자 입력 체크 + 0x 체크
                if(!onlyNumberAndAlpha(value) 
                || (value.length > 1 && !value.startsWith('0x'))) {
                    setValidationType('ERROR');
                    setValidationMessage(t('78'));
                    return;
                }

                // contract address 체크
                if (value.length === 42) {
                    const isValid = ethers.isAddress(value);
                    if (!isValid) {
                        setInvalidTitle(t('81'));
                        setIsErrorModal(true);
                        return;
                    }
                    setValidationType('SUCCESS');
                    setValidationMessage(t('79'));
                    // validation 최종 통과 - auto scan 실행
                    setLoading(true);
                    const results = await onLoadAutoScan();
                    if(results.length > 0) {
                        const networkList = commonGroupBy(results,'isNft');
                        setTokens(results);
                        setGroupDisplayTokens({
                            asset : commonGroupBy(networkList.asset || [],"groupName"),
                            collectible : commonGroupBy(networkList.collectible || [],"groupName")
                        })
                    } 
                    setLoading(false);
                }
            }
        }
    }

    const validationColor = useMemo(() => {
        let color = '';
        if(validationType === 'SUCCESS'){
            color = 'text-light-blue';
        }
        else if(validationType === 'ERROR'){
            color = 'text-light-red';
        }
        return color;
    },[validationType])

    const inputColor = useMemo(() => {
        let color = 'border-dark-gray-purple';
        if(validationType === 'SUCCESS'){
            color = 'border-light-blue';
        }
        else if(validationType === 'ERROR'){
            color = 'border-light-red';
        }
        else if(isFocus) {
            color = 'border-light-yellow';
        }
        return color;
    },[isFocus, validationType]);

    const clearPage = useCallback(() => {
        setContractAddress('');
        setIsErrorModal(false);
        setTokens([]);
        setGroupDisplayTokens(null);
    },[])

    const onAddToken = async () => {
        if(isApp()){
            // validation 이 통과 되었을때만 save 버튼 기능 동작
            if (validationType === 'SUCCESS' && tokens.length > 0 ) {
                // 1. 기존 network 토큰들중에 동일 contract address 가 존재하는지 체크
                const networkList = await getItem('NETWORK_LIST');
                const networks = JSON.parse(networkList);
                const addNetworks = [...networks, ...tokens];
                await setItem('NETWORK_LIST', JSON.stringify(addNetworks));
                const networkLists = commonGroupBy(addNetworks,'isNft');
                setNetworks({
                    asset : commonGroupBy(networkLists.asset || [],"groupName"),
                    collectible : commonGroupBy(networkLists.collectible || [],"groupName")
                });
                clearPage();
            }
        }
    }

    useEffect(() => {
        const textarea = contractAddressRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = Math.min(textarea.scrollHeight, 38) + 'px';
        onChangeContractAddress();
    }, [contractAddress]);
    return (
        <>
            <div className='flex-1 flex flex-col pt-[16px] gap-[40px]'>
                <div className='flex flex-col gap-[16px] px-[24px]'>
                    <SubTitle title={t('76')} />
                    <div className='flex flex-col gap-[4px]'>
                        <div className='text-[16px] text-lavender-gray'>{t('77')}</div>
                        <div className={`rounded-[4px] px-[16px] py-[18.5px] border flex flex-row items-center gap-[10px]
                            ${inputColor}`}>
                            <textarea className='resize-none box-border w-full h-full bg-transparent text-white outline-none auto-height'
                                placeholder='ex) 0x45tdf453klldc43756gdf'
                                maxLength={42}
                                value={contractAddress} onChange={(e)=>setContractAddress(e.target.value)}
                                ref={contractAddressRef} rows={1}
                                onBlur={() => setIsFocus(false)}
                                onFocus={() => setIsFocus(true)}
                            ></textarea>
                            <div className='flex-none w-[24px] h-[24px] cursor-pointer' onClick={async () => {
                                let lang = await getItem('language');
                                if (lang === null) lang = 'en';
                                onQrScanner({setState : setContractAddress,lang : lang})
                            }}>
                                <img src='/assets/icon/scan.png' alt='' />
                            </div>
                        </div>
                        <div className='flex flex-row justify-between'>
                            <div className={validationColor}>
                                {validationMessage}
                            </div>
                            <div className='text-[16px] text-light-yellow cursor-pointer' onClick={() => onPaste({setState : setContractAddress})}>
                                {t('72')}
                            </div>
                        </div>
                    </div>
                </div>
                {/* Result */}
                {
                    loading ? <div className='flex justify-center'>
                        <ClipLoader
                            size={40}
                            color={"#FFCC48"}
                            loading={loading}
                            speedMultiplier={0.8}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                    :   tokens.length > 0 && Object.entries(groupDisplayTokens).map(([tokenKey, tokenItem],tokenIndex) => 
                            <div key={tokenKey} className={`flex flex-col ${tokenIndex === 0 ? 'gap-[16px]' : 'gap-[24px]'}`}>
                                <TabTitleItem title={tokenKey === 'asset' ? t('83') : t('88')} />
                                { 
                                    Object.entries(tokenItem)
                                    .map(([subGroupKey, subGroupItem]) => 
                                        <div key={subGroupKey} className='px-[24px] flex flex-col'>
                                                <SubTitle title={subGroupKey} />
                                                {
                                                    subGroupItem.length > 0 && subGroupItem.map((item,index) => 
                                                        <CustomTokenItem key={index} item={item} />)
                                                }
                                        </div>
                                    )
                                }
                            </div>
                        )
                    
                }
            </div>
            <div className='flex flex-row justify-center items-center pb-[40px]'>
                <div className={`bg-primary-yellow rounded-[4px]
                                text-[16px] text-dark-purple text-center py-[18.5px] px-[102.5px] ${tokens.length > 0 ? '' : 'opacity-50'}`} onClick={onAddToken}>
                    {t('80')} 
                </div>
            </div>
            <ErrorImportWalletModal isOpen={isErrorModal} setIsOpen={setIsErrorModal} title={invalidTitle} fn={clearPage} />
        </>
    );
}

export default AddCustomToken;
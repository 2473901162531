import React, { useCallback } from 'react';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LOGIN_PAGE, WALLETS, WALLETS_PAGE } from '../../global/Constant';
import { getItem, isApp, removeItem, setItem } from '../../utils/CommonUtil';
import { useWalletStore } from '../../store/WalletStore';
import { useNetworkStore } from '../../store/NetworkStore';
function LoseThisWalletConfirmModal({isOpen,setIsOpen}) {
    const navigate = useNavigate();
    const location = useLocation(); 
    const { t } = useTranslation();
    const {setCurrentWallet,setWallets,setWalletGroup} = useWalletStore(['setCurrentWallet','setWallets','setWalletGroup']);
    const {setNetworks} = useNetworkStore(['setNetworks']);
    const removeAccountByPublicKey = (data, walletAddress) => {
        // Remove matching accounts
        data = data.filter(item => {
            item.accounts = item.accounts.filter(account => account.publicKey !== walletAddress);
            return item.accounts.length > 0;
        });
        
        // If there are any items left, set the first account's isSelected to true
        if (data.length > 0 && data[0].accounts.length > 0) {
            data[0].accounts[0].isSelected = true;
        }
        return data;
    }

    
    const onLoseThisWallet = useCallback(async () =>{
        if(isApp()) {
            // 1. 선택 지갑주소를 가져온다
            const walletAddress = location.state.publicKey;
            // 2. storage wallet 목록 가져온다
            const walletList = await getItem(WALLETS);
            const parseWalletList = JSON.parse(walletList);
            const  afterRemoveWalletList = removeAccountByPublicKey(parseWalletList,walletAddress);
            // 마지막 지갑이면 전체 초기화 후 로그인 페이지 이동
            if(afterRemoveWalletList.length === 0) {
                await removeItem(WALLETS);
                setCurrentWallet(null)
                setWallets([]);
                setWalletGroup(null)
                navigate(LOGIN_PAGE,{replace : true})
                return;
            }

            await setItem(WALLETS,JSON.stringify(afterRemoveWalletList));
            const changeWallet = {};
            afterRemoveWalletList.map(walletItem => {
              const selectedAccount = walletItem.accounts.find(account => account.isSelected);
              if(selectedAccount) {
                changeWallet.isBackup = walletItem.isBackup;
                changeWallet.index = selectedAccount.index;
                changeWallet.isSelected = selectedAccount.isSelected;
                changeWallet.publicKey = selectedAccount.publicKey;
                changeWallet.nickName = selectedAccount.nickName;
                changeWallet.isSeed = selectedAccount.isSeed;
                changeWallet.isPrivateKey = selectedAccount.isPrivateKey;
                changeWallet.isWatch = selectedAccount.isWatch;
              }
            })
            setCurrentWallet(changeWallet);
            setNetworks(null);
            navigate(WALLETS_PAGE,{replace : true});
        }
    },[])
    return (
        <Modal open={isOpen} center classNames={{
            overlay : 'custom_overlay',
            modal: 'custom_modal'
        }} showCloseIcon={false} onClose={() => setIsOpen(false)}>
            <div className='flex flex-col gap-[32px] px-[24px] py-[32px] items-stretch'>
                <div className='flex flex-col gap-[16px] w-[264px]'>
                    <div className='flex justify-center items-center'>
                        <img src='/assets/icon/info.png' alt='' />
                    </div>
                    <div className='text-white text-[20px] font-bold text-center'>{t('59')}</div>
                    <div className='text-lavender-gray text-[16px] text-center'>
                    {t('60')}
                    </div>
                </div>
                <div className='flex flex-row gap-[8px] text-center'>
                    <div className='flex-1 bg-dark-gray-purple text-white text-[16px] py-[14.5px] rounded-[4px] cursor-pointer' onClick={() => setIsOpen(false)}>
                    {t('55')}
                    </div>
                    <div className='flex-1 bg-primary-yellow text-dark-purple text-[16px] py-[14.5px] rounded-[4px] cursor-pointer' onClick={onLoseThisWallet}>
                    {t('56')}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default LoseThisWalletConfirmModal;
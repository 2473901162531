import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDefaultStore } from '../store/DefaultStore';
import { useTranslation } from 'react-i18next';
import { BACKUP_WALLET_STEP01_PAGE, BACKUP_WALLET_STEP03_PAGE } from '../global/Constant';
import { getItem, isApp } from '../utils/CommonUtil';

function BackupWallet02() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { currentStep,setCurrentStep } = useDefaultStore(['currentStep','setCurrentStep']);
    const [mnemonic,setMnemonic] = useState([]);
    const nextStep = async () => {
        setCurrentStep(3);
        navigate(BACKUP_WALLET_STEP03_PAGE);
    }

    const onLoad = async () => {
        if(isApp()) {
            // 강제 접속시,
            if(currentStep < 2) {
                navigate(BACKUP_WALLET_STEP01_PAGE);
            }
            else {
                setCurrentStep(2);  
                if(isApp()) {
                    const walletList = await getItem('wallets');
                    const wallets = JSON.parse(walletList);
                    const currentWallet = wallets.find(item => item.accounts.some(account => account.isSelected));
                    setMnemonic(currentWallet.mnemonic.split(' '));
                }
            }
        }
    }
    useEffect(() =>{
        onLoad();
    },[])

    return (
        <div className='flex-1 px-[24px] pt-[16px] pb-[40px] flex flex-col overflow-y-auto'>
            <div className='flex flex-col gap-[24px]'>
                <div className='text-white text-[20px] font-bold' dangerouslySetInnerHTML={{__html : t('35')}} />
                {
                    mnemonic.length > 0 &&
                    <div className='flex flex-wrap gap-[8px]'>
                        {
                            mnemonic.map((item,index) => <div key={index} className='p-[16px] rounded-[4px] bg-dark-navy text-lavender-gray'>
                                {item}
                            </div>)
                        }
                    </div>
                }
            </div>
            <div className='flex-1' />
            <div className='bg-primary-yellow px-[54.5px] py-[18.5px] rounded-[4px]
                            text-[16px] text-dark-purple text-center' onClick={nextStep}>
                {t('36')}
            </div>
        </div>
    );
}

export default BackupWallet02;
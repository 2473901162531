import React from 'react';

function AssetDetailInfoItem({ title, price, color }) {
    return (
        <div className='flex flex-row text-[16px] justify-between py-[16px]'>
            <div className='text-light-gray-purple'>
                {title}
            </div>
            <div className={`${color ? (parseInt(price) > 0 ? 'text-success' : parseInt(price) < 0 ? 'text-light-red' : 'text-white') : 'text-white'}`}>
                {price}
            </div>
        </div>
    );
}

export default AssetDetailInfoItem;
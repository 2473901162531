import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubTitle from '../components/SubTitle';
import SettingItem from '../components/SettingItem';
import { SETTING_MENU_LIST } from '../global/Constant';
import { useWalletStore } from '../store/WalletStore';
import { getItem, isApp } from '../utils/CommonUtil';
import InfoItem from '../components/InfoItem';
import { useDefaultStore } from '../store/DefaultStore';

function Setting() {
    const { t } = useTranslation();
    const { currentWallet } = useWalletStore(['currentWallet']);
    const [language, setLanguage] = useState('');
    const {currency} = useDefaultStore(['currency']);

    const onLoad = async () => {
        if (isApp()) {
            let lang = await getItem('language');
            if (lang === null) lang = 'English';
            else {
                if (lang === 'ko') {
                    lang = 'Korean';
                } else {
                    lang = 'English';
                }
            }
            setLanguage(lang);
        }
    }
    // onLoad
    useEffect(() => {
        onLoad();
    }, []);


    return (
        <div className='flex-1 overflow-y-auto pb-[145px]'>
            <div className='flex flex-col gap-[40px]  px-[1.5rem] py-[1rem]'>
                <div className='flex flex-col'>
                    <SubTitle title={t('17')} />
                    <SettingItem src={SETTING_MENU_LIST[0].list[0].src}
                        title={t('18')}
                        url={SETTING_MENU_LIST[0].list[0].url}
                    />
                    <SettingItem src={SETTING_MENU_LIST[0].list[1].src}
                        title={t('19')}
                        subTitle={currentWallet?.publicKey}
                        url={SETTING_MENU_LIST[0].list[1].url}
                    />
                    <SettingItem src={SETTING_MENU_LIST[0].list[2].src}
                        title={t('20')}
                        url={SETTING_MENU_LIST[0].list[2].url}
                    />
                    {(!currentWallet?.isWatch && !currentWallet?.isBackup) && <SettingItem src={SETTING_MENU_LIST[0].list[3].src}
                        title={t('21')}
                        url={SETTING_MENU_LIST[0].list[3].url} />}
                    {
                        (!currentWallet?.isWatch && !currentWallet?.isPrivateKey && currentWallet?.isBackup) && <SettingItem src={SETTING_MENU_LIST[0].list[4].src}
                            title={t('23')}
                            url={SETTING_MENU_LIST[0].list[4].url} />
                    }
                </div>
                <div className='flex flex-col'>
                    <SubTitle title={t('24')} />
                    <SettingItem src={SETTING_MENU_LIST[1].list[0].src}
                        title={t('25')}
                        url={SETTING_MENU_LIST[1].list[0].url} />
                    <SettingItem src={SETTING_MENU_LIST[1].list[1].src}
                        title={t('26')}
                        subTitle={language.toLocaleLowerCase() === 'english' ? t('89') : t('90')}
                        url={SETTING_MENU_LIST[1].list[1].url} />
                    <SettingItem src={SETTING_MENU_LIST[1].list[2].src}
                        title={t('27')}
                        subTitle={currency}
                        url={SETTING_MENU_LIST[1].list[2].url} />
                </div>
                <div className='flex flex-col'>
                    <SubTitle title={t('29')} />
                    <SettingItem src={SETTING_MENU_LIST[2].list[0].src}
                        title={t('29')}
                        url={SETTING_MENU_LIST[2].list[0].url} 
                        isWeb />
                </div>
            </div>
            {/* 밑줄 */}
            <div className='px-[24px] flex flex-col gap-[24px] text-light-gray-purple'>
                <div className='h-[1px] bg-[#343140]' />
                {/* 버전정보 */}
                <InfoItem title={t('30')} content={'1.0.0 (1)'} />
                <InfoItem title={t('31')} content={'2024/07'} />
            </div>
        </div>
    )
}

export default Setting;
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getItem, isApp, setItem } from '../utils/CommonUtil';
import { useGeckoStore } from '../store/GeckoStore';
import { axiosGecko } from '../lib/axios';
import { useDefaultStore } from '../store/DefaultStore';
import { MARKET_API } from '../global/Constant';
// import { getItem, isApp, setItem } from '../utils/CommonUtils';

function ChangeCurrency() {
    const {marketData,setMarketData,updateTime,setUpdateTime} 
    = useGeckoStore(['marketData','setMarketData','updateTime','setUpdateTime']);
    const { t, i18n } = useTranslation();
    const {currency,setCurrency} = useDefaultStore(['currency','setCurrency']);

    const onChangeCurrency = async (moneyUnit) => {
        if (isApp()) {
            await setItem('currency', moneyUnit);
            setCurrency(moneyUnit);
            // 게코api 마켓데이터 조회
            const params = {
                'vs_currency' : moneyUnit.toLowerCase(),
                'ids' : 'staked-ether,wrapped-eeth,pepe,ethena-usde,polygon,leo-token,dai,uniswap,chainlink,wrapped-bitcoin,shiba-inu,ethereum,tether,usd-coin,binancecoin,ssv-network',
                'per_page' : 16,
                'page' : 1,
                'sparkline' : false,
                'price_change_percentage' : '24h'
            };
            const timestamp = new Date().getTime();
            try{
                const marketResponse = await axiosGecko(MARKET_API,{params});    
                // console.dir(marketResponse)
                setMarketData(marketResponse.data);
                setUpdateTime(timestamp);
                setCurrency(moneyUnit);
            }
            catch(ex) {
                console.log("Err::",ex)
            }
        }
    }

    return (
        <div className='flex-1 flex flex-col'>
            <div className='px-[24px] py-[16px] flex flex-row gap-[16px] items-center'>
                <div className='flex-1 text-white text-[16px]'>
                    {/* {t('89')} */}
                    USD
                </div>
                <div className='w-[24px] h-[24px] cursor-pointer' onClick={() => onChangeCurrency('USD')}>
                    <img src={`/assets/icon/radio${currency === 'USD' ? "_checked" : ""}.png`} alt='' />
                </div>
            </div>
            <div className='px-[24px] py-[16px] flex flex-row gap-[16px] items-center'>
                <div className='flex-1 text-white text-[16px]'>
                    {/* {t('90')} */}
                    KRW
                </div>
                <div className='w-[24px] h-[24px] cursor-pointer' onClick={() => onChangeCurrency('KRW')}>
                    <img src={`/assets/icon/radio${currency === 'KRW' ? "_checked" : ""}.png`} alt='' />
                </div>
            </div>
        </div>
    );
}

export default ChangeCurrency;
import React, { useEffect, useMemo } from 'react';
import { calculatePercent, calculatePrice, getCurrencyUnit, numberWithCommas } from '../utils/CommonUtil';
import { useTranslation } from 'react-i18next';
import { useNetworkStore } from '../store/NetworkStore';
import { useGeckoStore } from '../store/GeckoStore';
import { useDefaultStore } from '../store/DefaultStore';

function MainTotal() {
    const {t } = useTranslation();
    const {networks} = useNetworkStore(['networks']);
    const {marketData} = useGeckoStore(['marketData']);
    const {currency} = useDefaultStore(['currency']);

    const totalPrice = useMemo(() => {
        let sum = 0;
        if(networks !== null && marketData !== null) {
            Object.entries(networks.asset).map(([subGroupKey, subGroupItem]) => {
                subGroupItem.length > 0 && subGroupItem.filter(item => item.isMain).map((item,index) => {
                    const marketInfo = marketData.find(marketItem => marketItem.symbol === item.alias.toLowerCase())
                    if(marketInfo) {
                        sum += item.balance * marketInfo.current_price;
                    }
                })
            });
        }
        return sum;
    },[marketData, networks])

    const totalPrevPrice = useMemo(() => {
        let sum = 0;
        if(networks !== null && marketData !== null) {
            Object.entries(networks.asset).map(([subGroupKey, subGroupItem]) => {
                subGroupItem.length > 0 && subGroupItem.filter(item => item.isMain).map((item,index) => {
                    const marketInfo = marketData.find(marketItem => marketItem.symbol === item.alias.toLowerCase())
                    if(marketInfo) {
                        sum += calculatePrice((item.balance * marketInfo.current_price),marketInfo.price_change_percentage_24h);
                    }
                })
            });
        }
        return sum;
    },[marketData, networks])

    const totalPrevPercent = useMemo(() => {
        return calculatePercent(totalPrice,totalPrevPrice);
    },[totalPrice,totalPrevPrice])
    
    return (
        <div className='flex flex-col items-center gap-[0.25rem] px-[1.5rem]'>
            <div className='text-[1.5rem] font-bold text-white'>
                {getCurrencyUnit(currency) + ' ' + numberWithCommas({
                    number: parseFloat(totalPrice || 0),
                    decimalPlaces: 2,
                    isB: true
                })}
            </div>
            <div className='flex flex-row gap-[4px]'>
                <div className='text-[1rem] text-light-gray-purple'>
                    {getCurrencyUnit(currency) + ' ' + numberWithCommas({
                        number: parseFloat(totalPrevPrice || 0),
                        decimalPlaces: 2,
                        isB: true
                    })} 
                </div>
                <div className='text-[1rem] text-light-gray-purple'>
                    ({numberWithCommas({
                        number: parseFloat(totalPrevPercent || 0),
                        decimalPlaces: 2,
                        isB: true
                    })}%)
                </div>
                <div className='text-[0.875rem] bg-dark-gray-purple text-lavender-gray py-[0.125rem] px-[0.25rem] rounded-[0.125rem]'>
                    {t('8')}
                </div>
            </div>
        </div>
    );
}

export default MainTotal;
import React from 'react';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
function ErrorWindowOpenModal({isOpen,setIsOpen, title}) {
    return (
        <Modal open={isOpen} center classNames={{
            overlay : 'custom_overlay',
            modal: 'custom_modal'
        }} showCloseIcon={false} onClose={() => setIsOpen(false)}>
            <div className='flex flex-col gap-[32px] px-[24px] py-[32px] items-stretch'>
                <div className='flex flex-col gap-[16px] w-[264px]'>
                    <div className='flex justify-center items-center'>
                        <img src='/assets/icon/error.png' alt='' />
                    </div>
                    <div className='text-white text-[20px] font-bold text-center'>{title}</div>
                </div>
                <div className='flex flex-row justify-center items-center'>
                    <div className='bg-primary-yellow py-[18.5px] px-[69px] text-dark-purple text-[16px] rounded-[4px] cursor-pointer' onClick={() => setIsOpen(false)}>
                        OK
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default ErrorWindowOpenModal;
import React, { useCallback, useEffect, useRef } from 'react';
import { getItem, onPaste, onQrScanner } from '../../utils/CommonUtil';
import { useImportStore } from '../../store/ImportStore';
import { useTranslation } from 'react-i18next';

function ImportWalletByWatch() {
    const { importText, setImportText, setWordCount,setIsValid } = useImportStore(['importText', 'setImportText', 'setWordCount','setIsValid']);
    const watchTextRef = useRef(null);
    const { t } = useTranslation();

    const onChangeWatchText = useCallback((e) => {
        setImportText(e.target.value);
    },[setImportText])
    
    useEffect(() => {
        setImportText('')
        setWordCount(0)
        setIsValid(true)
    }, [])

    useEffect(() => {
        const textarea = watchTextRef.current;
        textarea.style.height = 'auto';
        textarea.style.height = Math.min(textarea.scrollHeight, 38) + 'px';
    }, [importText]);


    return (
        <div className='flex flex-col px-[24px]'>
            <div className='flex flex-col gap-[4px]'>
                <div className='text-[16px] text-lavender-gray'>{t('64')}</div>
                <div className={`rounded-[4px] px-[16px] py-[18.5px] border border-dark-gray-purple flex flex-row items-center gap-[10px]`}>
                    <textarea className='resize-none box-border w-full h-full bg-transparent text-white outline-none auto-height'
                        placeholder={t('64')}
                        value={importText} onChange={onChangeWatchText}
                        ref={watchTextRef} rows={1}
                    ></textarea>
                    <div className='flex-none w-[24px] h-[24px] cursor-pointer' onClick={async () => {
                        let lang = await getItem('language');
                        if (lang === null) lang = 'en';
                        onQrScanner({setState : setImportText,lang : lang})
                    }}>
                        <img src='/assets/icon/scan.png' alt='' />
                    </div>
                </div>
                <div className='flex flex-row justify-end'>
                    <div className='text-[16px] text-light-yellow cursor-pointer' onClick={() => onPaste({setState : setImportText})}>
                    {t('72')}
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ImportWalletByWatch;
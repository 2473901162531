import React from 'react';

function Browser() {
    return (
        <div className='flex-1'>
            
        </div>
    );
}

export default Browser;
import { useShallow } from "../lib/hooks/useShallow";
import { createWithEqualityFn } from "zustand/traditional"


const initialState = {
    sendingToAddress: "",
    sendingContractAddress: "",
    sendingAmount: 0,
    sendingAvailable: 0,
    sendingMaxPriorityFeePerGas: 0,
    sendingMaxFeePerGas: 0,
    sendingSpeed: 'Averge',
    sendingGaspriceBsc: 0
}

export const SendingInfoStore = createWithEqualityFn((set) => ({
    ...initialState,
    setSendingToAddress: (item) => set({ sendingToAddress: item }),
    setSendingContractAddress: (item) => set({ sendingContractAddress: item }),
    setSendingAmount: (item) => set({ sendingAmount: item }),
    setSendingAvailable: (item) => set({ sendingAvailable: item }),
    setSendingMaxPriorityFeePerGas: (item) => set({ sendingMaxPriorityFeePerGas: item }),
    setSendingMaxFeePerGas: (item) => set({ sendingMaxFeePerGas: item }),
    setSendingSpeed: (item) => set({ sendingSpeed: item }),
    setSendingGaspriceBsc: (item) => set({ sendingGaspriceBsc: item }),
}), Object.is);

export const useSendingInfoStore = (keys) => {
    return useShallow(SendingInfoStore, keys);
}
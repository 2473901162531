import React, { useEffect, useState } from 'react';
import { getItem, isApp, setItem } from '../utils/CommonUtil';
import SubTitle from '../components/SubTitle';
import { useNavigate } from 'react-router-dom';

function SelectActiveNetworks() {
  const [networks, setNetworks] = useState();
  const [chains, setChains] = useState();
  const onLoad = async () => {
    if (isApp()) {
      const networkList = await getItem('NETWORK_LIST');
      setNetworks(JSON.parse(networkList));

      const maintest = {
        'MAINNET': [],
        'TESTNET': [],
      }
      JSON.parse(networkList)?.map((network, i) => {
        if (network?.isMain && network?.isCoin) {
          maintest['MAINNET'].push([network?.groupName, network?.chainId, network?.display]);
        }
        if (!network?.isMain && network?.isCoin) {
          maintest['TESTNET'].push([network?.groupName, network?.chainId, network?.display]);
        }
      })
      setChains(maintest);
    }
  }
  // onLoad
  useEffect(() => {
    onLoad();
  }, []);


  const onChangeNetworkEnable = async (mainTest, curNetworkEnable) => {

    let isMainState = true;
    if (mainTest === "MAINNET") isMainState = true;
    else isMainState = false;

    if (isApp()) {
      const updateNet = networks.map((network) => {
        if (network.isMain === isMainState) network.display = !curNetworkEnable

        return network;
      })
      setNetworks(updateNet)
      await setItem('NETWORK_LIST', JSON.stringify(updateNet));
      await onLoad();
    }
  };

  const onChangeChainEnable = async (mainTest, groupName, curChainEnable) => {

    let isMainState = true;
    if (mainTest === "MAINNET") isMainState = true;
    else isMainState = false;

    if (isApp()) {
      let selectedSymbol = "";
      const updateNet = networks.map((network) => {
        if (network?.groupName === groupName) network.display = !curChainEnable

        return network;
      })
      setNetworks(updateNet);
      await setItem('NETWORK_LIST', JSON.stringify(updateNet));
      await onLoad();
    }
  }

  // NETWORK_ENABLE
  const isMainTestNetworkEnable = (networkList) => {
    let result = false
    networkList.map((network) => {
      result = result || network[2]
    })

    return result;
  }


  return (
    <div className='flex-1 flex flex-col pt-[24px] px-[24px] gap-[40px]'>
      {
        networks &&
        Object.keys(chains).map((chain, index) => {
          return (
            <div className='flex flex-col' key={index}>
              <div className='flex flex-row gap-[16px] items-center'>
                <SubTitle title={chain.charAt(0).toUpperCase() + chain.slice(1)} />
                <div className="slider-container cursor-pointer" >
                  <div className="bar-image" />
                  <div
                    className={`toggle-button ${isMainTestNetworkEnable(chains[chain]) ? 'toggled' : ''} cursor-pointer`} onClick={() => onChangeNetworkEnable(chain, isMainTestNetworkEnable(chains[chain]))}
                  />
                </div>
              </div>
              {
                chains[chain].map((chainData, idx) => {
                  return (
                    <div key={idx} className='flex flex-row items-center gap-[16px] py-[16px]'>
                      <div className='flex-1 flex flex-row items-center'>
                        <div className='w-[24px] h-[24px]'>
                          <img src='/assets/gnb/menu.png' alt='' />
                        </div>
                        <div className='flex flex-col flex-1'>
                          <div className='text-[16px] text-white'>{chainData[0]}</div>
                          <div className='text-light-gray-purple'>Chain ID : {chainData[1]}</div>
                        </div>
                        <div className='w-[24px] h-[24px] cursor-pointer' >
                          <img src={`/assets/icon/checkbox${chainData[2] ? '_checked' : ''}.png`} alt='' onClick={() => onChangeChainEnable(chain, chainData[0], chainData[2])} />
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
      {/* <pre className='text-white'>{JSON.stringify(networks,null,2)}</pre> */}
    </div>
  );
}

export default SelectActiveNetworks;
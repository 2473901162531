import React, {  useEffect, useState } from 'react';
import { commonGroupByDate, getItem, isApp } from '../utils/CommonUtil';
import { axiosBscCoinScan, axiosBscTokenScan, axiosEtherScan, axiosSepoliaScan, axiosTbnbCoinScan, axiosTbnbTokenScan } from "../lib/axios";
import { useWalletStore } from '../store/WalletStore';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';
import SubTitle from '../components/SubTitle';
import ActivityItem from '../components/ActivityItem';

function Activity( {item} ) {
    const {t } = useTranslation();
    const {currentWallet}  = useWalletStore(['currentWallet']);
    const [activities, setActivities] = useState(null);
    const [loading, setLoading] = useState(false);
    
    const onLoadActivities = async (networkList) => {
        let promises = [];
        for (let networkItem of networkList.filter( (network) => {
            if ( item === undefined ) return true;
            else if ( network.name == item?.name ) {
                if (item.isNft) {
                    if (item.contractAddress === network.contractAddress) return true;
                    else return false
                } else return true;
            }else return false;
        })) 
        {
            
            const params = {
                module : 'account',
                address : currentWallet.publicKey,
                startblock : 0,
                endblock : 999999999,
                page : 1,
                offset : 100,
                sort : 'desc',
                
            };
            let response;
            let axiosObj;
            const ethApiKey = process.env.REACT_APP_ETH_API_KEY;
            const bnbApiKey = process.env.REACT_APP_BNB_API_KEY;
            if(networkItem.symbol === 'eth' || networkItem.groupName === 'Seporila(Testnet)' ) {
                if(networkItem.isNft){
                    params.action = 'tokennfttx';
                    params.contractaddress = networkItem.contractAddress;
                    params.apikey = ethApiKey;
                }
                else {
                    if(networkItem.isCoin) {
                        params.action = 'txlist'; 
                        params.apikey = ethApiKey;
                    }
                    else {
                        params.action = 'tokentx';
                        params.contractaddress = networkItem.contractAddress;
                        params.apikey = ethApiKey;
                    }    
                }
                if ( networkItem.isMain ) axiosObj = axiosEtherScan;
                else axiosObj = axiosSepoliaScan;
            }
            else if(networkItem.symbol === 'bnb' || networkItem.groupName === 'BINANCE(BSC-Testnet)' ) {
                if(networkItem.isNft) {
                    params.action = 'tokennfttx';
                    params.apikey = bnbApiKey;
                    params.contractaddress = networkItem.contractAddress;

                    if ( networkItem.isMain ) axiosObj = axiosBscCoinScan;
                    else axiosObj = axiosTbnbCoinScan;
                }
                else {
                    if(networkItem.isCoin) {
                        params.action = 'txlist';
                        params.apikey = bnbApiKey;

                        if ( networkItem.isMain ) axiosObj = axiosBscCoinScan;
                        else axiosObj = axiosTbnbCoinScan;
                    }
                    else {
                        params.action = 'tokentx';
                        params.contractaddress = networkItem.contractAddress;
                        params.apikey = bnbApiKey;

                        if ( networkItem.isMain ) axiosObj = axiosBscTokenScan;
                        else axiosObj = axiosTbnbTokenScan;
                    }
                }
            }

            await axiosObj.get('/api',{params}).then( (res, err) => {
                const {result} = res.data;
                if (Array.isArray(result)) {
                    result.forEach(item => {
                        item.networkInfo = networkItem;
                    });
                    promises = [...promises, ...result];
                }
            });
        }
       return await Promise.all(promises);
    }

    

    const onLoad = async () => {
        // 1. network list  조회
        setLoading(true);
        const networkList = await getItem('NETWORK_LIST');
        const networks = JSON.parse(networkList);
        if ( item === undefined || item.isMain === true ) {
            const apiNetworkList = networks.filter(network => network.isMain)
            const results = await onLoadActivities(apiNetworkList);
            setActivities(commonGroupByDate(results,t));
        } else {
            // testnet, selectedItem
            const apiNetworkList = networks.filter(network => !network.isMain)
            const results = await onLoadActivities(apiNetworkList);
            setActivities(commonGroupByDate(results,t));
        }
        setLoading(false);
    }

    useEffect(() => {
        if(isApp()) {
            onLoad();
        }
    },[])
    return (
        <div className={`flex-1 overflow-y-auto py-[0.5rem] flex flex-col ${loading ? 'justify-center' : ''}`}>
            <div className='flex flex-col px-[1.5rem] text-white gap-[24px]'>
                {
                    loading
                    ? <div className='flex-1 flex flex-row justify-center'>
                        <ClipLoader
                            size={40}
                            color={"#FFCC48"}
                            loading={true}
                            speedMultiplier={0.8}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                    : activities !== null && Object.entries(activities).map(([activityKey, activityItem]) => 
                        <div key={activityKey}>
                            <SubTitle title={activityKey} />
                            {activityItem.map((item,index) =>  <ActivityItem key={index} item={item} />)}
                        </div>
                    )
                }
            </div>
        </div>
    );
}

export default Activity;

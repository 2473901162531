import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { COLLECTIBLE_DETAIL_TAB_LIST } from '../global/Constant';
import CollectibleDetailInfo from '../components/CollectibleDetailInfo';
import Activity from './Activity';

function CollectibleDetail() {
    const location = useLocation();
    const { t } = useTranslation();
    const {item, tabIdx} = location.state;

    const [tabList, setTabList] = useState(COLLECTIBLE_DETAIL_TAB_LIST);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [filterText, setFilterText] = useState('');

    const onChnageTab = (index) => {
        setActiveTab(index);
    }

    const setActiveTab = (selectedIndex) => {
        setActiveTabIndex(selectedIndex);
        setTabList(tabList.map((item, index) => ({
            ...item,
            active: index === selectedIndex
        })));
    }

    const onFilterList = (value) => {
        setFilterText(value);
    }

    useEffect( () => {
        if ( tabIdx !== undefined ) setActiveTab(tabIdx);
    }, [])

    return (
        <div className={`flex-1 flex flex-col overflow-hidden ${activeTabIndex === 0 ? 'px-[24px]' : ''}`}>
            <div className={`flex-1 flex flex-col ${activeTabIndex === 0 ? 'gap-[32px]' : 'gap-[24px]'} overflow-hidden`}>
                <div className='flex flex-row justify-center gap-[48px]'>
                    {
                        tabList.map((item, index) =>
                            <div key={index} className='text-medium-gray text-center relative text-[1rem] cursor-pointer' onClick={() => onChnageTab(index)}>
                                <div className={`w-[0.1875rem] h-[0.1875rem] rounded-[50%] ${item.active ? 'bg-white' : ''} mx-auto`} />
                                <div className={item.active ? 'text-white' : ''}>{t(item.key)}</div>
                            </div>)
                    }
                </div>

                {/* 검색창 */}
                {
                    activeTabIndex === 0 &&
                    <div className='p-[0.5rem] flex flex-row gap-[0.5rem] bg-dark-navy rounded-[0.25rem]'>
                        <img src='/assets/icon/search.svg' alt='검색 아이콘' />
                        <input type='text' placeholder={t('12')}
                            className='flex-1 bg-transparent focus:outline-none text-[0.875rem] text-[#ffffff] placeholder-medium-gray'
                            value={filterText} onChange={(e) => onFilterList(e.target.value)} />
                    </div>
                }

                <div className='flex-1 overflow-y-auto flex felx-col text-white'>
                    {
                        activeTabIndex === 0
                            ? <CollectibleDetailInfo item={item} />
                            : <Activity item={item}/>
                    }
                </div>

            </div>
            {/* <pre className='text-white'>{JSON.stringify(item, null, 2)}</pre> */}
        </div>
    );
}

export default CollectibleDetail;
import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import Login from './pages/Login';
import Main from './pages/Main';
import Wallets from './pages/Wallets';
import Setting from './pages/Setting';
import MyWalletAddress from './pages/MyWalletAddress';
import NameThisWallet from './pages/NameThisWallet';
import SelectActiveNetworks from './pages/SelectActiveNetworks';
import ChangeLanguage from './pages/ChangeLanguage';
import BackupWallet01 from './pages/BackupWallet01';
import BackupWallet02 from './pages/BackupWallet02';
import BackupWallet03 from './pages/BackupWallet03';
import BackupWallet04 from './pages/BackupWallet04';
import ManageWallet from './pages/ManageWallet';
import ImportWallet from './pages/ImportWallet';
import AddHideTokens from './pages/AddHideTokens';
import AddCustomToken from './pages/AddCustomToken';
import AssetDetail from './pages/AssetDetail';
import CollectibleDetail from './pages/CollectibleDetail';
import Activity from './pages/Activity';
import ActivityDetail from './pages/ActivityDetail';
import TransactionDetail from './pages/TransactionDetail';
import CollectibleMetadata from './pages/CollectibleMetadata';
import ShowSeedPharse from './pages/ShowSeedPharse';
import SendAssets from './pages/SendAssets';
import SetSpeed from './pages/SetSpeed';
import ChangeCurrency from './pages/ChangeCurrency';
import SendCollectibles from './pages/SendCollectibles';
import Browser from './pages/Browser';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: 'import_wallet/:importType',
    element: <ImportWallet />
  },
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <Main />,
      },
      {
        path: 'wallets',
        element: <Wallets />
      },
      {
        path : 'browser',
        element : <Browser />
      },
      {
        path: 'setting',
        element: <Setting />
      },
      {
        path: 'my_wallet_address',
        element: <MyWalletAddress />
      },
      {
        path: 'name_this_wallet',
        element: <NameThisWallet />
      },
      {
        path: 'backup_wallet/',
        children: [{
          path: 'step01',
          element: <BackupWallet01 />
        }, {
          path: 'step02',
          element: <BackupWallet02 />
        }, {
          path: 'step03',
          element: <BackupWallet03 />
        }, {
          path: 'step04',
          element: <BackupWallet04 />
        }]
      },
      {
        path: 'show_seed_pharse',
        element: <ShowSeedPharse />
      },
      {
        path: 'select_active_networks',
        element: <SelectActiveNetworks />
      },
      {
        path: 'change_language',
        element: <ChangeLanguage />
      },
      {
        path: 'change_currency',
        element: <ChangeCurrency />
      },
      {
        path: 'manage_wallet',
        element: <ManageWallet />
      },
      {
        path : 'add_hide_tokens',
        element : <AddHideTokens />
      },
      {
        path : 'add_custom_token',
        element : <AddCustomToken />
      },
      {
        path : 'asset_detail',
        element : <AssetDetail />
      },
      {
        path : 'send_assets',
        element : <SendAssets />
      },
      {
        path : 'collectible_detail',
        element : <CollectibleDetail />
      },
      {
        path : 'collectible_metadata',
        element : <CollectibleMetadata />
      },
      {
        path : 'send_collectibles',
        element : <SendCollectibles />
      },
      {
        path: 'activity',
        element: <Activity />,
      },
      {
        path: 'activity_detail',
        element: <ActivityDetail />,
      },
      {
        path : 'transaction_detail',
        element : <TransactionDetail />
      },
      {
        path : 'set_speed',
        element : <SetSpeed />
      }
    ]
  }
]);

export default router;

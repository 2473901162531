import React, { useCallback, useEffect } from 'react';
import BackupDot from './BackupDot';
import { calculatePercent, calculatePrice, getCurrencyUnit, getItem, isApp, numberWithCommas, setItem, shortenWalletAddress, success } from '../utils/CommonUtil';
import { useNetworkStore } from '../store/NetworkStore';
import { useGeckoStore } from '../store/GeckoStore';
import { getCoinBalance, getTokenBalance } from '../utils/WalletUtil';
import { useWalletStore } from '../store/WalletStore';
import { useNavigate } from 'react-router-dom';
import { MAIN_PAGE, MANAGE_WALLET_PAGE } from '../global/Constant';
import { useDefaultStore } from '../store/DefaultStore';

function WalletItem({walletItem}) {
    const {networks,setNetworks} = useNetworkStore(['networks','setNetworks']);
    const {marketData} = useGeckoStore(['marketData']); 
    const {setCurrentWallet,changePriceWalletGroup,changeSelectedWalletGroup} = useWalletStore(['setCurrentWallet','changePriceWalletGroup','changeSelectedWalletGroup']);
    const navigate = useNavigate();
    const { currency } = useDefaultStore(['currency']);

    const onChangeCurrentWallet = async () => {
        if (isApp() && !walletItem.isSelected) {
            
            const walletList = await getItem('wallets');
            if (walletList !== null) {
                const walletsByStorage = JSON.parse(walletList);
                // 1. 현재 선택되어있는 아이템이면 동작 중지,
                const currentWallet = walletsByStorage.find(wallet => wallet.accounts.some(account => account.publicKey === walletItem.publicKey));
                if (currentWallet === undefined) {
                    return;
                }
                const updateWallet = walletsByStorage.map((wallet) => {
                    const updateAccount = wallet.accounts.map((account) => {
                        return {
                            ...account, isSelected: account.publicKey === walletItem.publicKey
                        }
                    })
                    return {
                        ...wallet, accounts: updateAccount
                    }
                })
                await setItem('wallets', JSON.stringify(updateWallet));
                const changeWallet = {};
                updateWallet.map(walletItem => {
                    const selectedAccount = walletItem.accounts.find(account => account.isSelected);
                    if(selectedAccount) {
                        changeWallet.isBackup = walletItem.isBackup;
                        changeWallet.index = selectedAccount.index;
                        changeWallet.isSelected = selectedAccount.isSelected;
                        changeWallet.publicKey = selectedAccount.publicKey;
                        changeWallet.nickName = selectedAccount.nickName;
                        changeWallet.isSeed = selectedAccount.isSeed;
                        changeWallet.isPrivateKey = selectedAccount.isPrivateKey;
                        changeWallet.isWatch = selectedAccount.isWatch;
                    }
                })
                setCurrentWallet(changeWallet);
                changeSelectedWalletGroup(walletItem.publicKey);
                await success();
            }
        }
    }

    const getBalance = async (networkList) => {
        let balance = 0; 
        const promises = [];
        for (let item of networkList) { 
            const marketItem = marketData.find(marketItem => marketItem.symbol === item.alias.toLowerCase() && item.isMain);
            if(item.isCoin){
                balance = await getCoinBalance(walletItem.publicKey, item.endPoint)
            }
            else {
                balance = await getTokenBalance(walletItem.publicKey, item.endPoint,item.contractAddress)
            } 
            promises.push({
                balance : balance,
                currentPrice : marketItem ? marketItem.current_price : 0,
                percent : marketItem ? marketItem.price_change_percentage_24h : 0
            })
        }
        return await Promise.all(promises);
    }

    const onLoad = useCallback(async () => {
        if(networks !== null) {
            const networkList = [];
            for(let [key,networkItem] of Object.entries(networks.asset)){
                if(networkItem.length > 0) {
                    networkItem.filter(item => item.display && item.isMain).map(item => {
                        const marketItem = marketData.find(marketItem => marketItem.symbol === item.alias.toLowerCase());
                        if(marketItem) {
                            networkList.push(item);
                        } 
                    })
                }
            }
            const results = await getBalance(networkList);
            const totalPrice = results.reduce((sum, item) => sum + (item.balance * item.currentPrice), 0);;
            const prevPrice = results.reduce((sum, item) => sum + calculatePrice(item.balance * item.currentPrice,item.percent), 0);
            const prevPercent = calculatePercent(totalPrice,prevPrice);
            changePriceWalletGroup(
                walletItem.publicKey,
                totalPrice,
                prevPrice,
                prevPercent
            );
        }
    },[]);
    
    const goManageWalletPage = () => {
        navigate(MANAGE_WALLET_PAGE, { state: { ...walletItem },replace : true })
    }

    useEffect(() => {
        const controller = new AbortController();
        // 추후에 주석제거
        if(isApp()){
            onLoad();
        }
        return () => {
            controller.abort();
          };
    },[])

    return (
        <div className='flex flex-row py-[16px] gap-[8px] items-center'>
            <div className='flex-none cursor-pointer w-[24px] h-[24px]' onClick={goManageWalletPage}>
                <img src='/assets/icon/menu.svg' alt='' />
            </div>
            <div className='flex-1 flex flex-row truncate cursor-pointer gap-[8px]' onClick={onChangeCurrentWallet}>
                <div className='flex-1 flex flex-row items-center gap-[8px] truncate'>
                    <div className='relative'>
                        <div className='w-[32px] h-[32px] rounded-[50%]'>
                            <img src='/assets/gnb/profile.svg' alt='' />
                        </div>
                        {
                            walletItem.isSelected && <BackupDot color={'bg-primary-yellow'} position={'left-0'} />
                        }
                    </div>
                    <div className='flex-1 flex flex-col truncate'>
                        <div className='text-white text-[16px]'>
                                {getCurrencyUnit(currency) + ' ' + numberWithCommas({
                                number : parseFloat(walletItem.totalPrice || 0),
                                decimalPlaces : 2,
                                isB : true})}
                            </div>
                            <div className='flex flex-row gap-[4px] text-light-gray-purple'>
                            <div className='truncate'>{walletItem.nickName}</div>
                            <div>/</div>
                            <div className='flex-1'>{shortenWalletAddress(walletItem.publicKey, 4, 4)}</div>
                        </div>
                    </div>
                </div>
                <div className={`flex flex-row  text-[16px] ${(walletItem.prevPercent || 0) === 0 ? 'text-light-gray-purple' : walletItem.prevPercent > 0 ? 'text-success' : 'text-light-red'}`}
                     onClick={() => {
                        setNetworks(null);
                        navigate(MAIN_PAGE, {replace : true})}
                    } >
                    <div>{walletItem.prevPercent > 0 && '+'}
                        {numberWithCommas({
                            number : parseFloat(walletItem.prevPercent || 0),
                            decimalPlaces : 2,
                            isB : true})}%
                    </div>
                    <div className='flex-none w-[24px] h-[24px]'>
                        <img src='/assets/icon/arrow-right.svg' alt='' />
                    </div>
                </div> 
            </div>
        </div>
    );
}

export default React.memo(WalletItem);
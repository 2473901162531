import axios from "axios";

/**
 * default
 */
export default axios.create({
  baseURL: 'http://localhost:3000',
  headers: { "Content-Type": "application/json" },
});

/**
 *  coin gecko
 */
export const axiosGecko = axios.create({
  baseURL: 'https://api.coingecko.com/api/v3/coins',
  headers: { "Content-Type": "application/json" },
});

/**
 *  ether scsan
 */
export const axiosEtherScan = axios.create({
  baseURL: 'https://api.etherscan.io',
  headers: { "Content-Type": "application/json" },
});
/**
 *  bsc scsan coin
 */
export const axiosBscCoinScan = axios.create({
  baseURL: 'https://api.bscscan.com',
  headers: { "Content-Type": "application/json" },
});
/**
 *  bsc scsan token
 */
export const axiosBscTokenScan = axios.create({
  baseURL: 'https://api.bscscan.com',
  headers: { "Content-Type": "application/json" },
});
/**
 *  seplia scsan
 */
export const axiosSepoliaScan = axios.create({
  baseURL: 'https://api-sepolia.etherscan.io',
  headers: { "Content-Type": "application/json" },
});
/**
 *  test bnb scsan
 */
export const axiosTbnbCoinScan = axios.create({
  baseURL: 'https://api-testnet.bscscan.com',
  headers: { "Content-Type": "application/json" },
});
export const axiosTbnbTokenScan = axios.create({
  baseURL: 'https://api-testnet.bscscan.com',
  headers: { "Content-Type": "application/json" },
});
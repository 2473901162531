import { useShallow } from '../lib/hooks/useShallow';
import { createWithEqualityFn } from 'zustand/traditional';

const initialState = {
    // loading : false,
    // walletAddress : '',
    // walletName : '',
    // language : '',
    // currency : '',
    // isBackup : true,
    // isWatch : false,
    // isPrivateKey : false,
    currentStep : 0,
    share : null,
    // shareValue : null,
    headerTitle : '',
    currency : 'USD',
    // tokenNameId : '',
    // send : '',
    // activity : '',
    // symbolImages : [],
    // activityInfoList : []
}

export const DefaultStore = createWithEqualityFn((set) => ({
    ...initialState,
    // setLoading: (items) => set({loading : items}),
    // setWalletAddress: (items) => set({walletAddress : items}),
    // setWalletName : (items) => set({walletName : items}),
    // setLanguage: (items) => set({language : items}),
    // setCurrency: (items) => set({langcurrencyuage : items}),
    // setIsBackup : (items) => set({isBackup : items}),
    // setIsWatch : (items) => set({isWatch : items}),
    // setIsPrivateKey : (items) => set({isPrivateKey : items}),
    setCurrentStep : (items) => set({currentStep : items}),
    setShare : (items) => set({share : items}),
    // setShareValue : (items) => set({shareValue : items}),
    setHeaderTitle : (items) => set({headerTitle : items}),
    setCurrency : (items) => set({currency : items}),
    // setTokenNameId : (items) => set({tokenNameId : items}),
    // setSend : (items) => set({send : items}),
    // setActivity : (items) => set({activity : items}),
    // setSymbolImages : (items) => set({symbolImages : items}),
    // setActivityInfoList : (items) => set({activityInfoList : items}),
    reset : () => {
        set(initialState)
    }
}),Object.is)

export const useDefaultStore = (keys) => {
    return useShallow(DefaultStore, keys);
};
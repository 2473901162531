import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDefaultStore } from '../store/DefaultStore';
import { Slide, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { getItem, isApp, shuffles, success } from '../utils/CommonUtil';
import { BACKUP_WALLET_STEP01_PAGE, BACKUP_WALLET_STEP04_PAGE } from '../global/Constant';

function BackupWallet03() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { currentStep,setCurrentStep } = useDefaultStore(['currentStep','setCurrentStep']);
    const [randomMnemonic,setRandomMnemonic] = useState([]);
    const [selectedMnemonic,setSelectedMnemonic] = useState([]);
    const nextStep = async () => {
        if(isApp()) {
            if(selectedMnemonic.length !== 12){
                return;
            }
            // 1. mnemonic validation 
            const walletList = await getItem('wallets');
            const wallets = JSON.parse(walletList);
            const currentWallet = wallets.find(item => item.accounts.some(account => account.isSelected));
            if(currentWallet.mnemonic === selectedMnemonic.join(' ')) {
                // 1. 브릿지 모달 toast 출력(V)
                await success();
                // 2. 다음스텝 설정
                setCurrentStep(4);
                // 3. 페이지 이동
                navigate(BACKUP_WALLET_STEP04_PAGE);
            }
            else {
                toast(t('39'),{
                    position : 'bottom-center',
                    autoClose : 1000,
                    closeButton : false,
                    hideProgressBar : true,
                    pauseOnHover : false,
                    draggable : false,
                    transition : Slide,
                    className : '!bg-dark-gray-purple !text-[14px] !text-white',
                    icon : <img src='/assets/icon/warning.png' alt='' />
                  });
            }
        }
    }

    const onSelectItem = (selectIndex) => {
        const updateMnemonic = randomMnemonic.map((item,index) => {
            if(selectIndex === index) {
                // 이미 선택이 되어있으면 배열에서 뺀다
                if(item.isSelected){
                    setSelectedMnemonic(selectedMnemonic.filter((word) => {
                        return word !== item.text
                    })); 
                }
                // 신규로 추가한다.
                else {
                    setSelectedMnemonic([...selectedMnemonic, item.text])
                }
            }

            return {
                ...item, isSelected : (selectIndex === index ? !item.isSelected  : item.isSelected)
            }
        })
        setRandomMnemonic(updateMnemonic);
    }

    const onLoad = async () => {
        if(isApp()) {
            // 강제 접속시,
            if(currentStep < 3) {
                navigate(BACKUP_WALLET_STEP01_PAGE);
            }
            else {
                setCurrentStep(3);  
                if(isApp()) {
                    const walletList = await getItem('wallets');
                    const wallets = JSON.parse(walletList);
                    const currentWallet = wallets.find(item => item.accounts.some(account => account.isSelected));
                    const mnemonicList = [];
                    shuffles(currentWallet.mnemonic.split(' ')).forEach(item => {
                        mnemonicList.push({
                            isSelected : false,
                            text : item
                        })
                    });
                    setRandomMnemonic(mnemonicList);
                }
            }
        }
    }
    useEffect(() =>{
        onLoad();
    },[])

    return (
        <div className='flex-1 px-[24px] pt-[16px] pb-[40px] flex flex-col overflow-y-auto'>
            <div className='flex flex-col gap-[24px]'>
                <div className='text-white text-[20px] font-bold' dangerouslySetInnerHTML={{__html : t('37')}} />
                <div className='py-[16px] px-[24px] flex flex-wrap gap-[8px] text-white rounded-[4px] border border-dark-gray-purple min-h-[111px]'>
                    {
                        selectedMnemonic.map((item,index) => <div key={index}>{item}</div>)
                    }
                </div>
                {
                    randomMnemonic.length > 0 &&
                    <div className='flex flex-wrap gap-[8px]'>
                        {
                            randomMnemonic.map((item,index) => <div key={index} className={`py-[12px] px-[16px] rounded-[4px] ${item.isSelected ? 'bg-transparent text-transparent border-dark-gray-purple border-dashed' : 'bg-dark-navy text-lavender-gray border-dark-navy'}  border  cursor-pointer`}
                                                                onClick={() => onSelectItem(index)}>
                                {item.text}
                            </div>)
                        }
                    </div>
                }
                <div className='flex-1' />
                <div className={`bg-primary-yellow px-[54.5px] py-[18.5px] rounded-[4px]
                                text-[16px] text-dark-purple text-center ${selectedMnemonic.length !== 12 ? 'opacity-50' : ''}`} onClick={nextStep}>
                    {t('38')}
                </div>
            </div>
        </div>
    );
}

export default BackupWallet03;
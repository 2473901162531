import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { convertIpfsUrl, shortenWalletAddress } from '../utils/CommonUtil';
import { useDefaultStore } from '../store/DefaultStore';
import { SEND_COLLECTIBLES_PAGE } from '../global/Constant';
import { useWalletStore } from '../store/WalletStore';
import { useSendingInfoStore } from '../store/SendingInfoStore';

function CollectibleMetadata() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const nftAlchemyItem = location.state.selectedCollectible;
    const nftAppitem = location.state.item;
    const { currentWallet } = useWalletStore(['currentWallet']);
    const {
        setSendingToAddress, setSendingContractAddress, setSendingAmount, setSendingAvailable, setSendingMaxPriorityFeePerGas, setSendingMaxFeePerGas, setSendingGaspriceBsc,
    } = useSendingInfoStore([
        'setSendingToAddress', 'setSendingContractAddress', 'setSendingAmount', 'setSendingAvailable', 'setSendingMaxPriorityFeePerGas', 'setSendingMaxFeePerGas', 'setSendingGaspriceBsc',
    ]);

    const goSendCollectible = () => {
        setSendingToAddress('');
        setSendingContractAddress('');
        setSendingAmount(0);
        setSendingAvailable(0);
        setSendingMaxPriorityFeePerGas(0);
        setSendingMaxFeePerGas(0);
        setSendingGaspriceBsc(0);
        navigate(SEND_COLLECTIBLES_PAGE, {
            state: {
                nftItem: nftAlchemyItem,
                openSetting: false,
                item : nftAppitem
            }
        })
    }

    return (
        <div className='flex-1 flex flex-col pt-[13px] px-[24px] gap-[40px]'>
            <div className='flex flex-col gap-[16px]'>
                <div>
                    {
                        nftAlchemyItem?.raw?.metadata?.image !== undefined ?
                            <img
                                src={nftAlchemyItem?.raw?.metadata?.image.startsWith("ipfs://") ? convertIpfsUrl(nftAlchemyItem?.raw?.metadata?.image) : nftAlchemyItem?.raw?.metadata?.image}
                                alt=''
                            /> :
                            <div className='truncate bg-lavender-gray w-[304px] h-[304px] rounded-[50%] text-medium-gray flex justify-center items-center text-[100px]'>
                                {nftAlchemyItem?.name}
                            </div>
                    }
                </div>
                <div className='text-white text-[16px] font-bold'>
                    {t('118')}
                </div>
                <div className='flex flex-row justify-between'>
                    <div className='text-light-gray-purple'>{t('109')} ID</div>
                    <div className='text-white'>{nftAlchemyItem?.tokenId}</div>
                </div>
                <div className='flex flex-row justify-between'>
                    <div className='text-light-gray-purple'>{t('108')}</div>
                    <div className='text-white'>{nftAppitem?.groupName}</div>
                </div>
                <div className='flex flex-row justify-between'>
                    <div className='text-light-gray-purple'>{t('77')}</div>
                    <div className='text-white'>{shortenWalletAddress(nftAppitem?.contractAddress, 4, 4)}</div>
                </div>
                <div className='flex flex-row justify-between'>
                    <div className='text-light-gray-purple'>{t('181')}</div>
                    <div className='text-white'>{nftAlchemyItem?.name}</div>
                </div>
            </div>
            <div className='flex flex-col gap-[8px]'>
                <div className='text-white text-[16px] font-bold'>
                    {t('182')}
                </div>
                <div className='text-lavender-gray'>
                    {nftAlchemyItem?.raw?.metadata?.description}
                </div>
            </div>
            <div className='flex flex-col gap-[16px]'>
                <div className='text-white text-[16px] font-bold'>
                    {t('183')}
                </div>
                <div className='grid grid-cols-2 gap-[8px]'>
                    {
                        nftAlchemyItem?.raw?.metadata?.attributes?.map((attribute, index) => {
                            return (
                                <div key={index} className='text-white bg-dark-navy p-[16px] text-[14px] rounded-[4px]'>
                                    <div>
                                        {attribute?.trait_type}
                                    </div>
                                    <div className='text-light-gray-purple'>
                                        {attribute?.value}
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            {
                !currentWallet.isWatch && <div className='flex justify-center'>
                    <div className='text-dark-purple bg-primary-yellow flex-1 mx-[60px] px-[36px] py-[18.5px] rounded-[4px] text-center' onClick={goSendCollectible}>
                        {t('184')}
                    </div>
                </div>
            }
            <div className='h-[40px]' />
        </div>
    );
}

export default CollectibleMetadata;
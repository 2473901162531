import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { BOTTOM_MENU_LIST, SETTING_PAGE } from '../global/Constant';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWalletStore } from '../store/WalletStore';
import BackupDot from './BackupDot'
import { useNetworkStore } from '../store/NetworkStore';
function BottomMenu() {
    const navigate = useNavigate();
    const location = useLocation();
    const {currentWallet}  = useWalletStore(['currentWallet']);
    const {setNetworks} = useNetworkStore(['setNetworks']);

    const [bottomList,setBottomList] = useState(BOTTOM_MENU_LIST);

    // 페이지 이동
    const onPage = (url) => {
        setNetworks(null);
        navigate(url, {replace : true, })
    }

    return (
        <Fragment>
            <div className='flex-none bg-dark-purple'>
                <div className='flex flex-row px-[1.6875rem] py-[1.25rem] justify-between'>
                    {
                        bottomList.map((item,index) => <div key={index} className={`cursor-pointer ${item.to === SETTING_PAGE ? 'relative' : ''}`} onClick={() => onPage(item.to)}>
                            <img src={`/assets/footer/${item.src}${location.pathname === item.to ? '_active' : ''}.svg`} alt='' />
                            {(!currentWallet?.isBackup && item.to === SETTING_PAGE) && <BackupDot />}
                        </div>)
                    }
                </div>
            </div>
        </Fragment>
    );
}

export default BottomMenu;
import React, { useCallback, useEffect } from 'react';
import Summary from '../components/Summary';
import SubTitle from '../components/SubTitle';
import { commonGroupByIsWatch, isApp } from '../utils/CommonUtil';
import WalletItem from '../components/WalletItem';
import { useWalletStore } from '../store/WalletStore';

function Wallets() {
    const { walletGroup,setWalletGroup } = useWalletStore(['walletGroup','setWalletGroup']);

    const onLoad = useCallback(async () => {
        await commonGroupByIsWatch(setWalletGroup);
    },[])
    useEffect(() => {
        const controller = new AbortController();
        
        if(isApp()){
            onLoad();    
        }
        return () => {
            controller.abort();
        };
    },[])
  
    return (
        <div className='flex-1 flex flex-col gap-[40px] overflow-y-auto'>
            <Summary walletGroup={walletGroup} />
            {
                walletGroup && Object.entries(walletGroup ?? {}).map(([walletGroupKey, walletGroupItem],walletGroupIndex) => 
                    <div className='flex flex-col px-[24px] text-white' key={walletGroupIndex}>
                        <SubTitle title={walletGroupItem.title} />
                        {
                            walletGroupItem.wallet.map((walletItem,walletIndex) => 
                                <WalletItem key={walletIndex} walletItem={walletItem} />
                            )
                        }
                    </div>
                )
            }
        </div>
    );
}

export default Wallets;
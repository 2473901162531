import React, { useCallback} from 'react';
import { numberWithCommas } from '../utils/CommonUtil';
import { useGeckoStore } from '../store/GeckoStore';

function CustomTokenItem({item}) {
    const {marketData} = useGeckoStore(['marketData']);

    // const [balance, setBalance] = useState(0);
    const getImage = useCallback((flag) => {
        if(marketData) {
            const marketItem = marketData.find(marketItem => marketItem.symbol === flag?.toLowerCase());
            if(marketItem) {
                return marketItem.image;
            }
        }
    },[])

    return (
        <div className='flex flex-row gap-[1rem] items-center py-[1rem] cursor-pointer'>
                {/* 이미지 아이콘 */}
                <div className='flex-none relative w-[40px] h-[40px] rounded-[50%] bg-white'>
                    {
                        getImage(item.alias) 
                            ? <img src={getImage(item.alias)} alt='' />
                            : (item.protocol === 721 && item.tokenMetadata && item.tokenMetadata.length > 0) ? <img src={item.tokenMetadata[0].image} alt='' className='object-cover' />
                            : <div className='truncate bg-lavender-gray w-[40px] h-[40px] rounded-[50%] text-medium-gray flex justify-center items-center text-[12px]'>
                                {item.alias}
                            </div>
                    }
                    {
                        (item.isMain && !item.isCoin)
                        && <div className='w-[14px] h-[14px] rounded-[50%] bg-medium-gray absolute left-0 bottom-0'>
                                <img src={getImage(item.symbol) ? getImage(item.symbol) : `/assets/symbol/${item.symbol}.svg`} alt='' className='' />
                            </div>
                    }
                </div>
                <div className='flex-1 flex flex-col'>
                    <div className='text-[1rem] text-white'>{item.name}</div>
                    <div className='text-[14px] text-light-gray-purple flex flex-row gap-[2px]'>
                        {
                            <div>
                                {numberWithCommas({
                                    number: parseFloat(item.balance || 0),
                                    decimalPlaces: 2,
                                    isB: true
                                })}
                            </div>
                        }
                        <div>
                            {item.alias}
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default CustomTokenItem;
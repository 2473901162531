import { useShallow } from '../lib/hooks/useShallow';
import { createWithEqualityFn } from 'zustand/traditional';
/**
 * 시세정보 api store
 */

const initialState = {
    networks : null
}

export const NetworkStore = createWithEqualityFn((set) => ({
    ...initialState,
    setNetworks: (items) => set({networks : items}),
    updateBalance: (coinItem, balance) => set((state) => {
        const updateNetworkItem = (networkItem) => networkItem.map((item) =>{
            if(coinItem.isCoin){
                if(item.endPoint === coinItem.endPoint){
                    return {...item, balance}
                }
            }
            else if(!coinItem.isCoin){
                if((item.endPoint === coinItem.endPoint) && (item.contractAddress === coinItem.contractAddress)){
                    return {...item, balance}
                }
            }
            return item;
        });
          
        const updateNetworks = Object.fromEntries(Object.entries(state.networks).map(([networkKey,networkItem]) => {
            const networkResult = Object.fromEntries(
                Object.entries(networkItem).map(([networkKey, networkItem]) => {
                return [networkKey, updateNetworkItem(networkItem)]
            }))
            return [networkKey,networkResult];
        }))
        return {networks : updateNetworks}
    }),
    updateMetadata: (coinItem, metadata) => set((state) => {
        const updateNetworkItem = (networkItem) => networkItem.map((item) =>{
            if(coinItem.isNft){
                if((item.endPoint === coinItem.endPoint) && (item.contractAddress === coinItem.contractAddress)){
                    return {...item, metadata}
                }
            }
            return item;
        });
          
        const updateNetworks = Object.fromEntries(Object.entries(state.networks).map(([networkKey,networkItem]) => {
            const networkResult = Object.fromEntries(
                Object.entries(networkItem).map(([networkKey, networkItem]) => {
                return [networkKey, updateNetworkItem(networkItem)]
            }))
            return [networkKey,networkResult];
        }))
        return {networks : updateNetworks}
    }),
    updateFilter: (keyword, isInit) => set((state) => {
        const regex = new RegExp(keyword, 'i');
        const updateNetworks = Object.fromEntries(Object.entries(state.networks).map(([networkKey,networkItem]) => {
            const updateNetworkItem = Object.fromEntries(Object.entries(networkItem).map(([subGroupKey, subGroupItem]) => {
                const updateSubGroupItem = subGroupItem.map((item,index) => {
                    return {
                        ...item,
                        isSearch : !isInit ? regex.test(item.name) : true
                    }
                })
                return [subGroupKey,updateSubGroupItem]
            }))
            return [networkKey, updateNetworkItem]
        }));
        return {networks : updateNetworks}
    }),
    reset : () => {
        set(initialState)
    }
}),Object.is)

export const useNetworkStore = (keys) => {
    return useShallow(NetworkStore, keys);
};
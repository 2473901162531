import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNetworkStore } from '../store/NetworkStore';

function SearchField() {
    const { t } = useTranslation();

    const {updateFilter} = useNetworkStore(['networks','updateFilter']);

    const [text , setText] = useState('');
    
    const onFilterList = useCallback((keyword) => {
        setText(keyword);
        updateFilter(keyword);
    },[updateFilter])
    return (
        <div className='p-[0.5rem] flex flex-row gap-[0.5rem] bg-dark-navy rounded-[0.25rem]'>
            <img src='/assets/icon/search.svg' alt='검색 아이콘' />
            <input type='text' placeholder={t('12')}
                className='flex-1 bg-transparent focus:outline-none text-[0.875rem] text-[#ffffff] placeholder-medium-gray'
                value={text} onChange={(e) => onFilterList(e.target.value)} />
        </div>
    );
}

export default SearchField;
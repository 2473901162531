import React, { useMemo } from 'react';
import { calculatePercent, getCurrencyUnit, numberWithCommas } from '../utils/CommonUtil';
import { useTranslation } from 'react-i18next';
import { useWalletStore } from '../store/WalletStore';
import { useDefaultStore } from '../store/DefaultStore';

function Summary() {
    const { walletGroup } = useWalletStore(['walletGroup']);
    const { t } = useTranslation();
    const {currency} = useDefaultStore(['currency']);


    const totalPrice = useMemo(() => {
        let sum = 0;
        if(walletGroup) {
            Object.entries(walletGroup).map(([walletGroupKey, walletGroupItem]) => {
                walletGroupItem.wallet.length > 0 && walletGroupItem.wallet.map((item,index) => {
                        sum += item.totalPrice;
                })
            });
        }
        return sum;
    },[walletGroup])
    const totalPrevPrice = useMemo(() => {
        let sum = 0;
        if(walletGroup) {
            Object.entries(walletGroup).map(([walletGroupKey, walletGroupItem]) => {
                walletGroupItem.wallet.length > 0 && walletGroupItem.wallet.map((item,index) => {
                    sum += item.prevPrice;
                })
            });
        }
        return sum;
    },[walletGroup])

    const totalPrevPercent = useMemo(() => {
        return calculatePercent(totalPrice,totalPrevPrice);
    },[totalPrevPrice, totalPrice])
    return (
        <div className='flex flex-col p-[24px] gap-[8px] bg-dark-navy'>
            <div className='text-light-gray-purple'>{t('43')}</div>
            <div className='flex flex-col gap-[4px]'>
                <div className='text-[24px] text-white'>
                    {getCurrencyUnit(currency) + ' ' + numberWithCommas({
                        number : parseFloat(totalPrice || 0),
                        decimalPlaces : 2,
                        isB : true})}
                </div>
                <div className='flex flex-row gap-[4px] text-light-gray-purple text-[16px]'>
                    <div>
                    {getCurrencyUnit(currency) + ' ' + numberWithCommas({
                        number : parseFloat(totalPrevPrice || 0),
                        decimalPlaces : 2,
                        isB : true
                    })}
                    </div>
                    <div>
                    ({numberWithCommas({
                            number : parseFloat(totalPrevPercent || 0),
                            decimalPlaces : 2,
                            isB : true})}%)  
                    </div>
                    <div className='bg-dark-gray-purple text-lavender-gray px-[4px] py-[2px] rounded-[2px]'>
                    {t('8')}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Summary
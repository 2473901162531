import { useShallow } from '../lib/hooks/useShallow';
import { createWithEqualityFn } from 'zustand/traditional';

const initialState = {
    importText : '',
    wordCount : 0,
    isValid : true
}

export const ImportStore = createWithEqualityFn((set) => ({
    ...initialState,
    setImportText: (items) => set({importText : items}),
    setWordCount: (items) => set({wordCount : items}),
    setIsValid :  (items) => set({isValid : items}),
    reset : () => {
        set(initialState)
    }
}),Object.is)

export const useImportStore = (keys) => {
    return useShallow(ImportStore, keys);
};
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSendingInfoStore } from '../store/SendingInfoStore';
import { useTranslation } from 'react-i18next';
import { convertIpfsUrl, get, getItem, isApp, isBoolean, onPaste, onQrScanner } from '../utils/CommonUtil';
import { getFeeData, sendNft721 } from '../utils/WalletUtil';
import { speedSetter } from '../global/Constant';
import { formatEther, parseUnits } from 'ethers';
import FailToBroadcastingTransactionModal from '../components/modal/FailToBroadcastingTransactionModal';
import TransactionInProgressModal from '../components/modal/TransactionInProgressModal';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { ClipLoader } from 'react-spinners';
import { useGeckoStore } from '../store/GeckoStore';
import { useWalletStore } from '../store/WalletStore';

function SendCollectibles() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const watchRef = useRef(null);
    const { nftItem, openSetting, item } = location.state;
    const { marketData } = useGeckoStore(['marketData']);
    const { currentWallet } = useWalletStore(['currentWallet']);
    const [open, setOpen] = useState(openSetting);
    const [loading, setLoading] = useState(false);
    const [isTransactionFailModal, setIsTransactionFailModal] = useState(false);
    const onCloseGuide_TransactionFailModal = () => setIsTransactionFailModal(false);
    const [transactionInProgressModal, setTransactionInProgressModal] = useState(false);
    const [sendFailCode, setSendFailCode] = useState();

    const getCurrentPrice = useCallback((flag) => {
        if (marketData) {
            const marketItem = marketData.find(marketItem => marketItem.symbol === flag.toLowerCase());
            if (marketItem) return marketItem?.current_price;
            else return marketItem;
        }
    }, [])
    const closeSheet = () => { setOpen(false); };
    const { sendingToAddress, sendingContractAddress, sendingAmount, sendingMaxPriorityFeePerGas, sendingMaxFeePerGas, sendingSpeed, sendingGaspriceBsc, setSendingToAddress, setSendingContractAddress, setSendingMaxPriorityFeePerGas, setSendingMaxFeePerGas, setSendingGaspriceBsc,
    } = useSendingInfoStore([ 'sendingToAddress', 'sendingContractAddress', 'sendingAmount', 'sendingMaxPriorityFeePerGas', 'sendingMaxFeePerGas', 'sendingSpeed', 'sendingGaspriceBsc', 'setSendingToAddress', 'setSendingContractAddress', 'setSendingMaxPriorityFeePerGas', 'setSendingMaxFeePerGas', 'setSendingGaspriceBsc',
    ]);
    const getFeeInfo = async () => {
        try {
            await getFeeData(item.endPoint).then((res, err) => {
                if (sendingGaspriceBsc == undefined || sendingGaspriceBsc == 0) setSendingGaspriceBsc(parseInt(res?.gasPrice) * speedSetter[1].gaspriceRatio);
                if (sendingMaxPriorityFeePerGas == undefined || sendingMaxPriorityFeePerGas == 0)   setSendingMaxPriorityFeePerGas(parseUnits('1.5', 'gwei'));
                if (sendingMaxFeePerGas == undefined || sendingMaxFeePerGas == 0)   setSendingMaxFeePerGas(res?.maxFeePerGas + parseUnits('1.5', 'gwei'));
            })
        } catch (error) {
            console.error('Error fetching coin data:', error);
        }
    }
    const onLoad = async () => {
        await getFeeInfo();
        getCurrentPrice(item.alias);
        setSendingContractAddress(item.contractAddress);
    }
    useEffect(() => {
        if (isApp()) {
            onLoad();
            if (item.groupName === 'ETHEREUM' || item.groupName === 'Seporila(Testnet)')    item.groupName === 'ETHEREUM' ? item.symbol = "ETH" : item.symbol = "SeporliaETH";
            else    item.groupName === 'BINANCE(BSC)' ? item.symbol = "BNB" : item.symbol = "tBNB";
        }
    }, []);
    const validateHexOnly = (input) => { // 정규표현식을 사용하여 입력된 값이 16진수 문자(a-f, A-F, 0-9)만 포함하는지 확인합니다.
        const hexRegex = /^0x[a-zA-Z0-9]+$/;
        return input.length === 42 && hexRegex.test(input);
    }
    const isRetrieved = useMemo(() => {
        if ( sendingToAddress.length === 0) return null;
        else if ( validateHexOnly(sendingToAddress) ) return true;
        else return false;
    }, [sendingToAddress]);
    const goSetSpeed = () => { navigate('/set_speed', { state: { item, nftItem} }) }
    const confirmBtn = async () => {
        const result = await get('getLocalAuth');
        if (isBoolean(result)) {
            if (result) {
                setLoading(true);
                let pk = '';
                const walletList = await getItem('wallets');
                if (walletList !== null) {
                    const wallets = JSON.parse(walletList);
                    wallets.map((wallet) => { if (wallet.accounts[0].isSelected) pk = wallet.accounts[0].privateKey; })
                }
                try {
                    const sendNft721Result = await sendNft721(pk, item, nftItem, currentWallet.publicKey, sendingToAddress, sendingContractAddress, sendingMaxPriorityFeePerGas, sendingMaxFeePerGas, sendingGaspriceBsc);
                    // console.dir({ "sendNft721Result": sendNft721Result })
                    setTransactionInProgressModal(true)

                } catch (error) {
                    console.dir({"error":error})
                    setSendFailCode(error.code);
                    setIsTransactionFailModal(true);
                } finally {
                    setSendingToAddress("");
                    setSendingContractAddress("");
                    setOpen(false);
                    setLoading(false);
                }
            }
        }
    }
    return (
        <div className={`flex-1 flex flex-col px-[24px] overflow-hidden`}>
            <div className="mx-auto w-full max-w-[640px] flex-1 flex flex-col overflow-y-auto text-white">
                <div className='flex flex-row h-[72px] text-[#FFFFFF] pt-[7px] overflow-x-auto justify-start'>
                    {
                        nftItem?.raw?.metadata?.image !== undefined ?
                            <div className='w-[64px] h-[64px]'>
                                <img src={nftItem?.raw?.metadata?.image.startsWith("ipfs://") ? convertIpfsUrl(nftItem?.raw?.metadata?.image) : nftItem?.raw?.metadata?.image} />
                            </div> :
                            <div className='truncate bg-lavender-gray w-[64px] h-[64px] rounded-[50%] text-medium-gray flex justify-center items-center text-[10px]'>{nftItem?.name}</div>
                    }
                    <div className='w-[16px]' />
                    <div>
                        <div className='text-[#FFFFFF] text-[20px] font-bold'>{nftItem?.contract.name}</div>
                        <div className='text-[#CCCBDB] text-[14px] font-normal'># {nftItem?.tokenId}</div>
                    </div>
                </div>
                <div className='h-[32px]' />
                <div className='flex-1'>
                    <div className='flex flex-row h-[24px] text-[#626070] pt-[7px] overflow-x-auto justify-start'>
                        <div className='text-[#CCCBDB] text-[14px] pr-[4px]'>{t('140')}</div>
                    </div>
                    <div className='h-[8px]' />
                    <div className='flex flex-row items-center h-[56px] py-[16px] px-[16px] border-[1px] border-[#343140] rounded-[4px]'>
                        <textarea className='flex-1 h-[24px] bg-transparent focus:outline-none text-white resize-none'
                            value={sendingToAddress}
                            placeholder={t('176')}
                            onChange={(e) => {
                                setSendingToAddress(e.target.value)
                                if (watchRef && watchRef.current) {
                                    watchRef.current.style.height = "0px";
                                    const scrollHeight = watchRef.current.scrollHeight;
                                    watchRef.current.style.height = scrollHeight + "px";
                                }
                            }} />
                        <div className='w-[10px]' />
                        <div className='w-[24px] h-[24px] cursor-pointer' onClick={() => onQrScanner({ setState: setSendingToAddress })}>
                            <img src='/assets/icon/scan.png' />
                        </div>
                    </div>
                    <div className='h-[8px]' />
                    <div className='flex flex-row justify-between '>
                        {
                            isRetrieved == null ? <div /> : 
                                isRetrieved === true ? <div className='text-[#3687FF]'>{t('79')}</div> : <div className='text-[#FF5858]'>{t('78')}</div>
                        }
                        <div className='text-[#FFD771] cursor-pointer' onClick={() => onPaste({ setState: setSendingToAddress })}>{t('72')}</div>
                    </div>
                    <div className='h-[50%]' />
                    <div className='flex justify-center justify-around'>
                        {
                            isRetrieved ?
                                <div className='text-dark-purple bg-primary-yellow flex-1 px-[36px] py-[18.5px] rounded-[4px] text-center' onClick={() => setOpen(true)}>{t('144')}</div> :
                                <div className='text-dark-purple bg-primary-yellow flex-1 px-[36px] py-[18.5px] rounded-[4px] text-center opacity-50'>{t('144')}</div>
                        }
                    </div>
                    <div className='h-[40px]' />
                    <FailToBroadcastingTransactionModal isOpen={isTransactionFailModal} setIsOpen={setIsTransactionFailModal} title={t('177')} fn={onCloseGuide_TransactionFailModal} code={sendFailCode} />
                    <TransactionInProgressModal isOpen={transactionInProgressModal} setIsOpen={setTransactionInProgressModal} title={t('119')} detailInfo={item} />
                    <BottomSheet open={open}
                        onDismiss={closeSheet}
                        header={false}>
                        <div className='px-[24px] pt-[32px] bg-[#22212D] text-[#ffffff] rounded-t-[16px]'>
                            <div className='pb-[24px] text-[#626070]'>{t('125')}</div>
                            <div className='pb-[24px] w-[a] flex flex-row'>
                                <div className='text-[#626070] w-[64px]'>{t('127')}</div>
                                <div className='w-[16px]' />
                                <div className='flex flex-row justify-between'>
                                    <div onClick={closeSheet}>
                                        <div>{sendingSpeed !== undefined ? sendingSpeed : 'Average'}</div>
                                        <div>
                                            <div className='flex flex-row justify-between'>
                                                {'< '} {(parseFloat(formatEther(sendingMaxFeePerGas))).toFixed(4)} {item?.symbol} ($ {getCurrentPrice(item?.alias) === undefined ? '0.00' : (parseFloat(formatEther(sendingMaxFeePerGas)) * getCurrentPrice(item?.alias)).toFixed(9)})
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-[24px]'></div>
                                    <div className='text-[#FFCC48]' onClick={goSetSpeed}>{t('138')}</div>
                                </div>
                            </div>
                            <div className='pb-[24px] flex flex-row' onClick={closeSheet}>
                                <div className='text-[#626070] w-[64px]'>{t('128')}</div>
                                <div className='w-[16px]' />
                                <div className='break-all text-[#ffffff] pl-[16px] pr-[24px]'>{sendingToAddress}</div>
                            </div>
                            <div className='pb-[24px] flex flex-row' onClick={closeSheet}>
                            <div className='text-[#626070] w-[64px]'>{t('109')}</div>
                                <div className='w-[16px]' />
                                {nftItem.contract.name} #{nftItem.tokenId}
                            </div>
                            <div className='p-[32px] flex flex-row justify-center'>
                                {
                                    loading ? <ClipLoader
                                        size={40}
                                        color={"#FFCC48"}
                                        loading={loading}
                                        speedMultiplier={0.8}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    /> :
                                        <div className='text-dark-purple bg-primary-yellow flex-1 px-[36px] py-[18.5px] rounded-[4px] text-center' onClick={confirmBtn}>{t('139')}</div>
                                }
                            </div>
                        </div>
                    </BottomSheet>
                </div>
            </div>
        </div>
    )
}
export default SendCollectibles;

export const IPFS_URL = 'https://ipfs.io/ipfs/';
export const COLLECTIBLE_ALCHEMY_MAIN_ETH_API_URL = `https://eth-mainnet.g.alchemy.com/nft/v3/${process.env.REACT_APP_ALCHEMY_API_URL}/`;
export const COLLECTIBLE_ALCHEMY_TEST_ETH_API_URL = `https://eth-sepolia.g.alchemy.com/nft/v3/${process.env.REACT_APP_ALCHEMY_API_URL}/`;
export const COLLECTIBLE_ALCHEMY_MAIN_BSC_API_URL = `https://bnb-mainnet.g.alchemy.com/nft/v3/${process.env.REACT_APP_ALCHEMY_API_URL}/`;
export const COLLECTIBLE_ALCHEMY_TEST_BSC_API_URL = `https://bnb-testnet.g.alchemy.com/nft/v3/${process.env.REACT_APP_ALCHEMY_API_URL}/`;

export const MAX_SEED_WORD_COUNT = 12;
export const WALLETS = 'wallets';
export const NETWORKS = 'networks';
export const LANGUAGE = 'language';
export const CREATE = 'create';
export const SEED = 'seed';
export const PRIVATE = 'private';
export const WATCH = 'watch';
export const LOCAL_AUTH = 'getLocalAuth';

export const MARKET_API = '/markets';

export const LOGIN_PAGE = '/login';
export const MAIN_PAGE = '/';
export const WALLETS_PAGE = '/wallets';
export const ACTIVITY_PAGE = '/activity';
export const BROWSER_PAGE = '/browser';
export const SETTING_PAGE = '/setting';
export const IMPORT_WALLET_SEED_PAGE = '/import_wallet/seed';
export const IMPORT_WALLET_PRIVATE_PAGE = '/import_wallet/private';
export const IMPORT_WALLET_WATCH_PAGE = '/import_wallet/watch';

export const MY_WALLET_PAGE = '/my_wallet_address';
export const NAME_THIS_WALLET_PAGE = '/name_this_wallet';
export const BACKUP_WALLET_STEP01_PAGE = '/backup_wallet/step01';
export const BACKUP_WALLET_STEP02_PAGE = '/backup_wallet/step02';
export const BACKUP_WALLET_STEP03_PAGE = '/backup_wallet/step03';
export const BACKUP_WALLET_STEP04_PAGE = '/backup_wallet/step04';
export const SHOW_SEED_PHARSE_PAGE = '/show_seed_pharse';
export const SHOW_SEED_PHARSE_BY_ADDRESS_PAGE = '/show_seed_pharse_address';
export const MANAGE_WALLET_PAGE = '/manage_wallet';
export const CHANGE_LANGUAGE_PAGE = '/change_language';
export const ADD_HIDE_TOKENS_PAGE = '/add_hide_tokens';
export const ADD_CUSTOM_TOKEN_PAGE = '/add_custom_token';
export const SELECT_ACTIVE_NETWORKS_PAGE = '/select_active_networks';
export const ASSET_DETAIL_PAGE = '/asset_detail';
export const COLLECTIBLE_DETAIL_PAGE = '/collectible_detail';
export const COLLECTIBLE_METADATA_PAGE = '/collectible_metadata';
export const SEND_ASSETS_PAGE = '/send_assets';
export const SET_SPEED_PAGE = '/set_speed';
export const ACTIVITY_DETAIL_PAGE = '/activity_detail';
export const TRANSACTION_DETAIL_PAGE = '/transaction_detail';
export const SEND_COLLECTIBLES_PAGE = '/send_collectibles';
export const CHANGE_CURRENCY_PAGE = '/change_currency'

export const MAIN_TAB_LIST = [{
  "selected": true
}, {
  "selected": false
}, {
  "selected": false
}]
export const BOTTOM_MENU_LIST = [{
  src: "wallet",
  selected: true,
  to: MAIN_PAGE
}, {
  src: "activity",
  selected: false,
  to: ACTIVITY_PAGE
}, {
  src: "browser",
  selected: false,
  to: BROWSER_PAGE
}, {
  src: "setting",
  selected: false,
  to: SETTING_PAGE
}];

// 세팅 메뉴 목록
export const SETTING_MENU_LIST = [{
  title: 'WALLET',
  list: [{
    src: 'show_my_wallet_address',
    title: 'Show My Wallet Address',
    url: MY_WALLET_PAGE
  }, {
    src: 'change_add_wallet',
    title: 'Change / Add wallet',
    isWallet: true,
    url: WALLETS_PAGE
  }, {
    src: 'name_this_wallet',
    title: 'Name this Wallet',
    url: NAME_THIS_WALLET_PAGE
  }, {
    src: 'backup_this_wallet',
    title: 'Backup this wallet',
    // changeTitle : 'Upgrade Key security',
    url: BACKUP_WALLET_STEP01_PAGE,
    isHidden: true
  }, {
    src: 'show_seed_pharse',
    title: 'Show Seed Pharse',
    requireBackup: true,
    url: SHOW_SEED_PHARSE_PAGE,
    isHidden: true
  }]
}, {
  title: 'SYSTEM',
  list: [{
    src: 'select_active_networks',
    title: 'Select Active Networks',
    url: SELECT_ACTIVE_NETWORKS_PAGE
  }, {
    src: 'change_language',
    title: 'Change Language',
    url: CHANGE_LANGUAGE_PAGE,
    isLanguage: true,
  },{
    src : 'change_currency',
    title : 'Change Currecny',
    url : CHANGE_CURRENCY_PAGE,
    isCurrency : true
  }]
}, {
  title: 'HELP',
  list: [{
    src: 'support',
    title: 'Support',
    url: 'https://triplegoldglobal.org'
  }]
}]

// Asset 상세 페이지 탭 목록
export const ASSET_DETAIL_TAB_LIST = [{
  key: '91',
  display: 'Info',
  value: 'info',
  active: true,
}, {
  key: '92',
  display: 'Activity',
  value: 'activity',
  active: false,
}];
// NFT상세 페이지 탭 목록
export const COLLECTIBLE_DETAIL_TAB_LIST = [{
  key: '11',
  display: 'Collectibles',
  value: 'collectibles',
  active: true,
}, {
  key: '92',
  display: 'Activity',
  value: 'activity',
  active: false,
}];

export const speedSetter = [
  {
    level: "Fast",
    maxPriorityFeePerGas: '2', // parseUnits('2', 'gwei'),
    timeRange: "~1",
    gaspriceRatio: 1.5
  },
  {
    level: "Average",
    maxPriorityFeePerGas: '1.5', // parseUnits('1.5', 'gwei'),
    timeRange: "~3",
    gaspriceRatio: 1.2
  },
  {
    level: "Slow",
    maxPriorityFeePerGas: '1', // parseUnits('1', 'gwei'),
    timeRange: "~10",
    gaspriceRatio: 1
  }
]
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useImportStore } from '../../store/ImportStore';
import { wordlists } from 'bip39';
import { useTranslation } from 'react-i18next';
import { MAX_SEED_WORD_COUNT } from '../../global/Constant';

function ImportWalletBySeed() {
    const { importText,setImportText,setIsValid,
            wordCount, setWordCount} = useImportStore(['importText','setImportText','setIsValid','wordCount', 'setWordCount']);
    const [recommandWords, setRecommandWords] = useState([]);
    const importRef = useRef();
    const { t } = useTranslation();

    // 단어 수 체크하는 함수
    const onCountWord = (text) => {
        setWordCount(text.trim().length > 0 ? text.trim().split(/\s+/).length : 0);
        return text.trim().length > 0 ? text.trim().split(/\s+/).length : 0;
    };

    // textarea onchange
    const onChangeSeedText = (value) => {
        // 1. 오직 영어(소문자)만 입력가능하다
        let lowercaseAndSingleSpaceOnly = value
        .toLowerCase() // 입력값을 소문자로 변환
        .replace(/\s+/g, ' ') // 연속된 공백을 하나의 공백으로 치환
        const lastWord = lowercaseAndSingleSpaceOnly.split(' ').pop(); // 마지막 단어 추출
        onRecommandWords(lastWord);
        // 최대 12단어까지만 입력 가능하도록 조건 추가
        if (onCountWord(lowercaseAndSingleSpaceOnly) <= MAX_SEED_WORD_COUNT) {
            if(onCountWord(lowercaseAndSingleSpaceOnly) === MAX_SEED_WORD_COUNT) {
                lowercaseAndSingleSpaceOnly = lowercaseAndSingleSpaceOnly.replace(/^\s+|\s+$/g, '');
            }
            setImportText(lowercaseAndSingleSpaceOnly);
        }
    }

    // 추천단어 선택
    const onSelectRecommandWord = (word) => {
        const lastSpaceIndex = importText.lastIndexOf(' ');
        let newText =
        lastSpaceIndex === -1
          ? word + ' ' // 단어가 하나일 경우
          : importText.substring(0, lastSpaceIndex + 1) + word + ' '; // 이미 여러 단어가 있을 경우
        if (onCountWord(newText) <= MAX_SEED_WORD_COUNT) {
            if(onCountWord(newText) === MAX_SEED_WORD_COUNT) {
                newText = newText.replace(/^\s+|\s+$/g, '');
            }
            setImportText(newText);
        }
        setRecommandWords([]);
        importRef.current.focus();
        importRef.current.setSelectionRange(newText.length, newText.length);
    }

    // 추천단어 목록 출력
    const onRecommandWords = (text) => {
        if(text.trim().length > 0){
            const filterWords = wordlists.english.filter((word) => word.startsWith(text));
            setRecommandWords(filterWords);
        }
        else {
            setRecommandWords([]);
        }
    }


    useEffect(() => {
        setImportText('')
        setWordCount(0)
        setIsValid(true)
    },[])
 
    
    return (
        <>
            <div className='flex flex-col px-[24px] gap-[8px]'>
                <div className='text-[16px] text-lavender-gray'>{t('61')}</div>
                <div className='rounded-[4px] py-[16px] px-[24px] border border-dark-gray-purple h-[111px]'>
                    <textarea ref={importRef}  onChange={(e) => onChangeSeedText(e.target.value)} value={importText} className='resize-none box-border w-full h-full bg-transparent text-white outline-none font-inter leading-[21px]'></textarea>
                </div>
                <div className={`text-[16px] ${wordCount === MAX_SEED_WORD_COUNT ? 'text-success' : 'text-light-gray-purple'} text-right`}>
                    {wordCount}/{MAX_SEED_WORD_COUNT}
                </div>
            </div>
            {/* Recommand word */}
            {
                recommandWords.length > 0 &&
                <div className='fixed bottom-0 left-0 w-full flex flex-row gap-[16px] overflow-y-auto bg-dark-purple z-[1]'>
                    {
                        recommandWords.map((word,index) => <div key={index} className='p-[16px] rounded-[4px] bg-dark-navy text-white flex-shrink-0 flex-grow-0'
                                                            onClick={() => onSelectRecommandWord(word)}>
                            {word}
                        </div>)
                    }
                </div>
            }
            
        </>
    );
}

export default ImportWalletBySeed;
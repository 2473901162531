import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CREATE, IMPORT_WALLET_SEED_PAGE, IMPORT_WALLET_WATCH_PAGE, MAIN_PAGE, MARKET_API, WALLETS } from '../global/Constant';
import { useTranslation } from 'react-i18next';
import { generateWallet, get, getItem, isApp, setChangeLanguage } from '../utils/CommonUtil';
import { useGeckoStore } from '../store/GeckoStore';
import { axiosGecko } from '../lib/axios';
import { useDefaultStore } from '../store/DefaultStore';

function Login() {
    const navigate = useNavigate();
    const {t,i18n} = useTranslation();
    const {marketData,setMarketData,updateTime,setUpdateTime} 
    = useGeckoStore(['marketData','setMarketData','updateTime','setUpdateTime']);
    const {setCurrency} = useDefaultStore(['setCurrency']);
    const getMarketData = async(moneyUnit) => {
        // 게코api 마켓데이터 조회
        const params = {
          'vs_currency' : moneyUnit,
          // 'ids' : 'ethereum,tether,usd-coin,binancecoin,ssv-network',
          'ids' : 'staked-ether,wrapped-eeth,pepe,ethena-usde,polygon,leo-token,dai,uniswap,chainlink,wrapped-bitcoin,shiba-inu,ethereum,tether,usd-coin,binancecoin,ssv-network',
          'per_page' : 16,
          'page' : 1,
          'sparkline' : false,
          'price_change_percentage' : '24h'
        };
        const timestamp = new Date().getTime();
        if(marketData === null){
          try{
            const marketResponse = await axiosGecko(MARKET_API,{params});    
            setMarketData(marketResponse.data);
            setUpdateTime(timestamp);
          }
          catch(ex) {
            console.log("Err::",ex)
          }
        }
        // 1분이 지난상태면 갱신 가능
        else {
            const timeDifference = Math.abs(timestamp - updateTime);
            // 1분(60,000 밀리초) 이상 차이나는지 확인
            if (timeDifference >= 60000) {
                // 여기에 실행할 코드 작성
                try{
                const marketResponse = await axiosGecko(MARKET_API,{params});    
                setMarketData(marketResponse.data);
                setUpdateTime(timestamp);
                }
                catch(ex){
                  console.log("Err::",ex);
                }
            }
        }
    }

    // 지갑 생성
    const onCreateWallet = useCallback( async () => {
        if(isApp()) {
            await generateWallet(CREATE);
            navigate(MAIN_PAGE,{replace : true});
        }
    },[])

    // 페이지 이동
    const onPage = useCallback((url) => {
        if(isApp()) {
            navigate(url);
        }
    },[navigate])
    
    const onLoad = useCallback(async () => {
        if(isApp()){
            const wallets = await getItem(WALLETS);
            // 지갑이 존재하면 메인으로 보낸다
            if(wallets !== null){
                navigate(MAIN_PAGE);
                return;
            }
            await setChangeLanguage(i18n);
            await get('removeSplash')
            // 통화 화폐정보
            let moneyUnit = await getItem('currency');
            if (moneyUnit === null) moneyUnit = 'USD';
            setCurrency(moneyUnit);
            await getMarketData(moneyUnit)
        }
    },[])
    // onLoad
    useEffect(() => {
        const controller = new AbortController();
        onLoad();
        return () => {
            controller.abort();
        };
    },[])
    return (
        <div className='bg-dark-purple h-screen flex flex-col w-full max-w-[40rem] mx-auto'>
            <div className="flex min-h-full flex-col justify-center">
                <div className="mx-auto w-full max-w-[40rem] h-[40rem] flex flex-col justify-center items-center gap-[9.75rem]">
                    <div className='flex flex-col gap-[0.75rem]'>
                        <img src="/assets/home/logo.png"  alt="titan logo" />
                    </div>
                    <div className='flex flex-col gap-[2rem]'>
                        <div className='bg-primary-yellow px-[54.5px] py-[18.5px] rounded-[4px]
                                        text-[16px] text-dark-purple text-center' onClick={onCreateWallet}>
                        {t('1')}
                        </div>
                        <div className='flex flex-col gap-[0.5rem] items-center'>
                            <div className='text-lavender-gray'>{t('2')}</div>
                            <div className='flex flex-row text-white gap-[0.5rem]'>
                                <div className='bg-dark-gray-purple px-[35px] py-[14.5px] rounded-[4px]
                                                text-[16px] text-center' onClick={() => onPage(IMPORT_WALLET_WATCH_PAGE)}>
                                    {t('3')}
                                </div>
                                <div className='bg-dark-gray-purple px-[35px] py-[14.5px] rounded-[4px]
                                                text-[16px] text-center' onClick={() => onPage(IMPORT_WALLET_SEED_PAGE)}>
                                    {t('4')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { COLLECTIBLE_ALCHEMY_MAIN_BSC_API_URL, COLLECTIBLE_ALCHEMY_MAIN_ETH_API_URL, COLLECTIBLE_ALCHEMY_TEST_BSC_API_URL, COLLECTIBLE_ALCHEMY_TEST_ETH_API_URL, COLLECTIBLE_METADATA_PAGE } from '../global/Constant';
import { useDefaultStore } from '../store/DefaultStore';
import axios from '../lib/axios';
import { useWalletStore } from '../store/WalletStore';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ClipLoader } from 'react-spinners';
import { isApp, setItem } from '../utils/CommonUtil';

function CollectibleDetailInfo({ item }) {
    const location = useLocation();
    const navigate = useNavigate();

    const { setHeaderTitle } = useDefaultStore(['setHeaderTitle']);
    const { currentWallet } = useWalletStore(['currentWallet']);
    const [ collectibleInfo, setCollectibleInfo ] = useState([]);
    const [ pageKey, setPageKey ] = useState(0);

    const goCollectibleMetadataInfo = (selectedCollectible) => {
        setHeaderTitle(selectedCollectible.name);
        navigate(COLLECTIBLE_METADATA_PAGE, { state: { selectedCollectible, item } });
    }

    const fetchNextCollectible = async () => {
        if (pageKey !== null) {

            let collectibleUrl;
            if ( item.groupName ==='ETHEREUM' || item.groupName === 'Seporila(Testnet)' ) {
                if ( item?.isMain ) collectibleUrl = `${COLLECTIBLE_ALCHEMY_MAIN_ETH_API_URL}getNFTsForOwner?owner=${currentWallet.publicKey}&contractAddresses[]=${item.contractAddress}&withMetadata=true&pageSize=100&pageKey=${pageKey}`;
                else collectibleUrl = `${COLLECTIBLE_ALCHEMY_TEST_ETH_API_URL}getNFTsForOwner?owner=${currentWallet.publicKey}&contractAddresses[]=${item.contractAddress}&withMetadata=true&pageSize=100&pageKey=${pageKey}`;
            } else {
                if ( item?.isMain ) collectibleUrl = `${COLLECTIBLE_ALCHEMY_MAIN_BSC_API_URL}getNFTsForOwner?owner=${currentWallet.publicKey}&contractAddresses[]=${item.contractAddress}&withMetadata=true&pageSize=100&pageKey=${pageKey}`;
                else collectibleUrl = `${COLLECTIBLE_ALCHEMY_TEST_BSC_API_URL}getNFTsForOwner?owner=${currentWallet.publicKey}&contractAddresses[]=${item.contractAddress}&withMetadata=true&pageSize=100&pageKey=${pageKey}`;
            }

            setTimeout(async () => {
                await axios.get(collectibleUrl)
                    .then(async (res, err) => {
                        setCollectibleInfo([...collectibleInfo, ...res.data.ownedNfts])
                        setPageKey(res.data.pageKey)
                    });
            }, 500);
        }
    }

    const onLoad = async () => {
        let collectibleUrl;
        if ( item.groupName ==='ETHEREUM' || item.groupName === 'Seporila(Testnet)' ) {
            if ( item?.isMain ) collectibleUrl = `${COLLECTIBLE_ALCHEMY_MAIN_ETH_API_URL}getNFTsForOwner?owner=${currentWallet.publicKey}&contractAddresses[]=${item.contractAddress}&withMetadata=true&pageSize=100`;
            else collectibleUrl = `${COLLECTIBLE_ALCHEMY_TEST_ETH_API_URL}getNFTsForOwner?owner=${currentWallet.publicKey}&contractAddresses[]=${item.contractAddress}&withMetadata=true&pageSize=100`;
        } else {
            if ( item?.isMain ) collectibleUrl = `${COLLECTIBLE_ALCHEMY_MAIN_BSC_API_URL}getNFTsForOwner?owner=${currentWallet.publicKey}&contractAddresses[]=${item.contractAddress}&withMetadata=true&pageSize=100`;
            else collectibleUrl = `${COLLECTIBLE_ALCHEMY_TEST_BSC_API_URL}getNFTsForOwner?owner=${currentWallet.publicKey}&contractAddresses[]=${item.contractAddress}&withMetadata=true&pageSize=100`;
        }


        await axios.get(collectibleUrl)
            .then(async (res, err) => {
                setCollectibleInfo([...collectibleInfo, ...res.data.ownedNfts])
                setPageKey(res.data.pageKey)
            });
    }

    useEffect(() => {
        onLoad();
    }, [])

    return (
        <div className='flex-1 flex flex-col gap-[24px] overflow-y-auto' id="scrollableDiv">
            <InfiniteScroll
                dataLength={collectibleInfo.length}
                next={fetchNextCollectible}
                hasMore={pageKey !== null}
                loader={<div className='flex justify-center'>
                <ClipLoader
                    size={40}
                    color={"#FFCC48"}
                    loading={true}
                    speedMultiplier={0.8}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                </div>}
                scrollableTarget="scrollableDiv"
            >
                {
                    (collectibleInfo && collectibleInfo.length > 0) && collectibleInfo.map((collectibleObj, index) => {
                        return (
                            <div key={index} className='flex flex-row w-[312px] h-[96px] m-[5px]' onClick={() => goCollectibleMetadataInfo(collectibleObj)}>
                                {
                                    collectibleObj?.image?.pngUrl !== null ?
                                        <div className='w-[96px] h-[96px]'>
                                            <img src={collectibleObj?.image?.pngUrl} alt='' />
                                        </div> :
                                        <div className='truncate bg-lavender-gray w-[96px] h-[96px] text-medium-gray flex justify-center items-center text-[20px]'>
                                            {collectibleObj.name}
                                        </div>
                                }
                                <div className='w-[16px]' />
                                <div className='flex-1 '>
                                    <div className='text-[14px] text-[#CCCBDB]'>
                                        <div className='text-[16px] text-[#FFFFFF]'>
                                            {collectibleObj?.name}
                                        </div>
                                        # {collectibleObj?.tokenId}
                                    </div>
                                </div>
                            </div>

                        )
                    })
                }
            </InfiniteScroll>
        </div>
    );
}

export default CollectibleDetailInfo;
import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ImportWalletTab from '../components/import_wallet/ImportWalletTab';
import ImportWalletBySeed from '../components/import_wallet/ImportWalletBySeed';
import ImportWalletByPrivate from '../components/import_wallet/ImportWalletByPrivate';
import ImportWalletByWatch from '../components/import_wallet/ImportWalletByWatch';
import ErrorImportWalletModal from '../components/modal/ErrorImportWalletModal';
import { useImportStore } from '../store/ImportStore';
import { ethers } from 'ethers';
import { get, getItem, isApp, isBoolean, onlyNumberAndAlpha, setItem, success } from '../utils/CommonUtil';
import { useTranslation } from 'react-i18next';
import { useSettingStore } from '../store/SettingStore';
import { MAX_SEED_WORD_COUNT, PRIVATE, SEED, WALLETS, WALLETS_PAGE, WATCH } from '../global/Constant';
import Header from '../components/Header';

function ImportWallet() {
    const { importType } = useParams();
    const { t } = useTranslation();

    const { importText,wordCount,isValid} = useImportStore(['importText','wordCount','isValid']);
    const { setSettingOpen } = useSettingStore(['setSettingOpen']);

    const [invalidTitle , setInvalidTitle] = useState('');
    const [isErrorModal , setIsErrorModal] = useState(false); 
    const navigate = useNavigate();
    
    const onImportWallet = useCallback(async () => {
        // 앱인지 + 버튼활성화  체크
        if(isApp() && isValid){
            
            if((importType === SEED && (MAX_SEED_WORD_COUNT !== wordCount || importText.length === 0))
            || (importType === PRIVATE && (!onlyNumberAndAlpha(importText) || importText.length < 64))
            || (importType === WATCH && (!onlyNumberAndAlpha(importText) || importText.length === 0))){
                    return;
            }

            // validation
            let isValidation = false;
            let publicKey =  null;
            let privateKey =  null;
            let mnemonic = null;
            let walletInfo;
            
            // SEED + 갯수체크
            if(importType === SEED && MAX_SEED_WORD_COUNT === wordCount) {
                isValidation = ethers.Mnemonic.isValidMnemonic(importText);
                if(isValidation) {
                    walletInfo = ethers.Wallet.fromPhrase(importText);
                    mnemonic = walletInfo.mnemonic.phrase;
                    publicKey = walletInfo.address;
                    privateKey = walletInfo.privateKey;
                }
            }
            // WATCH ONLY
            else if(importType === WATCH) {
                isValidation = ethers.isAddress(importText);
                if(isValidation){
                    publicKey = importText
                }
            }
            // PRIVATE
            else if(importType === PRIVATE) {
                try{
                    walletInfo = new ethers.Wallet(`0x${importText}`);
                    publicKey = walletInfo.address;
                    privateKey = walletInfo.privateKey;
                    isValidation = true;
                } catch(err) {
                   console.log(`PRIVATE VALIDATION ERROR : ${err}`);
                }
            }

            // VALDATION - false = alert modal
            if(!isValidation){
                setInvalidTitle(t('50'));
                setIsErrorModal(true);     
                return;
            }

            // 로컬 인증
            const result = await get('getLocalAuth');
            if(isBoolean(result)) {
                if(!result){
                    return;
                }
                // 지갑목록 가져오기
                const walletList = await getItem(WALLETS);
                let wallets = [];
                
                // 지갑이 존재하면
                if(walletList !== null) {
                    wallets = JSON.parse(walletList);
                    // 등록할 지갑주소가 존재하느지
                    const isAlreadyInsert = wallets.find(wallet => wallet.accounts.some(account => account.publicKey === publicKey));

                    // 이미 존재하는 지갑주소
                    if(isAlreadyInsert !== undefined) {
                        setInvalidTitle(t('65'));
                        setIsErrorModal(true);     
                        return;
                    }
                    
                    // 기존 지갑주소들 isSelcted : false 
                    wallets = wallets.map((wallet) => {
                        const updateAccount = wallet.accounts.map((account) => {
                            return {
                                ...account, isSelected : false
                            }    
                        })
                        return {
                            ...wallet , accounts : updateAccount
                        }
                    })
                }

                let isBackup = importType === SEED ? false : true;
                // 신규 지갑 정보
                const wallet = {
                    isBackup,
                    seq : wallets.length === 0 ? 1 : (wallets[wallets.length-1].seq + 1),
                    mnemonic: mnemonic,
                    accounts : [{
                        index : 0,
                        isSelected: true,
                        publicKey: publicKey,
                        privateKey: privateKey,
                        nickName: `Wallet${wallets.length === 0 ? 1 : (wallets[wallets.length-1].seq + 1)}`,
                        isSeed : importType === SEED,
                        isPrivateKey : importType === PRIVATE,
                        isWatch : importType === WATCH
                    }]
                }
                // 추가
                wallets.push(wallet);
                // 저장
                await setItem(WALLETS,JSON.stringify(wallets));
                // 완료
                await success();
                navigate(WALLETS_PAGE,{replace : true});
            }
            else {
                // 모달창을 띄운다
                setSettingOpen(true); 
            } 
        }
    },[importText, importType, isValid, navigate, setSettingOpen, t, wordCount])

    return (
        <div className='bg-dark-purple h-screen flex flex-col w-full max-w-[40rem] mx-auto'>
            <Header />
            <div className='flex-1 flex flex-col'>
                <div className='flex flex-row overflow-x-auto gap-[48px] pl-[24px]'>
                    <ImportWalletTab title={t('46')} type={'seed'} selected={importType === 'seed'}/>
                    <ImportWalletTab title={t('47')} type={'private'} selected={importType === 'private'}/>
                    <ImportWalletTab title={t('48')} type={'watch'} selected={importType === 'watch'}/>
                </div>
                <div className='pt-[31px] flex-1'>
                    {
                        importType === 'seed' ? <ImportWalletBySeed />
                        : importType === 'private' ? <ImportWalletByPrivate />
                        : <ImportWalletByWatch />
                    }
                </div>
                <div className='flex flex-row justify-center items-center pb-[40px]'>
                    <div className={`bg-primary-yellow rounded-[4px]
                                    text-[16px] text-dark-purple text-center py-[18.5px] px-[72px] 
                                    ${((importType === SEED && (MAX_SEED_WORD_COUNT !== wordCount || importText.length === 0))
                                    || (importType === PRIVATE && (!onlyNumberAndAlpha(importText) || importText.length < 64))
                                    || (importType === WATCH && (!onlyNumberAndAlpha(importText) || importText.length === 0)))
                                    ? 'opacity-50' : '' }`} 
                                    onClick={onImportWallet}>
                        {importType === WATCH ? t('69') : t('49')}
                    </div>
                </div>
                <ErrorImportWalletModal isOpen={isErrorModal} setIsOpen={setIsErrorModal} title={invalidTitle} />
            </div>
        </div>
    );
}

export default ImportWallet;
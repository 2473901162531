import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDefaultStore } from '../store/DefaultStore';
import { useTranslation } from 'react-i18next';
import { SETTING_PAGE } from '../global/Constant';
import { get, getItem, isApp, isBoolean, setItem, success } from '../utils/CommonUtil';
import { useWalletStore } from '../store/WalletStore';

function BackupWallet04() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { setCurrentStep } = useDefaultStore(['setCurrentStep']);
    const { currentWallet, setCurrentWallet }  = useWalletStore(['currentWallet', 'setCurrentWallet']);

    const nextStep = async () => {
        if(isApp()){
            // 얼굴인식
            const result = await get('getLocalAuth');
            if(isBoolean(result)) {
                if(result){
                    const walletList = await getItem('wallets');
                    if(walletList !== null) {
                        const wallets = JSON.parse(walletList);

                        const updateWallets = wallets.map((wallet) => {
                            const isSelected = wallet.accounts.findIndex((account) => account.isSelected) > -1;
                            return {...wallet, isBackup : isSelected}
                        });
                        // 1. device storage 에서 isBackup update
                        await setItem('wallets',JSON.stringify(updateWallets));
                        // 2. isBackup store update
                        const currentUpdateWallet = currentWallet;
                        currentUpdateWallet.isBackup = true;
                        setCurrentWallet(currentUpdateWallet)
                        // 3. 브릿지 모달 toast 출력(V) 
                        await success();
                        // 4. 완료되면 step 초기화
                        setCurrentStep(0);
                        // 5. 페이지 이동
                        navigate(SETTING_PAGE);
                    }
                }
            }
            else {
                // 모달창을 띄운다
                // setSettingOpen(true);  
            }
        }
    }
    const onLoad = () => {
        setCurrentStep(4);
    }
    useEffect(() =>{
        onLoad();
    },[])

    return (
        <div className='flex-1 px-[24px] pt-[16px] pb-[40px] flex flex-col overflow-y-auto'>
            <div className='flex flex-col gap-[24px]'>
                <div className='text-white text-[20px] font-bold' dangerouslySetInnerHTML={{__html : t('40')}} />
                <div className='text-lavender-gray text-[16px]' dangerouslySetInnerHTML={{__html : t('41')}} />
            </div>
            <div className='flex-1' />
            <div className='bg-primary-yellow px-[54.5px] py-[18.5px] rounded-[4px]
                            text-[16px] text-dark-purple text-center' onClick={nextStep}>
                {t('42')}
            </div>
        </div>
    );
}

export default BackupWallet04;
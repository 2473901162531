import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WALLETS_PAGE } from '../global/Constant';
import { set, shortenWalletAddress } from '../utils/CommonUtil';
import ErrorWindowOpenModal from '../components/modal/ErrorWindowOpenModal'
function SettingItem({ src, title, subTitle, url, isWeb }) {
    const navigate = useNavigate();
    const [isErrorModal , setIsErrorModal] = useState(false);
    const [errorTitle , setIsErrorTitle] = useState('');

    const goPage = useCallback(async () => {
        if (url) {
            if(isWeb) {
                const result = await set('windowOpen',url);
                // 에러
                if(result){
                    setIsErrorTitle(result);
                    setIsErrorModal(true);     
                    return;
                }
            }
            else {
                navigate(url);
            }
        }
    },[])
    return (
        <>
            <div className='py-[16px] flex flex-row gap-[16px] items-center cursor-pointer' onClick={goPage}>
                <div className='flex-none w-[24px] h-[24px]'>
                    <img src={`/assets/setting/${src}.png`} alt='' />
                </div>
                <div className='flex flex-col gap-[2px] flex-1 truncate'>
                    <div className='text-[16px] text-white'>
                        {title}
                    </div>
                    {
                        subTitle &&
                        <div className=' text-light-gray-purple truncate'>
                            {url === WALLETS_PAGE ? shortenWalletAddress(subTitle, 12, 15) : subTitle}
                        </div>
                    }
                </div>
            </div>
            {isWeb &&  <ErrorWindowOpenModal isOpen={isErrorModal} setIsOpen={setIsErrorModal} title={errorTitle} />}
        </>
    );
}

export default SettingItem;
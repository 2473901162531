import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { IMPORT_WALLET_PRIVATE_PAGE, IMPORT_WALLET_SEED_PAGE, IMPORT_WALLET_WATCH_PAGE } from '../../global/Constant';

function ImportWalletTab({selected, title,type}) {
    const navigate = useNavigate();
    const onChangeTab = useCallback(() => {
        if(type === 'seed'){
            navigate(IMPORT_WALLET_SEED_PAGE, {replace : true});
        }
        else if(type === 'private'){
            navigate(IMPORT_WALLET_PRIVATE_PAGE, {replace : true});
        }
        else if(type === 'watch'){
            navigate(IMPORT_WALLET_WATCH_PAGE, {replace : true});
        }
    },[])
    return (
        <div className='flex flex-col gap-[4px] items-center flex-shrink-0 cursor-pointer' onClick={onChangeTab}>
            <div className={`w-[3px] h-[3px] rounded-[50%] ${selected ? 'bg-white' : ''}`} />
            <div className={`text-[16px] ${selected ? 'text-white' : 'text-medium-gray'} `}>
            {title}
            </div>
        </div>
    );
}

export default ImportWalletTab;
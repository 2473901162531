import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDefaultStore } from '../store/DefaultStore';
import { useSendingInfoStore } from '../store/SendingInfoStore';
import { getFeeData } from '../utils/WalletUtil';
import { SEND_ASSETS_PAGE, SEND_COLLECTIBLES_PAGE, speedSetter } from '../global/Constant';
import { formatEther, formatUnits, parseUnits } from 'ethers';
import { isApp } from '../utils/CommonUtil';
import { useGeckoStore } from '../store/GeckoStore';

function SetSpeed() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const { item, nftItem } = location.state;
    const { setHeaderTitle } = useDefaultStore(['setHeaderTitle']);
    const { marketData } = useGeckoStore(['marketData']);
    const [clickedRadioBox, setClickedRadioBox] = useState(1);
    const [gasFeeData, setGasFeeData] = useState();
    const [remainSec, setRemainSec] = useState(15);
    const {
        sendingSpeed, setSendingSpeed, setSendingMaxPriorityFeePerGas, setSendingMaxFeePerGas, setSendingGaspriceBsc,
    } = useSendingInfoStore([ 
        'sendingSpeed', 'setSendingSpeed', 'setSendingMaxPriorityFeePerGas', 'setSendingMaxFeePerGas', 'setSendingGaspriceBsc'
    ]);
    const getCurrentPrice = useCallback((flag) => {
        if (marketData) {
            const marketItem = marketData.find(marketItem => marketItem.symbol === flag.toLowerCase());
            if (marketItem) return marketItem?.current_price;
            else return marketItem;
        }
    }, []);
    const clickSetSpeedRadioBox = (_idx) => { setClickedRadioBox(_idx) }
    const getFeeInfo = async () => {
        const result = [];
        try {
            await getFeeData(item.endPoint).then((res, err) => {
                speedSetter.map((speed, idx) => {
                    let gasPrice = 0;
                    if ( item.groupName ==='ETHEREUM' || item.groupName === 'Seporila(Testnet)' ) {
                        item.groupName ==='ETHEREUM' ? item.symbol = "ETH" : item.symbol = "SeporliaETH";
                        gasPrice = parseInt(res?.maxFeePerGas) + parseInt(parseUnits(speed.maxPriorityFeePerGas, 'gwei'));
                        const gasGwei = formatUnits(gasPrice, "gwei");
                        result[idx] = [ gasGwei, gasPrice, formatEther(gasPrice), parseUnits(speed?.maxPriorityFeePerGas, 'gwei'),  res?.maxFeePerGas + parseUnits(speed?.maxPriorityFeePerGas, 'gwei'), parseInt(gasPrice) * speed.gaspriceRatio ];
                    } else {
                        item.groupName ==='BINANCE(BSC)' ? item.symbol = "BNB" : item.symbol = "tBNB";
                        gasPrice = parseInt(res?.gasPrice);
                        const gasGwei = formatUnits(parseInt(gasPrice) * speed.gaspriceRatio, "gwei");
                        result[idx] = [ gasGwei, gasPrice, formatEther(parseInt(gasPrice) * speed.gaspriceRatio), 0, 0, parseInt(gasPrice) * speed.gaspriceRatio ];
                    }
                })
                setGasFeeData(result);
            });
        } catch (error) {
            console.error('Error fetching coin data:', error);
        }
    }
    const clock15s = () => {
        setTimeout(() => {
            const newSec = remainSec - 1;
            if (newSec <= 0) {
                setRemainSec(15)
                // navigate(0)
                getFeeInfo();
                speedSetter.map((speed, idx) => { if (speed.level === sendingSpeed) setClickedRadioBox(idx) })
                return;
            }
            setRemainSec(newSec)
        }, 1000);
    }
    const goSendProducts = () => {
        if (item?.protocol !== 721) navigate(SEND_ASSETS_PAGE, { state: { item, openSetting: true }, replace : true })
        else    navigate(SEND_COLLECTIBLES_PAGE, { state: { item, openSetting: true, nftItem } , replace : true})
        if (isApp())    setHeaderTitle(t('160'));
    }
    useEffect(() => {
        getFeeInfo();
        speedSetter.map((speed, idx) => { if (speed.level === sendingSpeed) setClickedRadioBox(idx) })
    }, []);
    useEffect(() => {
        if (isApp())    setHeaderTitle(t('161'));
        clock15s();
    }, [remainSec]);
    return (
        <div className="pt-[8px] pb-[72px] px-[24px] flex flex-col gap-[16px]">
            <div>
                {
                    speedSetter.map((speed, idx) => {
                        return (
                            <div key={idx} className="py-[16px] flex flex-col">
                                <div className='flex flex-row items-center'>
                                    <div className='flex flex-col flex-1'>
                                        <div className='text-[16px] text-white'>{ speed.level === "Fast" ? t('131') : ( speed.level === "Average" ? t('132') : t('133') ) }</div>
                                        <div className='text-white'>
                                            $ {getCurrentPrice(item.alias) === undefined ? '0.00' : (parseFloat(gasFeeData?.[idx][2]) * getCurrentPrice(item.alias)).toFixed(9)}
                                        </div>
                                        <div>
                                            <div className='text-light-gray-purple'>({parseFloat(gasFeeData?.[idx][2]).toFixed(12)} {item?.protocol !== 721 ? item?.symbol : item?.symbol})</div>
                                        </div>
                                        <div className='text-light-gray-purple'>{t('134')} : {gasFeeData?.[idx][0]} Gwei</div>
                                    </div>
                                    <div className='flex flex-row gap-[8px]'>
                                        <div className='text-white'>
                                            <div>{speed.timeRange} {t('136')}</div>
                                        </div>
                                        <div
                                            onClick={() => {
                                                clickSetSpeedRadioBox(idx);
                                                setSendingMaxPriorityFeePerGas(gasFeeData?.[idx][3])
                                                setSendingMaxFeePerGas(gasFeeData?.[idx][4])
                                                setSendingSpeed(speed.level)
                                                setSendingGaspriceBsc(gasFeeData?.[idx][5])
                                                goSendProducts()
                                            }}>
                                            <img className='w-[24px] h-[24px]' src={`/assets/icon/radio${clickedRadioBox === idx ? '_checked' : ''}.png`} alt='' /> :
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div>
                <div className="text-light-gray-purple text-[14px]" dangerouslySetInnerHTML={{__html : t('137')}} />
            </div>
            <div className='text-primary-yellow text-[16px]'>{remainSec} {t('135')}</div>
        </div>
    )
}
export default SetSpeed;
import { useShallow } from '../lib/hooks/useShallow';
import { createWithEqualityFn } from 'zustand/traditional';
/**
 * 시세정보 api store
 */

const initialState = {
    marketData : null,
    updateTime : null
}

export const GeckoStore = createWithEqualityFn((set) => ({
    ...initialState,
    setMarketData: (items) => set({marketData : items}),
    setUpdateTime: (items) => set({updateTime : items}),
    reset : () => {
        set(initialState)
    }
}),Object.is)

export const useGeckoStore = (keys) => {
    return useShallow(GeckoStore, keys);
};
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TransactionDetailItem from '../components/TransactionDetailItem';
import { formatEther, formatUnits } from 'ethers';
import { useTranslation } from 'react-i18next';
import { useWalletStore } from '../store/WalletStore';
import { useLocation } from 'react-router-dom';
import { formatTimestampToTime, isApp, timestampToDateString } from '../utils/CommonUtil';
import { getLatestBlockNumber } from '../utils/WalletUtil';
import { useDefaultStore } from '../store/DefaultStore';

function TransactionDetail() {
    const { t } = useTranslation();
    const location = useLocation();
    const {currentWallet}  = useWalletStore(['currentWallet']);
    const {setShare} = useDefaultStore(['setShare'])
    const {item} = location.state;
    const [block,setBlock] = useState('');
    const onLoad = useCallback(async () => {
        const BN = await getLatestBlockNumber(item.networkInfo.endPoint);
        setBlock(`${item.blockNumber} (${BN - item.blockNumber + 1} Confirmations)`);

        let txUrl = "";
        if(item.networkInfo.isMain) {
            if(item.networkInfo.symbol === "eth") {
                txUrl = 'https://etherscan.io'
            }
            else if(item.networkInfo.symbol === 'bnb') {
                txUrl = 'https://bscscan.com'
            }
        }
        // testnet
        else {
            if(item.networkInfo.symbol === 'seporilaeth') {
                txUrl = 'https://sepolia.etherscan.io'
            }
            else if(item.networkInfo.symbol === 'tbnb') {
                txUrl = 'https://testnet.bscscan.com'
            }
        }
        txUrl += '/tx/' + item.hash;
        setShare({
            title : "Transaction Detail",
            url : txUrl
        });
    },[])
    useEffect(() => {
        if(isApp()){
            onLoad();
            if ( item.networkInfo.groupName ==='ETHEREUM' || item.networkInfo.groupName === 'Seporila(Testnet)' ) {   
                item.networkInfo.groupName ==='ETHEREUM' ? item.symbol = "ETH" : item.symbol = "SeporliaETH"
            } else {
                item.networkInfo.groupName ==='BINANCE(BSC)' ? item.symbol = "BNB" : item.symbol = "tBNB"
            }
        }
    },[])
    
    return (
        <div className='flex-1 overflow-y-auto pt-[24px] pb-[91px] flex flex-col gap-[40px] text-white'>
            {/* <pre className='text-white'>{JSON.stringify(item, null, 2)}</pre> */}
            <TransactionDetailItem type={'value'} title={t('104')} price={
                        (
                            item.methodId === "0x" ?
                            (
                                item.value ? parseFloat(formatEther(item.value)).toFixed(4).toString().replace(/(\.[0-9]*[1-9])0+$|\.0*$/, "$1")
                                : (item.networkInfo.isNft ? 1 : 0)
                                + ' ' + item.networkInfo.alias
                            )
                            : (
                                item.tokenDecimal ?
                                (
                                    item.value ? parseFloat( formatUnits( item.value, parseInt(item.tokenDecimal) )).toFixed(4).toString().replace(/(\.[0-9]*[1-9])0+$|\.0*$/, "$1")
                                    : (item.networkInfo.isNft ? 1 : 0)
                                    + ' ' + item.networkInfo.alias.toUpperCase()
                                ) :
                                item.value ? parseFloat(formatEther(item.value)).toFixed(4).toString().replace(/(\.[0-9]*[1-9])0+$|\.0*$/, "$1")
                                : (item.networkInfo.isNft ? 1 : 0)
                                + ' ' + item.networkInfo.alias.toUpperCase()
                            )
                        )
                    } value={
                item.networkInfo.isCoin ? (
                    item.methodId === '0x'
                    ? `${item.to.toUpperCase() === currentWallet.publicKey.toUpperCase() ? t('105') : t('106')}` 
                    : item.methodId
                )
                : `${item.to.toUpperCase() === currentWallet.publicKey.toUpperCase() ? t('105') : t('106')}` 
            }/>
            <TransactionDetailItem type={'hash'} title={`${t('107')} #`} value={item.hash} isCopy copyValue={item.hash} />
            <TransactionDetailItem type={'network'} title={t('108')} value={item.networkInfo.groupName} showImage image={item.networkInfo.symbol} />
            {
                !item.networkInfo.isCoin
                &&  <TransactionDetailItem type={'token'} title={t('109')} isToken={!item.networkInfo.isCoin} 
                                           contractAddress={item.networkInfo.contractAddress} value={item.name} showImage image={item.networkInfo.alias} isCopy
                                           copyValue={item.networkInfo.contractAddress} />
            }
            <TransactionDetailItem type={'from'} title={t('110')}  value={item.from} isCopy copyValue={item.from} /> 
            <TransactionDetailItem type={'to'} title={t('111')}  value={item.to} isCopy copyValue={item.to} />
            <TransactionDetailItem type={'block'} title={t('112')} value={block} />
            <TransactionDetailItem type={'gas'} title={t('113')} value={item.gasUsed}  />
            <TransactionDetailItem type={'fee'} title={t('114')} 
                                   value={ `${formatEther(item.gasUsed * item.gasPrice)} ${item.symbol}` }  />
            <TransactionDetailItem type={'price'} title={t('115')} value={formatUnits(item.gasPrice, "gwei" ) + ' Gwei'} />
            <TransactionDetailItem type={'time'} title={t('116')} value={`${timestampToDateString(item.timeStamp,true)} ${formatTimestampToTime(item.timeStamp)}`} />
        </div>
    );
}

export default TransactionDetail;

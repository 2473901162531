import React from 'react';

function SubTitle({title}) {
    return (
        <div className='text-[0.875rem] font-bold text-medium-gray'>
        {title}
        </div>
    );
}

export default SubTitle;
import React, { useCallback } from 'react';
import { useGeckoStore } from '../store/GeckoStore';
import { commonGroupBy, getItem, isApp, setItem } from '../utils/CommonUtil';
import { useNetworkStore } from '../store/NetworkStore';

function TokenItem({item,setTokens}) {
    const {marketData} = useGeckoStore(['marketData']);
    const {setNetworks} = useNetworkStore(['setNetworks']);
    const getImage = useCallback((flag) => {
        if(marketData) {
            const marketItem = marketData.find(marketItem => marketItem.symbol === flag.toLowerCase());
            if(marketItem) {
                return marketItem.image;
            }
        }
    },[])

    const onToggle = useCallback(async () => {
        if(isApp()) {
            const networkList = await getItem('NETWORK_LIST');
            const networks = JSON.parse(networkList);
            
            const updateNetworks = networks.map(networkItem => 
                (!networkItem.isCoin && networkItem.endPoint === item.endPoint && networkItem.contractAddress === item.contractAddress)
                ? {
                    ...networkItem, display : !item.display
                } : networkItem
            )
            await setItem('NETWORK_LIST',JSON.stringify(updateNetworks));
            const globalNetworkList = commonGroupBy(updateNetworks,'isNft');
            // 전역 networks set
            setNetworks({
                asset : commonGroupBy(globalNetworkList.asset || [],"groupName"),
                collectible : commonGroupBy(globalNetworkList.collectible || [],"groupName")
            });

            // token 영역 set
            const onlyTokens = updateNetworks.filter(networkItem => !networkItem.isCoin)
            setTokens(commonGroupBy(onlyTokens,'display'));
        }
    },[item.contractAddress, item.display, item.endPoint, setNetworks, setTokens])

    return (
        <div className='flex flex-row items-center gap-[16px] py-[16px] cursor-pointer' onClick={onToggle}>
            <div className='flex-none relative w-[24px] h-[24px] bg-white rounded-[50%]'>
            {
                    getImage(item.alias) 
                    ? <img src={getImage(item.alias)} alt='' />
                    : (item.protocol === 721 && item.tokenMetadata && item.tokenMetadata.length > 0) ? <img src={item.tokenMetadata[0].image} alt='' className='object-cover' />
                    : <div className='truncate bg-lavender-gray w-[24px] h-[24px] rounded-[50%] text-medium-gray flex justify-center items-center text-[8px]'>
                        {item.alias}
                    </div>
                }
                {
                    item.isMain 
                    && <div className='w-[8px] h-[8px] rounded-[50%] bg-medium-gray absolute left-0 bottom-0'>
                        <img src={getImage(item.symbol) ? getImage(item.symbol) : `/assets/symbol/${item.symbol}.svg`} alt='' className='' />
                    </div>
                }
            </div>
            <div className='text-white text-[16px] flex-1'>
                {item.name}
            </div>
            <div className="slider-container">
                <div className="bar-image" />
                <div
                    className={`toggle-button ${item.display ? 'toggled' : ''}`}
                />
            </div>
        </div>
    );
}

export default TokenItem;
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
function WalletBackupModal() {
    const [isOpen, setIsOpen] = useState(true);
    const { t } = useTranslation();

    return (
        <Modal open={isOpen} onClose={() => setIsOpen(false)} center classNames={{
            overlay : 'custom_overlay',
            modal: 'custom_modal'
        }} showCloseIcon={false}>
            <div className='flex flex-col gap-[32px] items-center px-[24px] py-[32px]'>
                <div className='flex flex-col gap-[16px] w-[264px]'> 
                    <div className='text-white text-[20px] font-bold text-center'>
                        {t('5')} 
                    </div>
                    <div className='text-lavender-gray text-[16px] text-center' dangerouslySetInnerHTML={{__html : t('6')}} />
                </div>
                <div className='bg-primary-yellow w-[160px] py-[18.5px] rounded-[4px]
                                text-[16px] text-center cursor-pointer'
                                onClick={() => setIsOpen(false)}>
                    {t('7')}
                </div>
            </div>
        </Modal>
    );
}

export default WalletBackupModal;
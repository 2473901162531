import React, { useMemo } from 'react';
import { useWalletStore } from '../store/WalletStore';
import BackupDot from './BackupDot';
import { useLocation, useNavigate } from 'react-router-dom';
import { ACTIVITY_DETAIL_PAGE, ACTIVITY_PAGE, ADD_CUSTOM_TOKEN_PAGE, ADD_HIDE_TOKENS_PAGE, ASSET_DETAIL_PAGE, BACKUP_WALLET_STEP01_PAGE, BACKUP_WALLET_STEP02_PAGE, BACKUP_WALLET_STEP03_PAGE, BACKUP_WALLET_STEP04_PAGE, BROWSER_PAGE, CHANGE_CURRENCY_PAGE, CHANGE_LANGUAGE_PAGE, COLLECTIBLE_DETAIL_PAGE, COLLECTIBLE_METADATA_PAGE, CREATE, IMPORT_WALLET_PRIVATE_PAGE, IMPORT_WALLET_SEED_PAGE, IMPORT_WALLET_WATCH_PAGE, MAIN_PAGE, MANAGE_WALLET_PAGE, MY_WALLET_PAGE, NAME_THIS_WALLET_PAGE, NFT_DETAIL_METADATA_PAGE, NFT_DETAIL_PAGE, SELECT_ACTIVE_NETWORKS_PAGE, SEND_ASSETS_PAGE, SEND_COLLECTIBLES_PAGE, SETTING_PAGE, SET_SPEED_PAGE, TOKEN_DETAIL_PAGE, TRANSACTION_DETAIL_PAGE, TRANSACTION_NFT_DETAIL_PAGE, WALLETS, WALLETS_PAGE } from '../global/Constant';
import { Slide, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { generateWalletAfterInfo, get, getItem, isApp, isBoolean, onShare, set, share, success} from '../utils/CommonUtil';
import { useSettingStore } from '../store/SettingStore';
import { useDefaultStore } from '../store/DefaultStore';
function Header() {
    const { currentWallet, setCurrentWallet, addWalletGroupByConnected } = useWalletStore(['currentWallet', 'setCurrentWallet', 'addWalletGroupByConnected']);
    const { setSettingOpen } = useSettingStore(['setSettingOpen'])
    const { headerTitle, share } = useDefaultStore(['headerTitle', 'share'])

    const location = useLocation();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const title = useMemo(() => {
        return location.pathname === MAIN_PAGE ? currentWallet?.nickName
            : location.pathname === WALLETS_PAGE ? t('148')
            : location.pathname === ACTIVITY_PAGE ? t('149')
            : location.pathname === BROWSER_PAGE ? t('150')
            : location.pathname === SETTING_PAGE ? t('151')
            : location.pathname === MY_WALLET_PAGE ? t('152')
            : location.pathname === NAME_THIS_WALLET_PAGE ? t('153')
            : location.pathname === MANAGE_WALLET_PAGE ? t('154')
            : location.pathname === CHANGE_LANGUAGE_PAGE ? t('156')
            : location.pathname === CHANGE_CURRENCY_PAGE ? t('27')
            : location.pathname === ADD_HIDE_TOKENS_PAGE ? t('157')
            : location.pathname === ADD_CUSTOM_TOKEN_PAGE ? t('158')
            : location.pathname === SELECT_ACTIVE_NETWORKS_PAGE ? t('159')
            : location.pathname === TRANSACTION_DETAIL_PAGE ? t('162')
            : location.pathname === ASSET_DETAIL_PAGE ? headerTitle // tokenName
            : location.pathname === COLLECTIBLE_DETAIL_PAGE ? headerTitle // tokenName
            : location.pathname === COLLECTIBLE_METADATA_PAGE ? headerTitle // tokenNameId
            : location.pathname === SEND_ASSETS_PAGE ? t('123')


            // : location.pathname === NFT_ACTIVITY_DETAIL_PAGE ? activity
            : location.pathname === ACTIVITY_DETAIL_PAGE ? t('92')
            // : location.pathname === SEND_ASSETS_PAGE ? send
            : location.pathname === SEND_COLLECTIBLES_PAGE ? t('123')
            // : location.pathname === SET_SPEED_PAGE ? send
            : (location.pathname === IMPORT_WALLET_SEED_PAGE
                || location.pathname === IMPORT_WALLET_PRIVATE_PAGE
                || location.pathname === IMPORT_WALLET_WATCH_PAGE)
                ? t('49')
                : ""
    }, [currentWallet?.nickName, location.pathname, t])

    const addCustom = () => {
        navigate(ADD_CUSTOM_TOKEN_PAGE);
    }

    const goBack = async () => {
        if (isApp()) {
            if (location.pathname === ACTIVITY_PAGE) {
                navigate(MAIN_PAGE, { replace: true })
            }
            // 지갑 목록 뒤로가기 시, stack 제거
            else if (location.pathname === WALLETS_PAGE) {
                navigate(MAIN_PAGE, { replace: true })
            }
            // 백업 step02 일 경우
            else if (location.pathname === BACKUP_WALLET_STEP02_PAGE) {
                navigate(SETTING_PAGE, { replace: true })
            }
            else if (location.pathname === MANAGE_WALLET_PAGE) {
                navigate(WALLETS_PAGE, { replace: true })
            }
            else if (location.pathname === BACKUP_WALLET_STEP03_PAGE) {
                // 1. 바이오 인증
                const result = await get('getLocalAuth');
                if (isBoolean(result)) {
                    if (result) {
                        navigate(BACKUP_WALLET_STEP02_PAGE, { replace: true })
                    }
                }
                else {
                    // 모달창을 띄운다
                    setSettingOpen(true);
                }
            }
            else {
                navigate(-1)
            }
        }
    };

    const showMenu = async () => {
        if (isApp() && currentWallet !== null) {
            let lang = await getItem('language');
            if (lang === null) lang = 'en';
            const result = await set('showMenu', {
                walletAddress: currentWallet?.publicKey,
                isBackup: currentWallet?.isBackup,
                lang: lang
            });
            if (result === 'COPY') {
                toast(t('57'), {
                    position: 'bottom-center',
                    autoClose: 1000,
                    closeButton: false,
                    hideProgressBar: true,
                    pauseOnHover: false,
                    draggable: false,
                    transition: Slide,
                    className: '!bg-dark-gray-purple !text-[14px] !text-white',
                    icon: <img src='/assets/icon/success.svg' alt='' />
                });
            }
            else if (result === 'SHOW') {
                navigate(MY_WALLET_PAGE);
            }
            else if (result === 'ADD') {
                navigate(ADD_HIDE_TOKENS_PAGE);
            }
            else if (result === 'RENAME') {
                navigate(NAME_THIS_WALLET_PAGE);
            }
            else if (result === 'ACTIVITY') {
                navigate(ACTIVITY_PAGE);
            }
            else if (result === 'SETTING') {
                navigate(SETTING_PAGE);
            }
        }
    }

    const showPlus = async () => {
        if (isApp()) {
            let lang = await getItem('language');
            if (lang === null) lang = 'en';
            const result = await set('showPlus', {
                lang: lang
            });
            if (result === 'CREATE') {
                const addWallet = await generateWalletAfterInfo(CREATE);
                // 마지막번째만 groupBywallet 객체에 추가
                addWalletGroupByConnected(addWallet);
                const wallets = await getItem(WALLETS);
                const updateWallet = {};
                JSON.parse(wallets).map(walletItem => {
                    const selectedAccount = walletItem.accounts.find(account => account.isSelected);
                    if (selectedAccount) {
                        updateWallet.isBackup = walletItem.isBackup;
                        updateWallet.index = selectedAccount.index;
                        updateWallet.isSelected = selectedAccount.isSelected;
                        updateWallet.publicKey = selectedAccount.publicKey;
                        updateWallet.nickName = selectedAccount.nickName;
                        updateWallet.isSeed = selectedAccount.isSeed;
                        updateWallet.isPrivateKey = selectedAccount.isPrivateKey;
                        updateWallet.isWatch = selectedAccount.isWatch;
                    }
                })
                setCurrentWallet(updateWallet);
                await success();
            }
            else if (result === 'IMPORT') {
                navigate(IMPORT_WALLET_SEED_PAGE);
            }
            else if (result === 'WATCH') {
                navigate(IMPORT_WALLET_WATCH_PAGE);
            }
        }
    }

    const onShareInfo = async () => {
        if(isApp()) { 
           await onShare(share.url, share.title); 
        }
    }


    return (
        <div className='flex-none bg-dark-purple'>
            <div className='pt-[2.5rem] pb-[1rem] px-[1.5rem] flex flex-row'>
                {
                    (location.pathname !== MAIN_PAGE
                        && location.pathname !== BROWSER_PAGE
                        && location.pathname !== SETTING_PAGE)
                    && <div className='cursor-pointer' onClick={goBack}>
                        <img src='/assets/icon/back.svg' alt='' />
                    </div>
                }
                {
                    location.pathname === MAIN_PAGE
                    && <div className='cursor-pointer' onClick={() => navigate(WALLETS_PAGE)}>
                        <img src='/assets/gnb/profile.svg' alt='' className='rounded-[50%]' />
                    </div>
                }
                <div className='flex-1 text-center text-white px-[22px]'>
                    {title}
                </div>
                {
                    location.pathname === MAIN_PAGE &&
                    <div className='relative cursor-pointer' onClick={showMenu}>
                        <img src='/assets/icon/menu.svg' alt='' />
                        {!currentWallet?.isBackup && <BackupDot />}
                    </div>
                }
                {
                    (location.pathname === WALLETS_PAGE || location.pathname === ADD_HIDE_TOKENS_PAGE) &&
                    <div className='cursor-pointer' onClick={location.pathname === WALLETS_PAGE ? showPlus : addCustom}>
                        <img src='/assets/icon/plus.svg' alt='' />
                    </div>
                }
                {
                    location.pathname === TRANSACTION_DETAIL_PAGE &&
                    <div className='cursor-pointer' onClick={onShareInfo}>
                        <img src='/assets/icon/share.svg' alt='' />
                    </div>
                }
            </div>
        </div>
    );
}

export default Header;
import React, { useCallback } from 'react';
import { Slide, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import SubTitle from './SubTitle';
import { isApp, set } from '../utils/CommonUtil';
import { useGeckoStore } from '../store/GeckoStore';
function TransactionDetailItem({type,title,value,showImage, image, contractAddress,isCopy, price, copyValue}) {
    const { t } = useTranslation();
    const {marketData} = useGeckoStore(['marketData']); 

    const onAddressCopy = async () => {
        if(isApp()) {
            const result = await set('copyAddress',copyValue);
            if(result){
                toast(t('57'),{
                position : 'bottom-center',
                autoClose : 1000,
                closeButton : false,
                hideProgressBar : true,
                pauseOnHover : false,
                draggable : false,
                transition : Slide,
                className : '!bg-dark-gray-purple !text-[14px] !text-white',
                icon : <img src='/assets/icon/success.svg' alt='' />
                });
            }
        }
    }

    const getImage = useCallback((flag) => {
        if (marketData) {
            const marketItem = marketData.find(marketItem => marketItem.symbol === flag?.toLowerCase());
            if (marketItem) {
                return marketItem.image;
            }
        }
    }, [])

    return (
        <div className='flex flex-col px-[24px]'>
            <SubTitle title={title} />
            <div className='flex flex-row py-[16px] items-center gap-[16px]'>
                {
                    showImage
                    &&  <div className='w-[22px] h-[22px] text-[12px] rounded-[50%] bg-white'>
                        {
                            getImage(image)
                            ? <img src={getImage(image)} alt='' />
                            : <div className='truncate bg-lavender-gray w-[22px] h-[22px] rounded-[50%] text-medium-gray flex justify-center items-center text-[12px]'>
                                    {image?.toUpperCase().substr(0,1)}
                                </div>
                        }
                        </div>
                }
                <div className='flex-1 flex flex-col gap-[2px] truncate'>
                    <div className='text-white text-[16px] truncate flex flex-row'>
                        <div className='truncate flex-1'>{value}</div>
                        {
                            type === 'value' 
                            && <div className='text-[16px] text-white flex flex-col items-end'>
                                <div>{price}</div>
                            </div>
                        }
                    </div>
                    {
                        contractAddress
                        && <div className='truncate text-light-gray-purple'>{contractAddress}</div>
                    }
                </div>
                {
                    isCopy
                    && <div className='w-[24px] h-[24px]' onClick={onAddressCopy}>
                        <img src='/assets/icon/copy.svg' alt='' />
                    </div>
                }
            </div>
        </div>
    );
}

export default TransactionDetailItem;
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import SubTitle from '../components/SubTitle';
import { useTranslation } from 'react-i18next';
import { commonGroupBy, getItem, isApp } from '../utils/CommonUtil';
import TokenItem from '../components/TokenItem';
import { useNetworkStore } from '../store/NetworkStore';

function AddHideTokens() {
    const { t } = useTranslation();
    const {networks} = useNetworkStore(['networks']);

    const [tokens,setTokens] = useState({});
    const [filterText, setFilterText] = useState('');

    const onFilterList = useCallback((keyword) => {
        setFilterText(keyword);
        const regex = new RegExp(keyword, 'i');
        setTokens(prev => Object.fromEntries(Object.entries(prev).map(([tokenKey,tokenItem]) => {
            const updateTokenItem = tokenItem.map((item,index) => {
                return {
                    ...item,
                    isSearch : regex.test(item.name)
                }
            })
            return [tokenKey, updateTokenItem]
        })))
    },[])

     const onLoad = useCallback(async () =>{ 
        const networkList = await getItem('NETWORK_LIST');
        const networks = JSON.parse(networkList);
        const onlyTokens = networks.filter(networkItem => !networkItem.isCoin)
        setTokens(commonGroupBy(onlyTokens,'display'));
    },[]);

    useEffect(() => {
        if(isApp()){
            onLoad();
        }
    },[])

    return (
        <div className='flex-1 flex flex-col gap-[24px] px-[24px] overflow-y-auto'>
            {/* 검색창 */}
            <div className='p-[0.5rem] flex flex-row gap-[0.5rem] bg-dark-navy rounded-[0.25rem]'>
                <img src='/assets/icon/search.svg' alt='검색 아이콘' />
                <input type='text' placeholder={t('86')} 
                        className='flex-1 bg-transparent focus:outline-none text-[0.875rem] text-[#ffffff] placeholder-medium-gray'
                        value={filterText} onChange={(e) => onFilterList(e.target.value)} />
            </div>
            <div className='flex flex-col gap-[40px]'>
                <div className='flex flex-col'>
                    {
                        Object.entries(tokens).sort(([key1], [key2]) => key1.localeCompare(key2))
                            .map(([groupKey,groupItem]) =>
                                <Fragment key={groupKey}>
                                    
                                    <SubTitle title={groupKey === 'display' ? t('74') : t('75')} />
                                    {
                                        groupItem.filter(item => item.isSearch).map((item,index) => <TokenItem key={index} item={item} setTokens={setTokens} />)
                                    }
                                </Fragment>
                            )
                    }
                </div>
            </div>
        </div>
    );
}

export default AddHideTokens;
import { useShallow } from '../lib/hooks/useShallow';
import { createWithEqualityFn } from 'zustand/traditional';
/**
 * 시세정보 api store
 */

const initialState = {
    currentWallet : null,
    wallets : [],
    walletGroup : null,
}

export const WalletStore = createWithEqualityFn((set) => ({
    ...initialState,
    setCurrentWallet: (items) => set({currentWallet : items}),
    setWalletGroup : (items) => set({walletGroup : items}),
    setWallets : (items) => set({wallets : items}),
    updateCurrentWallet : (key,value) => set(state => {
        const updateCurrentWallet = state.currentWallet;
        updateCurrentWallet[key] = value;
        return {
            currentWallet : updateCurrentWallet
        }
    }),
    updateItemKey: (seq, key, value) => set(state => {
        const updatedData = state.wallets.map(item => 
          item.seq === seq ? { ...item, [key]: value } : item
        );
        return { data: updatedData };
    }),
    // 신규 Item 추가
    addWalletGroupByConnected : (walletInfo) => set(state => {
        const updateWallets = Object.fromEntries(Object.entries(state.walletGroup ?? {}).map(([walletGroupKey,walletGroupItem]) => {
            const newWalletGroupItem = Object.fromEntries(Object.entries(walletGroupItem).map(([walletSubKey,walletSubItem]) => {
                if(walletSubKey === 'title') {
                    return [walletSubKey, walletSubItem]
                }
                else if(walletSubKey === 'wallet'){
                    const updateWalletItem = walletSubItem.map(item => {
                        return {
                            ...item,
                            isSelected : false
                        }
                    })
                    if(walletGroupKey === 'connected') {
                        updateWalletItem.push(walletInfo);
                    }
                    return [walletSubKey,updateWalletItem]
                }
            }))
            return [walletGroupKey,newWalletGroupItem]
        }));
        return {walletGroup : updateWallets}
    }),
    // 
    changeSelectedWalletGroup : (publicKey) => set(state => {
        const updateWallets = Object.fromEntries(Object.entries(state.walletGroup ?? {}).map(([walletGroupKey,walletGroupItem]) => {
            const newWalletGroupItem = Object.fromEntries(Object.entries(walletGroupItem).map(([walletSubKey,walletSubItem]) => {
                if(walletSubKey === 'title') {
                    return [walletSubKey, walletSubItem]
                }
                const updateWalletItem = walletSubItem.map((item,index) => {
                    return {
                        ...item,
                        isSelected : item.publicKey === publicKey
                    }
                });
                return [walletSubKey,updateWalletItem]                 
            }));
            return [walletGroupKey,newWalletGroupItem]
        }))
        return {walletGroup : updateWallets}
    }),
    changePriceWalletGroup: (publicKey,totalPrice, prevPrice, prevPercent) => set(state => {
        const updateWallets = Object.fromEntries(Object.entries(state.walletGroup ?? {}).map(([walletGroupKey,walletGroupItem]) => {
            // walletkey : connected, watch
            const newWalletGroupItem = Object.fromEntries(Object.entries(walletGroupItem).map(([walletSubKey,walletSubItem]) => {
                //walletSubKey : title,wallet
                if(walletSubKey === 'title') {
                    return [walletSubKey, walletSubItem]
                }
                const updateWalletItem = walletSubItem.map((item,index) => {
                    if(item.publicKey === publicKey) {
                        return {
                            ...item,
                            totalPrice,
                            prevPrice,
                            prevPercent
                        }
                    }
                    return item
                });
                return [walletSubKey,updateWalletItem]                 
            }));
            return [walletGroupKey,newWalletGroupItem]
        }))
        return {walletGroup : updateWallets}    
    }),
}),Object.is)

export const useWalletStore = (keys) => {
    return useShallow(WalletStore, keys);
};
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getItem, isApp, setItem } from '../utils/CommonUtil';
// import { getItem, isApp, setItem } from '../utils/CommonUtils';

function ChangeLanguage() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState('');

    const onChangeLanguage = async (lang) => {
        if (isApp()) {
            await setItem('language', lang);
            setLanguage(lang);
            i18n.changeLanguage(lang);
        }
    }

    const onLoad = async () => {
        if (isApp()) {
            let lang = await getItem('language');
            if (lang === null) lang = 'en';
            setLanguage(lang);
        }
    }
    // onLoad
    useEffect(() => {
        onLoad();
    }, []);

    return (
        <div className='flex-1 flex flex-col'>
            <div className='px-[24px] py-[16px] flex flex-row gap-[16px] items-center'>
                <div className='flex-1 text-white text-[16px]'>
                    {t('89')}
                </div>
                <div className='w-[24px] h-[24px] cursor-pointer' onClick={() => onChangeLanguage('en')}>
                    <img src={`/assets/icon/radio${language === 'en' ? "_checked" : ""}.png`} alt='' />
                </div>
            </div>
            <div className='px-[24px] py-[16px] flex flex-row gap-[16px] items-center'>
                <div className='flex-1 text-white text-[16px]'>
                    {t('90')}
                </div>
                <div className='w-[24px] h-[24px] cursor-pointer' onClick={() => onChangeLanguage('ko')}>
                    <img src={`/assets/icon/radio${language === 'ko' ? "_checked" : ""}.png`} alt='' />
                </div>
            </div>
        </div>
    );
}

export default ChangeLanguage;
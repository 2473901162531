import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useGeckoStore } from '../store/GeckoStore';
import { calculatePrice, getCurrencyUnit, isApp, numberWithCommas } from '../utils/CommonUtil';
import { getCoinBalance, getMyNftInfo1155, getMyNftInfo721, getNft721TokenId, getNft721Uri, getTokenBalance } from '../utils/WalletUtil';
import axios from 'axios';
import { useWalletStore } from '../store/WalletStore';
import { useNetworkStore } from '../store/NetworkStore';
import { useNavigate } from 'react-router-dom';
import { ASSET_DETAIL_PAGE, COLLECTIBLE_DETAIL_PAGE } from '../global/Constant';
import { useDefaultStore } from '../store/DefaultStore';
import { ethers } from 'ethers';

function CoinItem({ item/* ,setTotalPriceSum,setTotalPrevPriceSum */ }) {
    const { marketData } = useGeckoStore(['marketData']);
    const { currentWallet } = useWalletStore(['currentWallet']);

    const { updateBalance, updateMetadata } = useNetworkStore(['updateBalance', 'updateMetadata']);
    const { setHeaderTitle,currency } = useDefaultStore(['setHeaderTitle','currency']);

    const navigate = useNavigate();

    const goDetailPage = (item) => {
        if (!item.isNft) navigate(ASSET_DETAIL_PAGE, {
            state: {
                item,
                totalPrice,
                prevPercent
            }
        })
        else navigate(COLLECTIBLE_DETAIL_PAGE, {
            state: {
                item
            }
        })
    }


    // const [balance, setBalance] = useState(0);
    const getImage = useCallback((flag) => {
        if (marketData !== null) {
            const marketItem = marketData.find(marketItem => marketItem.symbol === flag.toLowerCase());
            if (marketItem) {
                return marketItem.image;
            }
        }
    }, [marketData]);

    const totalPrice = useMemo(() => {
        if (marketData !== null) {
            const marketInfo = marketData.find(marketItem => marketItem.symbol === item.alias.toLowerCase() && item.isMain)
            return marketInfo ? item.balance * marketInfo.current_price : 0;
        }
    }, [marketData,item.balance]);

    const prevPrice = useMemo(() => {
        if (marketData !== null) {
            const marketInfo = marketData.find(marketItem => marketItem.symbol === item.alias.toLowerCase() && item.isMain)
            return marketInfo ? calculatePrice(totalPrice, marketInfo.price_change_percentage_24h) : 0;
        }
    }, [marketData,item.balance]);

    const prevPercent = useMemo(() => {
        if (marketData !== null) {
            const marketInfo = marketData.find(marketItem => marketItem.symbol === item.alias.toLowerCase() && item.isMain)
            return marketInfo ? marketInfo.price_change_percentage_24h : 0;
        }
    }, [marketData,prevPrice])

    const getBalance = useCallback(async () => {
        if (currentWallet !== null) {
            const walletAddress = currentWallet.publicKey;
            // let tokenIdList;
            if (item.endPoint) {
                // coin 잔고 업데이트
                if (item.isCoin) {
                    updateBalance(item, await getCoinBalance(walletAddress, item.endPoint));
                }
                else {
                    // 토큰 잔고 업데이트
                    if (item.protocol === 20) {
                        updateBalance(item, await getTokenBalance(walletAddress, item.endPoint, item.contractAddress));
                    }
                    else if (item.protocol === 721) {
                        let tokenResult = await getMyNftInfo721(item.endPoint,item.contractAddress,walletAddress);
                        updateBalance(item, tokenResult ? tokenResult.balance : 0);
                        /* for (const tokenId of tokenIds) {
                            const metadata = await getTokenMetadata(tokenId);
                            console.log(`Metadata for Token ID ${tokenId}:`, metadata);
                        } */
                    }
                    else if(item.protocol === 1155){
                        let tokenResult = await getMyNftInfo1155(item.endPoint,item.contractAddress,walletAddress);
                        updateBalance(item, tokenResult ? tokenResult.balance : 0);
                    }
                }
            }
        }
    },[]);

    const onLoad = useCallback(async () => {
        await getBalance();
    },[])
    useEffect(() => {
        const controller = new AbortController();
        
        // 추후에 주석제거
        if (isApp()) {
            onLoad();
        }
        return () => {
            controller.abort();
        };
    }, [])

    return (
        <div className='flex flex-row gap-[1rem] items-center py-[1rem] cursor-pointer'
            onClick={() => {
                goDetailPage(item)
            }}
        >
            {/* <pre className='text-white'>{JSON.stringify(item, null, 2)}</pre> */}
            {/* 이미지 아이콘 */}
            <div className='flex-none relative w-[40px] h-[40px] rounded-[50%] bg-white'>
                {
                    getImage(item.alias)
                        ? <img src={getImage(item.alias)} alt='' className='rounded-[50%]' />
                        : (item.protocol === 721 && item.tokenMetadata && item.tokenMetadata.length > 0) ? <img src={item.tokenMetadata[0].image} alt='' className='object-cover' />
                            : <div className='truncate bg-lavender-gray w-[40px] h-[40px] rounded-[50%] text-medium-gray flex justify-center items-center text-[12px]'>
                                {item.alias}
                            </div>
                }
                {
                    (item.isMain && !item.isCoin)
                    && <div className='w-[14px] h-[14px] rounded-[50%] bg-medium-gray absolute left-0 bottom-0'>
                        <img src={getImage(item.symbol) ? getImage(item.symbol) : `/assets/symbol/${item.symbol}.svg`} alt='' className='' />
                    </div>
                }
            </div>
            <div className='flex-1 flex flex-col'>
                <div className='text-[1rem] text-white'>{item.name}</div>
                <div className='text-[14px] text-light-gray-purple flex flex-row gap-[2px]'>
                    {
                        <div>
                            {numberWithCommas({
                                number: parseFloat(item.balance || 0),
                                decimalPlaces: 2,
                                isB: true
                            })}
                        </div>
                    }
                    <div>
                        {item.alias}
                    </div>
                </div>
            </div>
            {
                (item.isMain && !item.isNft) && <div className='flex flex-col items-end'>
                    <div className='text-[16px] font-bold text-lavender-gray'>
                    {getCurrencyUnit(currency) + ' ' + numberWithCommas({
                        number: parseFloat(totalPrice || 0),
                        decimalPlaces: 2,
                        isB: true
                    })}</div>
                    {
                        <div className={`flex flex-row text-[1rem]  gap-[8px] ${parseFloat(prevPercent) === 0 ? 'text-light-gray-purple' : (parseFloat(prevPercent) > 0 ? 'text-success' : 'text-light-red')}`}>
                            <div>
                            {getCurrencyUnit(currency) + numberWithCommas({
                                number: parseFloat(prevPrice || 0),
                                decimalPlaces: 2,
                                isB: true
                            })}</div>
                            <div>
                                {item.prev1dayPercent > 0 && '+'}
                                {numberWithCommas({
                                    number: parseFloat(prevPercent || 0),
                                    decimalPlaces: 2,
                                    isB: true
                                })}%</div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}

export default React.memo(CoinItem);
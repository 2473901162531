import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNetworkStore } from '../store/NetworkStore';
import { useTranslation } from 'react-i18next';
import TabTitleItem from '../components/TabTitleItem';
import SubTitle from '../components/SubTitle';
import CoinItem from '../components/CoinItem';
import SearchField from '../components/SearchField';
import MainTotal from '../components/MainTotal';
import { MAIN_PAGE, MAIN_TAB_LIST } from '../global/Constant';
import WalletBackupModal from '../components/modal/WalletBackupModal';
import { useWalletStore } from '../store/WalletStore';
import { useLocation } from 'react-router-dom';
import { commonGroupBy, saveNetwork, set } from '../utils/CommonUtil';
import { useGeckoStore } from '../store/GeckoStore';

function Main( ) {
    const {t } = useTranslation();
    const location = useLocation();
    const {networks,setNetworks,updateFilter} = useNetworkStore(['networks','setNetworks','updateFilter']);
    const {currentWallet}  = useWalletStore(['currentWallet']);
    const {marketData} = useGeckoStore(['marketData']);

    const [tabList,setTabList] = useState(MAIN_TAB_LIST);

    const selectedIndex = useMemo(() => {
        return tabList.findIndex(tabItem => tabItem.selected)
    },[tabList])

    const changeTabIndex = useCallback((tabIndex) => {
        setTabList(prev => prev.map((prevItem,prevIndex) => {
            return {
                ...prevItem,
                selected : tabIndex === prevIndex
            }
        }));
    },[])

    const filteListByTab = useCallback((networkKey) => {
        if(selectedIndex === 1) {
            return networkKey === 'asset';
        }
        else if(selectedIndex === 2) {
            return networkKey === 'collectible';
        }
        return true;
    },[selectedIndex])
    

    const getTabTitle = useCallback((tabIndex) => {
        if(tabIndex === 0) {
            return t('9')
        }
        else if(tabIndex === 1) {
            return t('10')
        }
        return t('11')
    },[t])

    const onLoad = useCallback(async () => {
        if(location.pathname === MAIN_PAGE) {
            if(networks === null) {
                // 1. network 목록 조회
                const networkResponse = JSON.parse(await saveNetwork());
                const networkList = commonGroupBy(networkResponse,'isNft');
                setNetworks({
                    asset : commonGroupBy(networkList.asset || [],"groupName"),
                    collectible : commonGroupBy(networkList.collectible || [],"groupName")
                });
            }
            else {
                updateFilter('',true);
            }
        }
    },[])

    useEffect( ()=> {
        onLoad()
    }, [])

    return (
    <>
        <div className='flex-1  overflow-y-auto py-[1rem]'>
            <div className='flex flex-col gap-[1.5rem]'>
                {/* 가격,탭,검색창 영역 */}
                <div className='flex flex-col gap-[1.9375rem]'>
                    {/* 가격 */}
                    <MainTotal />
                    {/* 탭목록 */}
                    <div className='flex flex-col gap-[1rem] px-[1.5rem]'>
                        <div className='flex flex-row justify-between '>
                            {
                                tabList.map((tabItem,tabIndex) => <div key={tabIndex} className='text-medium-gray text-center flex-1 relative text-[1rem] cursor-pointer' onClick={() => changeTabIndex(tabIndex)}>
                                    <div className={`w-[0.1875rem] h-[0.1875rem] rounded-[50%] ${tabItem.selected ? 'bg-white' : ''} mx-auto`} />
                                    <div className={tabItem.selected ? 'text-white' : ''}>{getTabTitle(tabIndex)} </div>
                                </div>)
                            }
                        </div>
                        {/* 검색창 */}
                        <SearchField />
                    </div>
                </div>
                {
                    networks && Object.entries(networks).filter(([networkKey]) => filteListByTab(networkKey)).map(([networkKey, networkItem],networkIndex) => 
                        <div key={networkKey} className={`flex flex-col ${networkIndex === 0 ? 'gap-[16px]' : 'gap-[24px]'}`}>
                            <TabTitleItem title={networkKey === 'asset' ? t('83') : t('88')} />
                            { 
                                Object.entries(networkItem)
                                .map(([subGroupKey, subGroupItem]) => 
                                    <div key={subGroupKey} className='px-[24px] flex flex-col'>
                                            <SubTitle title={subGroupKey} />
                                            {
                                                subGroupItem.length > 0 && subGroupItem.filter(item => item.isSearch && item.display).map((item,index) => 
                                                    <CoinItem key={index} item={item} selectedIndex={selectedIndex} />)
                                            }
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
        </div>
        {!currentWallet?.isBackup && <WalletBackupModal />}
    </>
    );
}

export default Main;
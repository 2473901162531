import React, { useEffect, useState } from 'react';
// import { getItem, isApp, setItem } from '../utils/CommonUtils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getItem, isApp, setItem } from '../utils/CommonUtil';
import { useWalletStore } from '../store/WalletStore';
import { WALLETS } from '../global/Constant';

function NameThisWallet() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {updateCurrentWallet}  = useWalletStore(['updateCurrentWallet']);

    const [nickName, setNickName] = useState('');
    const onLoad = async () => {
        if (isApp()) {
            // 현재 지갑정보의 Wallet 이름 가져오기
            const walletList = await getItem('wallets');
            if (walletList !== null) {
                const wallets = JSON.parse(walletList);
                const currentWallet = wallets.find(item => item.accounts.some(account => account.isSelected));
                const selectedMyWallet = currentWallet.accounts.filter(item => item.isSelected);
                setNickName(selectedMyWallet[0].nickName);
                
            }
        }
    }
    const onSaveName = async () => {
        if (isApp()) {
            if (nickName.length === 0) {
                return;
            }
            const walletList = await getItem('wallets');
            if (walletList !== null) {
                const wallets = JSON.parse(walletList);
                const updateWallets = wallets.map((wallet) => {
                    const updateAccount = wallet.accounts.map((account) => {
                        if (account.isSelected) {
                            return { ...account, nickName: nickName }
                        }
                        else {
                            return account
                        }
                    });
                    return { ...wallet, accounts: updateAccount }
                })
                await setItem(WALLETS, JSON.stringify(updateWallets));
                updateCurrentWallet('nickName',nickName);
                navigate(-1);
            }
        }
    }

    // onLoad
    useEffect(() => {
        onLoad();
    }, []);
    return (
        <div className='flex-1 flex flex-col gap-[47px] items-stretch pt-[24px] px-[24px]'>
            <div></div>
            <div className='flex flex-col gap-[4px]'>
                <div className="text-[16px] text-lavender-gray">{t('84')}</div>
                <input type="text" className="p-[16px] bg-transparent border border-light-yellow text-white text-[16px] rounded-[4px] focus:outline-none"
                    value={nickName} onChange={(e) => setNickName(e.target.value)} />
            </div>
            <div className='flex justify-center'>
                <div className={`flex-none   px-[80.5px] py-[18.5px] rounded-[4px]
                                text-[16px] text-center bg-light-yellow text-dark-purple ${nickName.length === 0 && 'opacity-50'}`}
                    onClick={onSaveName}>
                    {t('85')}
                </div>
            </div>
        </div>
    );
}

export default NameThisWallet;
import React from 'react';

function InfoItem({ title, content }) {
    return (
        <div className='flex flex-row justify-between'>
            <div className='flex-1'>
                {title}
            </div>
            <div>
                {content}
            </div>
        </div>
    );
}

export default InfoItem;
import React, { useCallback } from 'react';
import { useGeckoStore } from '../store/GeckoStore';
import { useWalletStore } from '../store/WalletStore';
import { formatTimestampToTime, numberWithCommas, shortenWalletAddress } from '../utils/CommonUtil';
import { formatEther, formatUnits } from 'ethers';
import { useNavigate } from 'react-router-dom';
import { ACTIVITY_DETAIL_PAGE, TRANSACTION_DETAIL_PAGE } from '../global/Constant';
import { useTranslation } from 'react-i18next';

function ActivityItem({item}) {
    const { t } = useTranslation();
    const {currentWallet}  = useWalletStore(['currentWallet']);
    const {marketData} = useGeckoStore(['marketData']); 
    const navigate = useNavigate();

    const goDetail= () => {
        console.dir({ "item": item });
        if(item.tokenID) { // NFT
            navigate(ACTIVITY_DETAIL_PAGE, {
                state : {
                    item : item
                }
            });
        }
        else {
            navigate(TRANSACTION_DETAIL_PAGE, {
                state : {
                    item : item
                }
            });
        }
    }

    const getImage = useCallback((flag) => {
        if (marketData) {
            const marketItem = marketData.find(marketItem => marketItem.symbol === flag.toLowerCase());
            if (marketItem) {
                return marketItem.image;
            }
        }
    }, [])


    return (
        <div className='py-[16px] flex flex-row items-center gap-[8px]' onClick={goDetail}>
            <div className='flex-none relative w-[40px] h-[40px] bg-white rounded-[50%]'>
                    {
                        getImage(item.networkInfo.alias)
                            ? <img src={getImage(item.networkInfo.alias)} alt='' className='rounded-[50%]' />
                            : <div className='truncate bg-lavender-gray w-[40px] h-[40px] rounded-[50%] text-medium-gray flex justify-center items-center text-[12px]'>
                                { item.networkInfo.isMain ? item.networkInfo.alias.toUpperCase().substr(0,2) : item.networkInfo.alias}
                            </div>
                    }
                    {
                        (item.networkInfo.isMain && !item.networkInfo.isCoin)
                        && <div className='w-[14px] h-[14px] rounded-[50%] bg-medium-gray absolute left-0 bottom-0'>
                            <img src={getImage(item.networkInfo.symbol) ? getImage(item.networkInfo.symbol) : `/assets/symbol/${item.networkInfo.symbol}.svg`} alt='' className='' />
                        </div>
                    }
                    <img src={`/assets/activity/${item.to.toLowerCase() === currentWallet.publicKey.toLowerCase() ? 'import' : 'export'}.svg`} alt='' className='absolute right-0 bottom-0' />
            </div>
            <div className='flex-1 flex flex-row truncate'>
                <div className='flex-1 flex flex-col justify-center gap-[4px] truncate'>
                    <div className='text-[1rem] text-white'>
                        {   
                            item.methodId === '0x6a761202' ? 'Exec Transaction'
                            : item.networkInfo.isCoin ? (
                                item.methodId === '0x'
                                ? `${item.to.toLowerCase() === currentWallet.publicKey.toLowerCase() ? t('105') : t('106')} ${item.networkInfo.symbol !== undefined ? item.networkInfo.symbol : item.networkInfo.alias}` 
                                : item.methodId
                            )
                            : `${item.to.toLowerCase() === currentWallet.publicKey.toLowerCase() ? t('105') : t('106')} ${item.networkInfo.alias.toUpperCase()}` 
                        }
                    </div>
                    <div className='text-lavender-gray truncate'>{item.to.toLowerCase() === currentWallet.publicKey.toLowerCase() ? t('111') : t('110')} : {shortenWalletAddress(
                        item.to.toLowerCase() === currentWallet.publicKey.toLowerCase() ? item.from : item.to,
                        4,4)}</div>
                </div>
                <div className='flex flex-col gap-[2px] items-end'>
                    <div className='text-[1rem] text-white font-bold'>
                    {
                        (
                            item.methodId === "0x" ?
                            (
                                item.value ? numberWithCommas({
                                    number : parseFloat(formatEther(item.value || 0)),
                                    decimalPlaces : 4,
                                    isB : true}) : (item.networkInfo.isNft ? 1 : 0)
                            )
                            : (
                                item.tokenDecimal ?
                                (
                                    item.value ? numberWithCommas({
                                        number : parseFloat( formatUnits( item.value, parseInt(item.tokenDecimal) )),
                                        decimalPlaces : 4,
                                        isB : true}) : (item.networkInfo.isNft ? `# ${item.tokenID}` : 0)
                                ) :
                                item.value ? numberWithCommas({
                                    number : parseFloat(formatEther(item.value || 0)),
                                    decimalPlaces : 4,
                                    isB : true}) : (item.networkInfo.isNft ? 1 : 0)
                            )
                        )
                    }
                    {
                        ` ${item.networkInfo.isCoin ? 
                            item.networkInfo.symbol !== undefined ? item.networkInfo.symbol.toUpperCase() : item.networkInfo.alias
                            : item.networkInfo.alias}`
                    }
                    </div>
                    <div className='text-lavender-gray'>{formatTimestampToTime(item.timeStamp)}</div>
                </div>
            </div>
        </div>
    );
}

export default ActivityItem;

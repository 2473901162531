import React, { useCallback, useEffect } from 'react';
import Line from '../Line';
import { useTranslation } from 'react-i18next';
import { useImportStore } from '../../store/ImportStore';
import { onlyNumberAndAlpha } from '../../utils/CommonUtil';

function ImportWalletByPrivate() {
    const { importText,setImportText,setWordCount,isValid,setIsValid} = useImportStore(['importText','setImportText','setWordCount','isValid','setIsValid']);
    const { t } = useTranslation();

    const onChangePrivateText = useCallback((e) => {
        const privateText = e.target.value;
        setIsValid(onlyNumberAndAlpha(privateText));
        setImportText(privateText);
    },[setImportText, setIsValid])

    useEffect(() => {
        setImportText('')
        setWordCount(0)
        setIsValid(true)
    },[])

    return (
        <>
            <div className='flex flex-col px-[24px]'>
                <div className='flex flex-col gap-[8px]'>
                    <div className='text-[16px] text-lavender-gray'>{t('62')}</div>
                    <div className={`rounded-[4px] py-[16px] px-[24px] border ${!isValid ? 'border-light-red' : 'border-dark-gray-purple'} h-[111px]`}>
                        <textarea maxLength={64} onChange={onChangePrivateText} value={importText} className='resize-none box-border w-full h-full bg-transparent text-white outline-none font-inter leading-[21px]'></textarea>
                    </div>
                    {
                        !isValid && <div className='text-[16px] text-light-red text-right'>{t('70')}</div>
                    }
                </div>
                <div className='flex flex-col pt-[16px]'>
                    <Line />    
                    <div className='pt-[24px] text-light-gray-purple'>
                        {t('71')}
                    </div>
                </div>
                
            </div>
        </>
    );
}

export default ImportWalletByPrivate;
import React, { useEffect, useState } from 'react';
// import { get, getItem, isApp, isBoolean } from '../utils/CommonUtils';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSettingStore } from '../store/SettingStore';
import { get, getItem, isApp, isBoolean } from '../utils/CommonUtil';

function ShowSeedPharse() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { setSettingOpen } = useSettingStore(['setSettingOpen']);

    const [mnemonic, setMnemonic] = useState([]);
    const [auth, setAuth] = useState(false)
    const onLoad = async () => {
        if (isApp()) {
            const result = await get('getLocalAuth');
            setAuth(result);
            if (isBoolean(result)) {
                if (result) {
                    const walletList = await getItem('wallets');
                    const wallets = JSON.parse(walletList);
                    const currentWallet = wallets.find(item => item.accounts.some(account => account.isSelected));
                    setMnemonic(currentWallet.mnemonic.split(' '));
                }
                else {
                    navigate(-1);
                }
            }
            else {
                // 모달창을 띄운다
                setSettingOpen(true);
            }
        }
    }
    useEffect(() => {
        onLoad();
    }, [])
    if (!auth) {
        return <div></div>
    }

    return (

        <div className='flex-1 px-[24px] pt-[16px] pb-[40px] flex flex-col overflow-y-auto'>
            <div className='flex flex-col gap-[24px]'>
                <div className='text-white text-[20px] font-bold' dangerouslySetInnerHTML={{ __html: t('58') }} />
                {
                    mnemonic.length > 0 &&
                    <div className='flex flex-wrap gap-[8px]'>
                        {
                            mnemonic.map((item, index) => <div key={index} className='p-[16px] rounded-[4px] bg-dark-navy text-lavender-gray'>
                                {item}
                            </div>)
                        }
                    </div>
                }
            </div>
        </div>
    );
}

export default ShowSeedPharse;
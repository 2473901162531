import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGeckoStore } from '../store/GeckoStore';
import { calculateYesterdayPrice, formatDateString, getCurrencyUnit, numberWithCommas } from '../utils/CommonUtil';
import AssetDetaiInfoTitleItem from './AssetDetaiInfoTitleItem';
import { axiosGecko } from '../lib/axios';
import { Slide, toast } from 'react-toastify';
import AssetDetailInfoItem from './AssetDetailInfoItem';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
  } from "chart.js";
import DateButton from './DateButton';
import { useDefaultStore } from '../store/DefaultStore';
  
  
function AssetDetailInfo({ item, totalPrice, prevPercent }) {
    ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

    const { t } = useTranslation();
    const { marketData, updateTime }
        = useGeckoStore(['marketData', 'updateTime']);

    const { currency } = useDefaultStore(['currency']);

    const [activeIndex, setActiveIndex] = useState(0);
    const [dateButtons, setDateButtons] = useState([{
        display : '1D'
    },{
        display : '1W'
    },{
        display : '1M'
    },{
        display : '3M'
    },{
        display : '1Y'
    }])

    const [priceInfo, setPriceInfo] = useState(null);
    const [prices,setPrices] = useState(null);
    const getImage = useCallback((flag) => {
        if (marketData) {
            const marketItem = marketData.find(marketItem => marketItem.symbol === flag.toLowerCase());
            if (marketItem) {
                return marketItem.image;
            }
        }
    }, [])

    const assetPrice = marketData.filter((marketItem) => {
        return item.alias.toUpperCase() === marketItem.symbol.toUpperCase();
    })

    const onChangeChart = async (index) => {
        let days;
        if(index === 0) {
            // 1일전 24시간전
            days = '1';
        }
        else if(index === 1) {
            // 1주전 
            days = '7';
        }else if(index === 2) {
            // 1개월전
            days = '30';
        }else if(index === 3) {
            // 3개월전
            days = '90';
        }else if(index === 4) {
            // 1년전
            days = '365';
        }
        const marketItem = marketData.find(marketItem => marketItem.symbol === item.alias.toLowerCase());
        let id;
        if (marketItem) {
            id = marketItem.id;
            try{
                const params = {
                    vs_currency: currency.toLowerCase(),
                    days : days
                  };
            
                const res = await axiosGecko(`/${id}/market_chart`,{params});    
                setPrices(res.data.prices);
                setActiveIndex(index);
              }
              catch(ex) {
                console.log("Err::",ex)
              }
        }
    }

    const onLoad = async () => {
        // 시세정보가 존재한다
        let params;
        if(assetPrice.length !== 0){
            const currentDate = new Date();
            const sevenDaysAgo = new Date(currentDate);
            sevenDaysAgo.setDate(currentDate.getDate() - 7);
            const oneMonthAgo = new Date(currentDate);
            oneMonthAgo.setMonth(currentDate.getMonth() - 1);
            const oneYearAgo = new Date(currentDate);
            oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

            const dates = [{
                key : 'sevenDaysAgo',
                value : sevenDaysAgo.toISOString().split('T')[0]
            },{
                key : 'oneMonthAgo',
                value : oneMonthAgo.toISOString().split('T')[0]
            },
            {
                key : 'oneYearAgo',
                value : oneYearAgo.toISOString().split('T')[0]
            }]

            const getAgoData = async (dt) => {
                try{
                    const agoPromises = {};
                    for (let date of dt) { 
                        const {data} = await axiosGecko(`/${assetPrice[0].id}/history?date=${formatDateString(date.value)}`)
                        agoPromises[date.key] = currency === 'USD' ? data.market_data.current_price.usd : data.market_data.current_price.krw;
                    }
                    return await Promise.all([agoPromises]);
                }
                catch(error) {
                    console.log("ERR::",error)
                    toast(error, {
                        position: 'bottom-center',
                        autoClose: 1000,
                        closeButton: false,
                        hideProgressBar: true,
                        pauseOnHover: false,
                        draggable: false,
                        transition: Slide,
                        className: '!bg-dark-gray-purple !text-[14px] !text-white',
                        icon: <img src='/assets/icon/error.png' alt='' />
                    });
                }
                
            }

            const results = await getAgoData(dates);
            const currentPrice = assetPrice[0].current_price;
            const yesterdayPercent = assetPrice[0].price_change_percentage_24h;
            let weekAgoPrice;
            let monthAgoPrice;
            let yearAgoPrice;
            if(results && results.length === 1) {
                weekAgoPrice = results[0].sevenDaysAgo;
                monthAgoPrice = results[0].oneMonthAgo;
                yearAgoPrice = results[0].oneYearAgo;
            }
            params = {
                currentPrice  : currentPrice,
                yesterDay : calculateYesterdayPrice(
                    parseFloat(assetPrice[0].current_price),
                    parseFloat(assetPrice[0].price_change_percentage_24h)
                ),
                yesterdayPercent : yesterdayPercent,
                weekDay :  weekAgoPrice && (parseFloat(weekAgoPrice) * parseFloat(currentPrice) / 100.0),
                monthDay : monthAgoPrice && (parseFloat(monthAgoPrice) * parseFloat(currentPrice) / 100.0),
                yearDay :  yearAgoPrice &&(parseFloat(yearAgoPrice) * parseFloat(currentPrice) / 100.0),
                marketCap : assetPrice[0].market_cap,
                volume : assetPrice[0].total_volume,
                maxSupply : assetPrice[0].max_supply,
                allTimeLow : assetPrice[0].atl,
                allTimeHigh : assetPrice[0].ath
            } 
        }
        else {
            params = {
                currentPrice  : '0.00',
                yesterDay : '0.00',
                yesterdayPercent : '0.00',
            }
        }
        setPriceInfo(params)
        await onChangeChart(activeIndex);
    }

    useEffect(() => {
        onLoad();
    },[])
      
    if(priceInfo === null) {
        return;
    }

    return (
        <div className='flex-1 flex flex-col gap-[24px] px-[24px]'>
            <div className='flex flex-col gap-[16px] items-center'>
                <div className='flex-none relative w-[48px] h-[48px] bg-white rounded-[50%] text-white'>
                    {
                        getImage(item.alias)
                            ? <img src={getImage(item.alias)} alt='' className='rounded-[50%]' />
                            : <div className='truncate bg-lavender-gray w-[48px] h-[48px] rounded-[50%] text-medium-gray flex justify-center items-center text-[12px]'>
                                {item.alias}
                            </div>
                    }
                    {
                        (item.isMain && !item.isCoin)
                        && <div className='w-[14px] h-[14px] rounded-[50%] bg-medium-gray absolute left-0 bottom-0'>
                            <img src={getImage(item.symbol) ? getImage(item.symbol) : `/assets/symbol/${item.symbol}.svg`} alt='' className='' />
                        </div>
                    }
                </div>
                <div className='flex flex-col gap-[4px]'>
                    <div className='text-[24px] font-bold text-white text-center'>
                        {numberWithCommas({
                        number : parseFloat(item.balance || 0),
                        decimalPlaces : 2,
                        isB : true})} {item.alias}
                    </div>
                    <div className='flex flex-row gap-[8px] justify-center'>
                        <div className='flex flex-row gap-[4px] '>
                            <div className='text-medium-gray'>
                                {t('93')} : 
                            </div>
                            <div className='text-lavender-gray'>
                                {getCurrencyUnit(currency) + ' ' + numberWithCommas({
                                    number : parseFloat(totalPrice || 0),
                                    decimalPlaces : 2,
                                    isB : true})}
                            </div>
                        </div>
                        <div className={`${prevPercent < 0 ? 'text-light-red' : 'text-success'} `}>
                                {numberWithCommas({
                                    number : parseFloat(prevPercent || 0),
                                    decimalPlaces : 2,
                                    isB : true})}%
                        </div>
                    </div>
                </div>
            </div>
            {
                prices  && <div className='flex flex-col gap-[8px]'>
                    <div className='w-full h-[200px]'>
                        <Line data={{
                            labels: Array.from({ length: prices.length }, (_, i) => i + 1), // 임의로 가격 데이터 수에 맞는 라벨 생성
                            datasets: [
                            {
                                label: '',
                                data: prices,
                                fill: false,
                                borderColor: (prevPercent < 0 ? '#FF5858' : '#04af00'),
                                tension: 0.1,
                                backgroundColor: 'rgba(0, 0, 0, 0)', // 배경색을 투명하게 설정
                                pointRadius: 0, // 데이터 포인트 표시 안함
                            }
                            ]
                        }} options={{
                            responsive: true,
                            plugins: {
                            legend: {
                                display: false, // "Price in 7 days" 숨기기
                            },
                            },
                            scales: {
                            x: {
                                display: false, // x축 수치값 숨김
                                grid: {
                                display: true // x축의 그리드 라인을 비활성화
                                }
                            },
                            y: {
                                display: true,
                                ticks: {
                                display: false // y축 수치값 숨김
                                },
                                grid: {
                                display: true // y축의 그리드 라인을 활성화
                                }
                            }
                            }
                        }} />
                    </div>
                    <div className='flex flex-row gap-[10.75px] overflow-x-auto text-center justify-between text-white'>
                        {
                            dateButtons.map((item,index) => <div key={index} className='flex-1' onClick={() => onChangeChart(index)}><DateButton title={item.display} isSelected={index === activeIndex}  /></div>)
                        }
                    </div>
                </div>
            }
            {
                priceInfo.weekDay
                ? <div className='flex flex-col gap-[64px]'>
                    <div className='flex flex-col'>
                        <AssetDetaiInfoTitleItem title={t('94')} />
                        <AssetDetailInfoItem title={t('95')} price={`${numberWithCommas({
                                                                        number : parseFloat(priceInfo.yesterDay),
                                                                        decimalPlaces : 2,
                                                                        isB : true})  || '--'} ${currency.toUpperCase()}`} color />
                        <AssetDetailInfoItem title={t('96')} price={`${numberWithCommas({
                                                                        number : parseFloat(priceInfo.weekDay),
                                                                        decimalPlaces : 2,
                                                                        isB : true}) || '--'} ${currency.toUpperCase()}`} color />
                        <AssetDetailInfoItem title={t('97')} price={`${numberWithCommas({
                                                                        number : parseFloat(priceInfo.monthDay),
                                                                        decimalPlaces : 2,
                                                                        isB : true}) || '--'} ${currency.toUpperCase()}`} color />
                        <AssetDetailInfoItem title={t('98')} price={`${numberWithCommas({
                                                                        number : parseFloat(priceInfo.yearDay),
                                                                        decimalPlaces : 2,
                                                                        isB : true}) || '--'} ${currency.toUpperCase()}`} color />
                    </div>
                    <div className='flex flex-col'>
                        <AssetDetaiInfoTitleItem title={t('99')} />
                        <AssetDetailInfoItem title={t('100')} price={`${numberWithCommas({
                                                                        number : parseFloat(priceInfo.marketCap),
                                                                        decimalPlaces : 2,
                                                                        isB : true}) || '--'} ${currency.toUpperCase()}`} />
                        <AssetDetailInfoItem title={t('101')} price={`${numberWithCommas({
                                                                        number : parseFloat(priceInfo.volume),
                                                                        decimalPlaces : 2,
                                                                        isB : true})  || '--'} ${currency.toUpperCase()}`} />
                        <AssetDetailInfoItem title={t('102')} price={`${numberWithCommas({
                                                                        number : parseFloat(priceInfo.allTimeLow),
                                                                        decimalPlaces : 2,
                                                                        isB : true})  || '--'} ${currency.toUpperCase()}`} />
                        <AssetDetailInfoItem title={t('103')} price={`${numberWithCommas({
                                                                        number : parseFloat(priceInfo.allTimeHigh),
                                                                        decimalPlaces : 2,
                                                                        isB : true})  || '--'} ${currency.toUpperCase()}`} />
                    </div>
                </div>
                : <div className='text-light-gray-purple flex-1 flex items-center justify-center'>{t('117')}</div>
            }
            {/* <pre className='text-white'>{JSON.stringify(assetPrice[0],null,2)}</pre> */}
        </div>
    );
}

export default AssetDetailInfo;
import { ethers } from "ethers";
import { CREATE, IPFS_URL, LANGUAGE, WALLETS } from "../global/Constant";
export const isApp = () => window.flutter_inappwebview !== undefined;
export const App = window.flutter_inappwebview;
export const success = async () => await App.callHandler('success');
export const get = async (key) => await App.callHandler(key);
export const set = async (key, value) => await App.callHandler(key, value);
export const saveNetwork = async () => await App.callHandler('saveNetworkList');
export const getItem = async (key) => await App.callHandler('getItem', key);
export const setItem = async (key, value) => await App.callHandler('setItem', key, value);
export const removeItem = async (key) => await App.callHandler('removeItem', key);
export const onShare = async (value, title) => await App.callHandler('share', value, title);
export const shuffles = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

// 주소 요약함수
export const shortenWalletAddress = (address, start, end) => {
    if (!address) return '';
    const prefix = address.slice(0, start);
    const suffix = address.slice(-end);
    return `${prefix}...${suffix}`;
}

// bool 체크함수
export const isBoolean = (value) => typeof value === 'boolean';

// 0000.00 (억단위 B 표시)
export const numberWithCommas = ({ number, decimalPlaces, isB }) => {
    if (!isNaN(number)) {
        if (number >= 1e8 && isB) { // 1억 이상인 경우
            const formattedNumber = (number / 1e8).toFixed(decimalPlaces);
            const formattedWithCommas = new Intl.NumberFormat(undefined, {
                minimumFractionDigits: decimalPlaces,
                maximumFractionDigits: decimalPlaces,
            }).format(formattedNumber);
            return formattedWithCommas + 'B'; // 숫자를 억 단위로 나눈 후 소수점 자릿수 및 쉼표를 추가하고 'B'를 추가
        }

        return number.toLocaleString(undefined, {
            minimumFractionDigits: decimalPlaces, // 최소 소수점 자릿수
            maximumFractionDigits: decimalPlaces, // 최대 소수점 자릿수
        }); // 기본 언어 설정에 따라 쉼표를 추가하여 숫자를 포맷
    }
};

// 다국어 변환
export const setChangeLanguage = async (i18n) => {
    let lang = await getItem(LANGUAGE);
    if (lang === null) {
        // 최초 저장되어 있지 않으면 브라우저로 저장
        const browserLanguage = navigator.language || navigator.userLanguage;
        lang = browserLanguage.split('-')[0];
        await setItem(LANGUAGE, lang);
    }
    if (lang === "ko") {
        i18n.changeLanguage('ko');
    }
    else {
        i18n.changeLanguage('en');
    }
}
// 지갑정보 가져오기
export const getWallets = async () => {
    // 저장소에 들어있는 지갑목록 가져온다.  
    const result = await getItem(WALLETS);
    let wallets;
    // 없으면 빈배열 
    if (result === null) wallets = [];
    // 존재하면 배열 파싱
    else {
        wallets = JSON.parse(result);
        wallets = wallets.map(item => {
            return {
                ...item,
                accounts: item.accounts.map(account => {
                    if (account.isSelected) {
                        return { ...account, isSelected: false };
                    }
                    return account;
                })
            };
        });
    }
    return wallets;
}

// 지갑 생성로직
export const generateWallet = async (type) => {
    const wallets = await getWallets();
    let wallet;
    const seq = wallets.length === 0 ? 1 : (wallets[wallets.length - 1].seq + 1);
    // 신규 지갑 생성 타입
    if (type === CREATE) {
        const newWallet = ethers.Wallet.createRandom();
        wallet = {
            isBackup: false,
            seq: seq,
            mnemonic: newWallet.mnemonic.phrase,
            accounts: [{
                index: 0,
                isSelected: true,
                publicKey: newWallet.address,
                privateKey: newWallet.privateKey,
                nickName: `Wallet${seq}`,
                isSeed: true,
                isPrivateKey: false,
                isWatch: false
            }]
        }
    }
    wallets.push(wallet);
    await setItem(WALLETS,JSON.stringify(wallets));
}

// 지갑 생성 후, object return
export const generateWalletAfterInfo = async (type) => {
    const wallets = await getWallets();
    let wallet;
    const seq = wallets.length === 0 ? 1 : (wallets[wallets.length - 1].seq + 1);
    // 신규 지갑 생성 타입
    const walletInfo = {
        isSelected: true,
        isSeed: true,
        isPrivateKey: false,
        isWatch: false,
        totalPrice: 0,
        prevPrice: 0,
        prevPercent: 0,
        publicKey: '',
        nickName: ''
    }
    if (type === CREATE) {
        const newWallet = ethers.Wallet.createRandom();
        wallet = {
            isBackup: false,
            seq: seq,
            mnemonic: newWallet.mnemonic.phrase,
            accounts: [{
                index: 0,
                isSelected: true,
                publicKey: newWallet.address,
                privateKey: newWallet.privateKey,
                nickName: `Wallet${seq}`,
                isSeed: true,
                isPrivateKey: false,
                isWatch: false
            }]
        }
        walletInfo.publicKey = newWallet.address;
        walletInfo.nickName = `Wallet${seq}`;
    }
    wallets.push(wallet);
    await setItem(WALLETS, JSON.stringify(wallets));
    return walletInfo;
}

// 현재금액 + 전날 변동비율로 전날 비용 계산
export const calculatePrice = (todayPrice, percentageIncrease) => {
    const yesterdayPrice = todayPrice / (1 + (percentageIncrease / 100));
    return yesterdayPrice;
}

// 현재금액 + 전날비용으로 변동비율 계산
export const calculatePercent = (todayPrice, yesterdayPrice) => {
    const percentageChange = ((todayPrice - yesterdayPrice) / yesterdayPrice) * 100;
    return percentageChange;
}
// 숫자 + 영문만
export const onlyNumberAndAlpha = (value) => /^[a-zA-Z0-9]*$/.test(value);

export const timestampToDateString = (timestamp, passToday,t) => {
    const date = new Date(timestamp * 1000);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
  
    if (date.getTime() === today.getTime() && !passToday) {
      return t('8');
    } else {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}.${month}.${day}`;
    }
}
export const formatTimestampToTime = (timestamp) => {
    // 주어진 타임스탬프를 밀리초 단위로 변환
    const date = new Date(timestamp * 1000);
    // 시간 형식 옵션 설정
    const options = {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };
    // 시간 형식으로 변환
    return date.toLocaleTimeString('en-US', options);
}

export const commonGroupByIsWatch = async (setWalletGroup) => {
    const grouped = {
        connected: { title: 'CONNECTED WALLETS', wallet: [] },
        watch: { title: 'WATCH-ONLY WALLETS', wallet: [] }
    };
    const result = await getItem(WALLETS);
    const wallets = JSON.parse(result);
    wallets.forEach(item => {
        item.accounts.forEach(account => {
            const groupedAccount = {
                publicKey: account.publicKey,
                isSelected: account.isSelected,
                nickName: account.nickName,
                isSeed: account.isSeed,
                isPrivateKey: account.isPrivateKey,
                isWatch: account.isWatch,
                totalPrice: 0,
                prevPrice: 0,
                prevPercent: 0
            };

            if (account.isWatch) {
                grouped.watch.wallet.push(groupedAccount);
            } else {
                grouped.connected.wallet.push(groupedAccount);
            }
        });
    });
    setWalletGroup(grouped);
}

export const commonGroupByDate = (activities,t) => {
    return activities.reduce((acc, activity) => {
      const date = timestampToDateString(activity.timeStamp,false,t)
  
      if (!acc[date]) {
        acc[date] = [];
      }
  
      acc[date].push(activity);
      return acc;
    }, {});
};

export const commonGroupBy = (array, key) => {
    return array.reduce((result, currentValue) => {
        // boolean 값에 따라 그룹 키를 결정
        let groupKey;
        if (key === 'isNft') {
            groupKey = currentValue[key] ? 'collectible' : 'asset';
        }
        else if (key === 'display') {
            groupKey = currentValue[key] ? 'display' : 'hidden';
        }
        else {
            groupKey = currentValue[key];
        }

        // 그룹이 이미 존재하는지 확인
        if (!result[groupKey]) {
            // 그룹이 존재하지 않으면 빈 배열로 초기화
            result[groupKey] = [];
        }

        // 현재 값을 해당 그룹에 추가
        result[groupKey].push({
            ...currentValue,
            seq: (result[groupKey].length + 1)
        });

        return result;
    }, {}); // 초기값은 빈 객체
}


export const onPaste = async ({setState}) => {
    if (isApp()) {
        const result = await get('pasteAddress');
        if (result) {
            setState(result);
        }
    }
}
export const onQrScanner = async ({ setState, lang }) => {
    if (isApp()) {
        const result = await set('getQrcodeInfo',{
            lang: lang
        });
        if (result) {
            const match = result.match(/0x[a-fA-F0-9]+/);
            setState(match ? match[0] : result);
        }
    }
}

export const formatDateString = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
}

export const calculateYesterdayPrice = (todayPrice, percentageIncrease) => {
    const yesterdayPrice = todayPrice / (1 + (percentageIncrease / 100));
    return yesterdayPrice;
  }

export const convertIpfsUrl = (ipfsUrl) => {
    // IPFS 프로토콜 접두사를 공용 게이트웨이 URL로 변환
    // 'ipfs://'를 게이트웨이 접두사로 대체
    return ipfsUrl.replace("ipfs://", IPFS_URL);
}

export const getCurrencyUnit = (unit) => {
    return unit === 'KRW' ? '₩' : '$';
}
import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
function FailToBroadcastingTransactionModal({ isOpen, setIsOpen, title, fn, code }) {
    const { t } = useTranslation();

    return (
        <Modal open={isOpen} center classNames={{
            overlay: 'custom_overlay',
            modal: 'custom_modal'
        }} showCloseIcon={false} onClose={() => setIsOpen(false)}>
            <div className='flex flex-col gap-[32px] px-[24px] py-[32px] items-stretch'>
                <div className='flex flex-col gap-[16px] w-[264px]'>
                    <div className='flex justify-center items-center'>
                        <img src='/assets/icon/error.png' alt='' />
                    </div>
                    <div className='text-white text-[20px] font-bold text-center'>{title}</div>
                    <div className='text-lavender-gray text-[16px] text-center'>
                        {t('178')}<br />
                        {
                            code === "INSUFFICIENT_FUNDS" ? 
                            <div className='text-[#CCCBDB] text-[10px] h-[19px] overflow-y' dangerouslySetInnerHTML={{__html : t('179')}} /> : 
                            <div className='text-[#CCCBDB] text-[10px] h-[19px] overflow-y' dangerouslySetInnerHTML={{__html : t('180')}} />
                        }
                    </div>
                </div>
                <div className='flex flex-row justify-center items-center'>
                    <div className='bg-primary-yellow w-[120px] h-[60px] py-[5px] px-[10px] text-dark-purple text-[16px] rounded-[4px] cursor-pointer flex flex-row justify-center' onClick={() => fn ? fn() : setIsOpen(false)}>
                        <div className='flex flex-col justify-center'>
                            OK
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default FailToBroadcastingTransactionModal;
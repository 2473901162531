import { ethers, formatEther, formatUnits, parseEther, parseUnits } from "ethers";

export const getCoinBalance = async (address, network) => {
    const provider = new ethers.JsonRpcProvider(network);
    try {
        return formatEther(await provider.getBalance(address));
    } catch (err) {
        console.log(err)
    }
}

export const sendCoinAsIs = async (network, pk, toAddr, amount, maxPriorityFeePerGas, maxFeePerGas) => {
    const provider = new ethers.JsonRpcProvider(network);
    const wallet = new ethers.Wallet(pk, provider);
    const tx = {};

    tx.to = toAddr;
    tx.value = parseEther(String(amount));
    tx.maxPriorityFeePerGas = maxPriorityFeePerGas;
    tx.maxFeePerGas = maxFeePerGas;
    tx.gasLimit = await provider.estimateGas(tx);

    try {
        const transactionResponse = await wallet.sendTransaction(tx);
        // Wait for the transaction to be mined
        const receipt = await transactionResponse.wait();
    } catch (error) {
        return error;
    }
}

export const getTokenName = async (network, contractAddress) => {
    const provider = new ethers.JsonRpcProvider(network);
    const tokenABI = ["function name() view returns (string)"];
    try {
        const contract = new ethers.Contract(contractAddress, tokenABI, provider);
        return await contract.name();
    } catch (error) {
        return null;
    }

}

export const getTokenSymbol = async (_network, _contractAddress) => {
    const provider = new ethers.JsonRpcProvider(_network);
    const tokenABI = [
        "function symbol() public view returns (string)"
    ];

    try {
        const contract = new ethers.Contract(_contractAddress, tokenABI, provider);
        return await contract.symbol();
    } catch (error) {
        return null;
    }
}

export const getTokenBalance = async (address, network, contractAddress) => {
    const provider = new ethers.JsonRpcProvider(network);
    const tokenABI = ["function balanceOf(address owner) view returns (uint256)"];
    try {
        const contract = new ethers.Contract(contractAddress, tokenABI, provider);
        return formatUnits(await contract.balanceOf(address), await getTokenDecimals(network, contractAddress));
    } catch (err) {
        console.log(err)
    }
}
export const getTokenDecimals = async (network, contractAddress) => {
    const provider = new ethers.JsonRpcProvider(network);
    const tokenABI = ["function decimals() view returns (uint8)"];
    try {
        const contract = new ethers.Contract(contractAddress, tokenABI, provider);
        return await contract.decimals();
    } catch (error) {
        return null;
    }
}

export const sendTokenAsis = async (network, pk, toAddr, amount, maxPriorityFeePerGas, maxFeePerGas, contractAddress) => {
    const provider = new ethers.JsonRpcProvider(network);
    const wallet = new ethers.Wallet(pk, provider);

    const erc20Abi = [
        "function transfer(address to, uint amount) public returns (bool)",
        "function decimals() view returns (uint8)",
        "function balanceOf(address owner) view returns (uint256)",
        "function name() view returns (string)",
        "function symbol() public view returns (string)"
    ];

    const contract = new ethers.Contract(contractAddress, erc20Abi, wallet);
    const amountToken = parseUnits(String(amount), await contract.decimals()); // Assuming the token has 18 decimals

    try {

        const transactionResponse = await contract.transfer(toAddr, amountToken, {
            maxPriorityFeePerGas: maxPriorityFeePerGas,
            maxFeePerGas: maxFeePerGas
        })

        // Wait for the transaction to be mined
        const receipt = await transactionResponse.wait();
    } catch (error) {
        return error;
    }
}


// For 721-NFT
export const getTotalSupply = async (network, contractAddress) => {
    const provider = new ethers.JsonRpcProvider(network);
    const tokenABI = ["function totalSupply() external view returns (uint256)"];
    try {
        const contract = new ethers.Contract(contractAddress, tokenABI, provider);
        return await contract.totalSupply();
    } catch (error) {
        return null;
    }
}
// For 721-NFT
export const getNft721Uri = async (network, contractAddress, tokenId) => {
    const provider = new ethers.JsonRpcProvider(network);
    const tokenABI = [
        "function tokenURI(uint256 tokenId) view returns (string)",
    ];
    try {
        const contract = new ethers.Contract(contractAddress, tokenABI, provider);
        return await contract.tokenURI(tokenId);
    } catch (err) {
        console.log(err)
    }
}
// For 721-NFT
export const getNft721OwnerOf = async (network, contractAddress, tokenId) => {
    const provider = new ethers.JsonRpcProvider(network);
    const tokenABI = [
        "function ownerOf(uint256 tokenId) view returns (address)",
    ];
    try {
        const contract = new ethers.Contract(contractAddress, tokenABI, provider);
        return await contract.ownerOf(tokenId);
    } catch (error) {
        return null;
    }
}
// For 721-NFT
export const getNft721BalanceOf = async (network, contractAddress, address) => {
    const provider = new ethers.JsonRpcProvider(network);
    const tokenABI = [
        "function balanceOf(address owner) external view returns (uint256 balance)",
    ];
    try {
        const contract = new ethers.Contract(contractAddress, tokenABI, provider);
        return await contract.balanceOf(address);
    } catch (error) {
        return null;
    }
}
const nftChecker = async (network, contractAddress, address, tokenId) => {
    const owner = await getNft721OwnerOf(network, contractAddress, tokenId);

    if (owner !== null && (owner === address)) {
        // console.log('owner', owner)
        // console.log('tokenId', tokenId)
        return tokenId;
    }
}
// For 721-NFT
export const getNft721TokenId = async (network, contractAddress, address) => {

    const tokenIdList = []
    const provider = new ethers.JsonRpcProvider(network);
    const tokenABI = [
        // Replace with the actual ABI of the ERC-721 contract
        "function name() view returns (string)",
        "function symbol() view returns (string)",
        "function tokenURI(uint256 tokenId) view returns (string)",
        "function ownerOf(uint256 tokenId) view returns (address)",
        "function totalSupply() external view returns (uint256)",
        "function balanceOf(address owner) external view returns (uint256 balance)",
    ];
    try {
        const contract = new ethers.Contract(contractAddress, tokenABI, provider);
        const totalSupply = await contract.totalSupply();
        const balance721Amnt = await contract.balanceOf(address);

        // const promises = [];
        // for (let i = 1; i <= parseInt(totalSupply); i++) {
        //     promises.push(nftChecker(network, contractAddress, address, i));
        // }
        // const result = await Promise.all(promises)
        // if( result !== null ) tokenIdList.push(result)

        for (let i = 1, tot = 1; tot <= parseInt(balance721Amnt); i++) {
            const owner = await getNft721OwnerOf(network, contractAddress, i);
            if (owner !== null) {

                if (owner === address) {
                    tot++; // tokenId가 중간에 빠진 경우
                    tokenIdList.push(i);
                }
            }
        }
        return tokenIdList;
    } catch (err) {
        console.log(err);
        return null;
    }
}

// For 721-NFT
export const getNft721Transfer = async (network, contractAddress, tokenId, pk, from, to) => {
    const provider = new ethers.JsonRpcProvider(network);
    const wallet = new ethers.Wallet(pk, provider);

    const tokenABI = [
        "function safeTransferFrom(address from, address to, uint256 tokenId) external",
        "function ownerOf(uint256 tokenId) external view returns (address)"
    ];

    try {
        const contract = new ethers.Contract(contractAddress, tokenABI, wallet);
        const tx = await contract.safeTransferFrom(from, to, tokenId);

        // Waiting for the transaction to be confirmed
        await tx.wait();
    } catch (error) {
        console.error(`Error transferring token: ${error.message}`);
        return null;
    }
}




// For 1155-MFT
export const getNft1155Uri = async (network, contractAddress, tokenId) => {
    const provider = new ethers.JsonRpcProvider(network);
    const tokenABI = [
        "function uri(uint256 id) external view returns (string memory)",
    ];
    try {
        const contract = new ethers.Contract(contractAddress, tokenABI, provider);
        return await contract.uri(tokenId);
    } catch (error) {
        return null;
    }
}

// For 1155-MFT
export const getNft1155Balance = async (network, contractAddress, tokenId, address) => {
    const provider = new ethers.JsonRpcProvider(network);
    const tokenABI = [
        "function uri(uint256 id) external view returns (string memory)",
    ];
    try {
        const contract = new ethers.Contract(contractAddress, tokenABI, provider);
        const balance = await contract.balanceOf(address, tokenId);
        return balance;
    } catch (error) {
        return null;
    }
}

// For 1155-MFT
export const getNft1155Balances = async (network, contractAddress, tokenIds, addresses) => {
    // const tokenIds = [1, 2, 3]; // Replace with your token IDs
    // const accounts = [account, account, account]; // Replace with multiple accounts if needed

    const provider = new ethers.JsonRpcProvider(network);
    const tokenABI = [
        "function uri(uint256 id) external view returns (string memory)",
    ];

    try {
        const contract = new ethers.Contract(contractAddress, tokenABI, provider);
        const balances = await contract.balanceOfBatch(addresses, tokenIds);
        return balances;
    } catch (error) {
        return error;
    }
}
// For 1155-MFT
export const getNft1155Transfer = async (network, contractAddress, tokenId, pk, from, to, amount) => {
    const provider = new ethers.JsonRpcProvider(network);
    const wallet = new ethers.Wallet(pk, provider);

    const tokenABI = [
        "function safeTransferFrom(address from, address to, uint256 id, uint256 amount, bytes data) external",
        "function balanceOf(address account, uint256 id) external view returns (uint256)"
    ];

    try {
        const contract = new ethers.Contract(contractAddress, tokenABI, wallet);
        const tx = await contract.safeTransferFrom(from, to, tokenId, amount, '0x');

        // Waiting for the transaction to be confirmed
        await tx.wait();
    } catch (error) {
        return error;
    }
}

export const getLatestBlockNumber = async (network) => {
    const provider = new ethers.JsonRpcProvider(network);
    try {
        const result = await provider.getBlockNumber();
        return result;
    } catch (error) {
        return error;
    }
}

export const getFeeData = async (network) => {
    const provider = new ethers.JsonRpcProvider(network);
    try {
        const result = await provider.getFeeData();
        return result;
    } catch (error) {
        return error;
    }
}

export const getEstimatedGas = async (network, tx) => {
    const provider = new ethers.JsonRpcProvider(network);
    try {
        return await provider.estimateGas(tx);
    } catch (error) {
        return error;
    }
}

export const getMyNftInfo721 = async (network, contractAddress, walletAddress) => {
    const erc721Abi = [
        "function supportsInterface(bytes4 interfaceId) external view returns (bool)",
        "function name() view returns (string)",
        "function symbol() view returns (string)",
        "function balanceOf(address owner) view returns (uint256)",
        "function tokenOfOwnerByIndex(address owner, uint256 index) view returns (uint256)"
    ];

    const ERC721_INTERFACE_ID = "0x80ac58cd";
    const provider = new ethers.JsonRpcProvider(network);
    const contract721 = new ethers.Contract(contractAddress, erc721Abi, provider);
    try {
        const isERC721 = await contract721.supportsInterface(ERC721_INTERFACE_ID);
        if (isERC721) {
            const name = await contract721.name();
            const symbol = await contract721.symbol();
            const balance = parseInt(await contract721.balanceOf(walletAddress));
            return { name, symbol, balance }
        }
    } catch (error) {
        console.error("Error checking ERC-721 interface:", error);
    }
}
export const getMyNftInfo1155 = async (network, contractAddress, walletAddress) => {
    const erc1155Abi = [
        "function supportsInterface(bytes4 interfaceId) external view returns (bool)",
        "function name() view returns (string)",
        "function symbol() view returns (string)",
        "function balanceOf(address owner, uint256 id) view returns (uint256)"
    ];
    const ERC1155_INTERFACE_ID = "0xd9b67a26";
    const provider = new ethers.JsonRpcProvider(network);
    const contract1155 = new ethers.Contract(contractAddress, erc1155Abi, provider);
    try {
        const isERC1155 = await contract1155.supportsInterface(ERC1155_INTERFACE_ID);
        if (isERC1155) {
            const name = await contract1155.name();
            const symbol = await contract1155.symbol();
            const balance = parseInt(await contract1155.balanceOf(walletAddress, 1)); // 예시를 위해 ID 1 사용
            return { name, symbol, balance }
        }
    } catch (error) {
        console.error("Error checking ERC-1155 interface:", error);
    }
}
export const getMyTokenInfo = async (network, contractAddress, walletAddress) => {
    const erc20Abi = [
        "function name() view returns (string)",
        "function symbol() view returns (string)",
        "function balanceOf(address owner) view returns (uint256)"
    ];
    const provider = new ethers.JsonRpcProvider(network);
    const contract20 = new ethers.Contract(contractAddress, erc20Abi, provider);
    try {
        const name = await contract20.name();
        const symbol = await contract20.symbol();
        const balance = parseInt(await contract20.balanceOf(walletAddress));
        return { name, symbol, balance }
    } catch (error) {
        console.error("Error checking ERC-20 interface:", error);
    }
}

export const sendCoin = async (pk, item, sendingToAddress, sendingAmount, sendingMaxPriorityFeePerGas, sendingMaxFeePerGas, sendingGaspriceBsc) => {
    //
    const provider = new ethers.JsonRpcProvider(item.endPoint);
    const wallet = new ethers.Wallet(pk, provider);
    try {
        const tx = {};
        tx.to = sendingToAddress;
        tx.value = parseEther(String(sendingAmount));
        if (item.groupName === 'ETHEREUM' || item.groupName === 'Seporila(Testnet)') {
            tx.maxPriorityFeePerGas = sendingMaxPriorityFeePerGas;
            tx.maxFeePerGas = sendingMaxFeePerGas;
            tx.gasLimit = await provider.estimateGas(tx);
            // console.log("TP_confirmBtn_04");
        } else {
            tx.gasPrice = sendingGaspriceBsc;
            tx.gasLimit = 21000;
            // console.log("TP_confirmBtn_05");
        }

        const transactionResponse = await wallet.sendTransaction(tx);
        const receipt = await transactionResponse.wait();
        // console.log("TP_confirmBtn_06");
        return receipt;
    } catch (error) {
        throw error;
    }
}

export const sendToken = async (pk, item, sendingToAddress, sendingContractAddress, sendingAmount, sendingMaxPriorityFeePerGas, sendingMaxFeePerGas, sendingGaspriceBsc) => {
    //
    const provider = new ethers.JsonRpcProvider(item.endPoint);
    const wallet = new ethers.Wallet(pk, provider);

    const erc20Abi = [
        "function transfer(address to, uint amount) public returns (bool)",
        "function decimals() view returns (uint8)",
        "function balanceOf(address owner) view returns (uint256)",
        "function name() view returns (string)",
        "function symbol() public view returns (string)"
    ];
    try {
        const contract = new ethers.Contract(sendingContractAddress, erc20Abi, wallet);
        const amountToken = parseUnits(String(sendingAmount), await contract.decimals()); // Assuming the token has 18 decimals
        const paramsFee = {}
        if (item.groupName === "ETHEREUM" || item.groupName === "Seporila(Testnet)") {
            paramsFee.maxPriorityFeePerGas = sendingMaxPriorityFeePerGas;
            paramsFee.maxFeePerGas = sendingMaxFeePerGas;
            // paramsFee.gasLimit = await provider.estimateGas(paramsFee);
            // console.dir({ "paramsFee": paramsFee });
        } else paramsFee.gasPrice = sendingGaspriceBsc;

        const transactionResponse = await contract.transfer(sendingToAddress, amountToken, paramsFee);
        // console.log("TP_confirmBtn_10");
        // Wait for the transaction to be mined
        const receipt = await transactionResponse.wait();
        return receipt;
    } catch (error) {
        throw error;
    }
}

export const sendNft721 = async (pk, item, nftItem, fromAddress, sendingToAddress, sendingContractAddress, sendingMaxPriorityFeePerGas, sendingMaxFeePerGas, sendingGaspriceBsc) => {
    //
    const provider = new ethers.JsonRpcProvider(item.endPoint);
    const wallet = new ethers.Wallet(pk, provider);

    // ERC-721 ABI with necessary methods
    const erc721abi = [
        "function name() external view returns (string _name)",
        "function symbol() external view returns (string _symbol)",
        "function safeTransferFrom(address from, address to, uint256 tokenId) external",
        "function ownerOf(uint256 tokenId) external view returns (address)"
    ];
    try {
        const contract = new ethers.Contract(sendingContractAddress, erc721abi, wallet);
        const paramsFee = {}
        if ( item.groupName === "ETHEREUM" || item.groupName === "Seporila(Testnet)" ) {
            paramsFee.maxPriorityFeePerGas = sendingMaxPriorityFeePerGas;
            paramsFee.maxFeePerGas = sendingMaxFeePerGas;
            // console.dir({ "paramsFee": paramsFee });
        } else paramsFee.gasPrice = sendingGaspriceBsc;
        const transactionResponse = await contract.safeTransferFrom(fromAddress, sendingToAddress, nftItem.tokenId ,paramsFee);
        const receipt = await transactionResponse.wait();
        return receipt;
    } catch (error) {
        throw error;
    }
}
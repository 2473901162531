import React from 'react';

function DateButton({title, isSelected}) {
    return (
        <div className={`flex-1 py-[14.5px] rounded-[4px] border ${isSelected ? 'border-primary-yellow bg-primary-yellow text-dark-purple' : 'border-dark-gray-purple text-white'}`}>
            {title}
        </div>
    );
}

export default DateButton;
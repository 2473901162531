import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SubTitle from '../components/SubTitle';
import Line from '../components/Line';
import { getCurrencyUnit, isApp, numberWithCommas, set, shortenWalletAddress } from '../utils/CommonUtil';
import { Slide, toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import LoseThisWalletConfirmModal from '../components/modal/LoseThisWalletConfirmModal';
import { useDefaultStore } from '../store/DefaultStore';

function ManageWallet() {
    const location = useLocation(); 
    const { t } = useTranslation();
    const {currency} = useDefaultStore(['currency']);

    const [isLoseModal , setIsLoseModal] = useState(false);
    const onAddressCopy = useCallback(async () => {
        if(isApp()) {
          const result = await set('copyAddress',location.state.publicKey);
          if(result){
            toast(t('57'),{
              position : 'bottom-center',
              autoClose : 1000,
              closeButton : false,
              hideProgressBar : true,
              pauseOnHover : false,
              draggable : false,
              transition : Slide,
              className : '!bg-dark-gray-purple !text-[14px] !text-white',
              icon : <img src='/assets/icon/success.svg' alt='' />
            });
          }
        }
    },[])
    // 지갑 삭제
    const onLoseThisWallet = useCallback(() => {
        setIsLoseModal(true);
    },[])

    return (
        <div className='flex flex-col gap-[31px]'>
            <div className='flex flex-row px-[24px] py-[16px] gap-[16px] items-center'>
                <div className='flex-none w-[32px] h-[32px] rounded-[50%]'>
                    <img src='/assets/gnb/profile.svg' alt='' />
                </div>
                <div className='flex-1 flex flex-col truncate'>
                    <div className='text-[16px] text-white'>
                    {getCurrencyUnit(currency) + ' ' + numberWithCommas({
                        number : parseFloat(location.state.totalPrice || 0),
                        decimalPlaces : 2,
                        isB : true})}
                    </div>
                    <div className='text-light-gray-purple  truncate'>
                        {shortenWalletAddress(location.state.publicKey,5,4)}
                    </div>
                </div>
                <div className='flex-none w-[24px] h-[24px] cursor-pointer' onClick={onAddressCopy}>
                    <img src='/assets/icon/copy.png' alt='' />
                </div>
            </div>
            <div className='flex flex-col px-[24px]'>
                <SubTitle title={t('17')} />
                <div className='flex flex-row gap-[16px] py-[16px]' onClick={onLoseThisWallet}>
                    <div className='w-[24px] h-[24px]'>
                        <img src='/assets/setting/backup_this_wallet.png' alt='' />
                    </div>
                    <div className='text-[16px] text-white'>{t('53')}</div>
                </div>
                <Line />
                <div className='text-light-gray-purple pt-[24px]'>
                    {t('54')}
                </div>
            </div>
            <LoseThisWalletConfirmModal isOpen={isLoseModal} setIsOpen={setIsLoseModal} />
        </div>
    );
}

export default ManageWallet;